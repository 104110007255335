import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import { Measurement } from '../../../../services/prodocApi';
import { useRegisteredFields } from '../../../../hooks/validation-hooks';
import { bloodSugarSchema } from '../../../../utils/UtilsValidation';
import TextArea from '../../../_shared/_controls/TextArea/TextArea';

interface Props {
  measurement: Measurement;
  formName: string;
  onChange(measurement: Measurement, name: string);
}

const BloodSugarForm = forwardRef((props: Props, ref) => {
  const { measurement, formName, onChange } = props;
  const { register, errors, unregister, setValue, clearError, reset, triggerValidation }: any = useForm({
    mode: 'onBlur',
    validationSchema: bloodSugarSchema,
    defaultValues: measurement,
  });
  const { millimol, remarks } = measurement;
  const handledmeasurement = millimol === undefined ? '' : millimol;

  useEffect(() => {
    reset();
  }, [measurement]);

  useRegisteredFields(bloodSugarSchema, register, unregister);

  const handleFieldChange = (value: any, name: string) => {
    const newmeasurement = { ...measurement, [name]: value || '' };

    onChange(newmeasurement, formName);
    setValue(name, value);
    clearError(name);
  };

  useImperativeHandle(ref, () => ({
    validateForm() {
      return triggerValidation();
    },
  }));

  return (
    <form className="common-form">
      <TextInput
        title="mmol/l"
        value={handledmeasurement}
        name="millimol"
        type="number"
        error={errors.millimol && errors.millimol.message}
        onChange={handleFieldChange}
      />
      <TextArea title="Bemærkning" value={remarks} name="remarks" onChange={handleFieldChange} />
    </form>
  );
});

export default BloodSugarForm;
