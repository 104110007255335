import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AuthType, Employee } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import Button from '../../_shared/_controls/Button/Button';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import Title from 'antd/lib/typography/Title';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import { employeeSchema } from '../../../utils/UtilsValidation';
import { useCprValidation, useRegisteredFields } from '../../../hooks/validation-hooks';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { URLS } from '../../../constants/urls';
import { useNavigation, useRouterParams } from '../../../hooks/navigation';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { UtilsItems } from '../../../utils/UtilsItems';
import { CalendarState } from '../../../store/calendarStore';
import { useZipCodeField } from '../../../hooks/common-hooks';
import { DepartmentState } from '../../../store/departmentStore';
import { EmployeeState } from '../../../store/employeeStore';
import { CardActions } from '../../_shared/CardActions/CardActions';

import './EmployeeEditForm.scss';

interface Props {
  data: Employee;
  onSubmit(data: Employee): void;
  onCancel(): void;
}

interface PasswordData {
  password: string;
  passwordRepeat: string;
}

const authOptions = UtilsAuth.getAuthTypeOptions();
const defaultPasswordsData = {
  password: '',
  passwordRepeat: '',
};

export default function EmployeeEditForm(props: Props) {
  const { data, onSubmit, onCancel } = props;

  const [navigateTo] = useNavigation();
  const [match] = useRouterParams();
  const { getZipCodeData } = useZipCodeField();
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesItemsStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const employeesState = useSelector<ApplicationState, EmployeeState>(state => state.employees);
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const { isCprValid } = useCprValidation();
  const isDataSaved = pagesItemsStore[PAGES_ITEMS.EMPLOYEE_FORM].saved;
  const { employeesEnrollments } = calendarStore;
  const { userDepartments } = departmentState;
  const { availableSearchEmployees } = employeesState;
  const isEmployee = userState.authType === AuthType.Employee;

  const [employee, setEmployee] = useState<Employee>(data);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [passwordData, setPasswordData] = React.useState<PasswordData>(defaultPasswordsData);
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: employeeSchema,
    defaultValues: data as any,
  });
  const filteredEmployeesEnrollments = useMemo(() => {
    return availableSearchEmployees.filter(item => item.departmentId === selectedDepartment);
  }, [selectedDepartment, availableSearchEmployees.length]);

  useRegisteredFields(employeeSchema, register, unregister);

  const {
    firstname,
    username,
    lastname,
    address,
    zipCode,
    city,
    phone,
    phonePrivate,
    email,
    emailPrivate,
    authType,
    cpr,
  } = employee;
  const { id: employeeId, overview } = match.params;
  const employeeValue = filteredEmployeesEnrollments.length ? employeeId : '';

  const resetFormFields = () => {
    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });

    setEmployee(data);
  };

  useEffect(() => {
    resetFormFields();

    setPasswordData({
      password: data.password,
      passwordRepeat: data.password,
    });
    setValue('passwordRepeat', data.password);

    if (!data.id) {
      setPasswordData(defaultPasswordsData);
    }
  }, [data.id]);

  useEffect(() => {
    if (isDataSaved)  resetFormFields();
  }, [isDataSaved]);

  useEffect(() => {
    if (employeesEnrollments.length) {
      const employeeEnrollment = employeesEnrollments.find(item => employeeId === item.employeeId);

      setSelectedDepartment(employeeEnrollment?.departmentId || '');
    }
  }, [employeesEnrollments.length]);

  const handleFieldsChange = (value: any, name: string) => {
    const zipData = getZipCodeData(value, name, { ...employee, [name]: value });

    setEmployee(zipData);
    setValue(name, value);
    clearError(name);
  };

  const handleCprFieldChange = (value: string) => {
    let handledValue = value;

    if (!isCprValid(value)) return;

    handledValue = UtilsItems.formatCprNumber(value);

    setEmployee({ ...employee, cpr: handledValue });
    setValue('cpr', handledValue);
    clearError('cpr');
  };

  const handleSelectChange = (value: string, name: string) => {
    setEmployee({ ...employee, [name]: value });
  };

  const handleDepartmentChange = (id: string) => {
    const route = UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.DETAILS);

    navigateTo(route);
    setSelectedDepartment(id);
  };

  const handleFormSubmit = () => {
    onSubmit({ ...employee, password: passwordData.password });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleEmployeeSelect = (selectedId: string) => {
    if (selectedId) {
      const route = UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.DETAILS, selectedId);

      navigateTo(route);
    }
  };

  return (
    <form className="employee-form item-form" style={{ display: 'block' }} onSubmit={handleSubmit(handleFormSubmit)}>
      <Card>
        <div className="flexContainer">
          <div>
            <Title level={3}>Medarbejder oplysninger</Title>
            {!overview && (
              <>
                <SelectMenu
                  title="Vælg afdeling"
                  value={selectedDepartment}
                  options={userDepartments}
                  valueKey="id"
                  labelKey="name"
                  onChange={handleDepartmentChange}
                />
                <SelectMenu
                  title="Vælg medarbejder"
                  value={employeeValue}
                  disabled={!selectedDepartment}
                  options={filteredEmployeesEnrollments}
                  valueKey="employeeId"
                  labelKey={['firstname', 'lastname']}
                  onChange={handleEmployeeSelect}
                />
              </>
            )}
            <TextInput
              title="Cpr"
              name="cpr"
              value={cpr}
              error={errors.cpr && errors.cpr.message}
              onChange={handleCprFieldChange}
            />
            <div className="flexContainer">
            <span className="standardSpaceRightTextbox">
              <TextInput
                title="Fornavn"
                name="firstname"
                value={firstname}
                error={errors.firstname && errors.firstname.message}
                onChange={handleFieldsChange}
              />
            </span>
              <TextInput
                title="Efternavn"
                name="lastname"
                value={lastname}
                error={errors.lastname && errors.lastname.message}
                onChange={handleFieldsChange}
              />
            </div>
            <TextInput title="Adresse (Privat)" name="address" value={address} onChange={handleFieldsChange} />
            <div className="flexContainer">
            <span className="standardSpaceRightTextbox">
              <TextInput title="Postnummer" name="zipCode" value={zipCode} onChange={handleFieldsChange} />
            </span>
              <TextInput title="By" name="city" value={city} onChange={handleFieldsChange} />
            </div>
            <div className="flexContainer">
            <span className="standardSpaceRightTextbox">
              <TextInput title="Telefon (Arbejde)" name="phone" value={phone} onChange={handleFieldsChange} />
            </span>
              <TextInput
                title="Telefon (Privat)"
                name="phonePrivate"
                value={phonePrivate}
                onChange={handleFieldsChange}
              />
            </div>
            <TextInput
              title="Email (Arbejde)"
              name="email"
              value={email}
              error={errors.email && errors.email.message}
              onChange={handleFieldsChange}
            />
            <TextInput title="Email (Privat)" name="emailPrivate" value={emailPrivate} onChange={handleFieldsChange} />
          </div>
          <div className="employee-form__account">
            <Title level={3}>Bruger oplysninger</Title>
            <TextInput
              title="Brugernavn"
              name="username"
              value={username}
              error={errors.username && errors.username.message}
              disabled={isEmployee}
              onChange={handleFieldsChange}
            />
            <SelectMenu
              title="System rettighed"
              name="authType"
              value={authType}
              options={authOptions}
              valueKey="value"
              labelKey="value"
              disabled={isEmployee}
              onChange={handleSelectChange}
            />
          </div>
        </div>
        <CardActions
          rightActions={(
            <>
              <Button title="Opdater" htmlType="submit" />
              <Button title="Annuller" onClick={handleCancel} />
            </>
          )}
        />
      </Card>
    </form>
  );
}
