import useReactRouter from 'use-react-router';
import { CommonObject, NavigationItem } from '../types/common';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { URLS } from '../constants/urls';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useMemo } from 'react';
import { ROUTES } from '../constants/routes';
import { UtilsAuth } from '../utils/UtilsAuth';

export function useNavigation() {
  const { history } = useReactRouter<{ residenceId: string }>();

  const navigateTo = (url: string) => {
    history.push(url);
  };

  return [navigateTo];
}

export function useRouterParams() {
  const { match } = useReactRouter<any>();

  return [match];
}

export function useCitizenNavigationItems(options?: any) {
  const { id, employeeId, departmentId } = useParams() as any;
  const isMeasurementsPage = !!useRouteMatch(ROUTES.MEASUREMENTS);
  const isMeasurementsEditPage = !!useRouteMatch(ROUTES.MEASUREMENTS_EDIT);
  const { measurementCitizenId } = options || [];

  const citizenItems: NavigationItem[] = useMemo(
    () => [
      {
        name: 'Basisoplysninger',
        route: UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.DETAILS, id),
      },
      {
        name: 'Indskrivningsoplysninger',
        route: UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.ENROLL, id),
      },
      {
        name: 'Udskriv borger',
        route: UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.DISCHARGE, id),
      },
    ],
    [id]
  );

  const employeeItems: NavigationItem[] = useMemo(
    () => [
      {
        name: 'Basisoplysninger',
        route: UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.DETAILS, id),
      },
      {
        name: 'Ansættelsesoplysninger',
        route: UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.ENROLL, id),
      },
      {
        name: 'Afslut medarbejder',
        route: UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.DISCHARGE, id),
      },
    ],
    [id]
  );

  const measurementsItems: NavigationItem[] = useMemo(
    () => [
      {
        name: 'Vis målinger',
        route: UtilsNavigation.buildRoute(URLS.MEASUREMENTS, measurementCitizenId),
        isActive: isMeasurementsPage,
      },
      {
        name: 'Rediger risikovurdering',
        route: UtilsNavigation.buildRoute(URLS.MEASUREMENT, URLS.DETAILS, measurementCitizenId),
        isActive: isMeasurementsEditPage,
      },
    ],
    [measurementCitizenId, isMeasurementsPage, isMeasurementsEditPage]
  );

  const goalsItems: NavigationItem[] = useMemo(
    () => [
      {
        name: 'Mål',
        route: UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.GOALS),
      },
      {
        name: 'Målstatus',
        route: ROUTES.GOAL_STATUSES,
      },
    ],
    []
  );

  const documentsItems: NavigationItem[] = useMemo(
    () => [
      {
        name: 'Dokumenter',
        route: ROUTES.DOCUMENTS,
      },
      {
        name: 'Procedurer',
        route: ROUTES.DOCUMENTS_PROCEDURES,
      },
    ],
    []
  );
  const planningItems: NavigationItem[] = [
    {
      name: 'Level+',
      route: UtilsNavigation.buildRoute(URLS.PLANNING, URLS.WORKPLAN, employeeId, departmentId),
      isHidden: false,
    },

    {
      name: 'Skabelon',
      route: UtilsNavigation.buildRoute(URLS.PLANNING, URLS.DRAFT, employeeId, departmentId),
      isHidden: !(UtilsAuth.isManager() || UtilsAuth.isAdmin()),
    },
    {
      name: 'Kladde',
      route: UtilsNavigation.buildRoute(URLS.PLANNING, URLS.TEMPLATE, employeeId, departmentId),
      isHidden: !(UtilsAuth.isManager() || UtilsAuth.isAdmin()),
    },
  ];
  return { citizenItems, employeeItems, measurementsItems, goalsItems, documentsItems, planningItems };
}
