import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import OverviewDetailsField from '../OverviewDetailsField/OverviewDetailsField';
import { CitizensState, getCitizenOverviewData, setCitizenOverview } from '../../../store/citizenStore';
import { CitizenEnrollmentResult } from '../../../services/prodocApi';
import { ApplicationState } from '../../../store';
import { useCitizenData } from '../../../hooks/citizen-hooks';

interface Props {
  data: CitizenEnrollmentResult;
}

export default function OverviewDetailsCitizen(props: Props) {
  const { data } = props;
  const { citizen } = useCitizenData(data.citizenId);

  const { address, zipCode, city, cpr, alternativeAddress, phone, cave, foreignId, id, typeOfConviction, diagnoses, vaccines } = citizen;
  const dispatch = useDispatch();
  const citizensState = useSelector<ApplicationState, CitizensState>(state => state.citizens);
  const { citizenOverview } = citizensState;
  const { enrollments, enrollmentDepartments } = citizenOverview;

  React.useEffect(() => {
    dispatch(getCitizenOverviewData(id));

    return () => {
      dispatch(setCitizenOverview({
        enrollments: [],
        enrollmentDepartments: [],
      }));
    };
  }, [id]);

  const enrollmentsValue = enrollments.map((item) => {
    return moment(item.enrollmentDate).format('MM/DD/YYYY');
  }).join(', ');

  const enrollmentDepartmentsValue = enrollmentDepartments.map((item) => (item.name)).join(', ');

  return (
    <div>
      <OverviewDetailsField label="Cpr" value={cpr}/>
      <OverviewDetailsField label="Udlændinge id" value={foreignId}/>
      <OverviewDetailsField label="Adresse" value={address}/>
      <OverviewDetailsField label="Postnummer" value={zipCode}/>
      <OverviewDetailsField label="By" value={city}/>
      <OverviewDetailsField label="Alternativ adresse" value={alternativeAddress}/>
      <OverviewDetailsField label="Telefonnummer" value={phone}/>
      <OverviewDetailsField label="Cave" value={cave} important/>
      <OverviewDetailsField label="Diagnoser" value={diagnoses}/>
      <OverviewDetailsField label="Vaccinationer" value={vaccines}/>
      <OverviewDetailsField label="Afdeling" value={enrollmentDepartmentsValue}/>
      <OverviewDetailsField label="Indkrivningsdato" value={enrollmentsValue}/>
      <OverviewDetailsField label="Domstype" value={typeOfConviction}/>
    </div>
  );
}
