import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal/Modal';
import './Modal.scss';

export function showConfirmModal(options: ModalFuncProps) {
  return () => {
    Modal.confirm({
      ...options,
    });
  };
}
