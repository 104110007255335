import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../_shared/_controls/Button/Button';
import CalendarItemDrawer from '../CalendarItemDrawer/CalendarItemDrawer';
import { Form } from '../../_shared/Form/Form';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ApplicationState } from '../../../store';
import { CalendarState } from '../../../store/calendarStore';
import { CalendarItem, Medicine } from '../../../services/prodocApi';
import { useCitizenMedicineRequest, usePn24HoursDosesCount } from '../../../hooks/medicine-hooks';
import { UtilsMedicine } from '../../../utils/UtilsMedicine';
import { medicineDefault } from '../../../services/defaults';
import { MESSAGES } from '../../../constants/messages';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { LoadingAnimation } from '../../_shared/LoadingAnimation/LoadingAnimation';

import './CalendarPnWindow.scss';

interface Props {
  data: CalendarItem;
  isOpened: boolean;
  preselectedEnrollmentId?: string;
  onUpdateItem(data: Medicine): void;
  onClose(): void;
}

const defaultData: Medicine = { ...medicineDefault(), fromDate: new Date() };

export const CalendarPnWindow: React.FC<Props> = props => {
  const { data, isOpened, preselectedEnrollmentId = '', onClose, onUpdateItem } = props;
  const { getCitizenMedicine } = useCitizenMedicineRequest();
  const { get24hoursDosesCount } = usePn24HoursDosesCount();
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { citizensEnrollments } = calendarState;
  const { saved } = pagesState[PAGES_ITEMS.CALENDAR_ITEM];
  const isSaving = pagesState[PAGES_ITEMS.CALENDAR_ITEM].loading;

  const [medicineOptions, setMedicineOptions] = useState<Medicine[]>([]);
  const [formData, setFormData] = useState<Medicine>({ ...defaultData });
  const [doseError, setDoseError] = useState('');
  const [doses24count, setDoses24count] = useState(0);
  const medicineSelectOption = useMemo(
    () => UtilsMedicine.createMedicineSelectOptions(medicineOptions),
    [medicineOptions]
  );
  useEffect(() => {
    setFormData({ ...defaultData, fromDate: new Date() });
  }, []);
  const { citizenEnrollmentId, fromDate, dailyDose, maxDailyDose, actualDose, id: medicineId } = formData;
  const isMaxDoseReached = doses24count >= parseInt(maxDailyDose);
  const dose24Text = isMaxDoseReached
    ? 'Maksimal, daglig dosis nået'
    : `Udleveringer de sidste 24 timer: ${doses24count || 0}`;
  const dose24Class = classNames('pn-form__dose24', {
    'pn-form__dose24--reached': parseInt(dailyDose) <= doses24count,
  });

  useEffect(() => {
    if (data && preselectedEnrollmentId && isOpened) {
      handleCitizenChange(preselectedEnrollmentId);
    }
  }, [data, preselectedEnrollmentId, isOpened]);

  useEffect(() => {
    if (saved) {
      setFormData(defaultData);
    }
  }, [saved]);

  const getDoseCount = async (medicineItem: Medicine) => {
    if (medicineItem.product) {
      const dosesFor24hors = await get24hoursDosesCount(
        medicineItem.product.varenummer,
        medicineItem.citizenEnrollmentId
      );
      setDoses24count(dosesFor24hors);
    }
  };

  const handleFieldChange = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleMedicineFieldChange = (value: any, name: string) => {
    if (name === 'dailyDose' && doseError) setDoseError('');

    setFormData({ ...formData, [name]: value });
  };

  const handleCitizenChange = async (citizenEnrollmentId: string) => {
    const selectedEnrollment = citizensEnrollments.find(item => item.id === citizenEnrollmentId);
    const medicineItems = await getCitizenMedicine(selectedEnrollment.citizenId, true);
    if (formData) {
      getDoseCount(formData);
    }

    setFormData({ ...formData, citizenEnrollmentId: citizenEnrollmentId });
    setMedicineOptions(medicineItems);
  };

  const handleMedicineChange = (selectedMedicineId: string) => {
    const medicineItem = medicineOptions.find(item => item.id === selectedMedicineId);

    getDoseCount(medicineItem);

    setFormData({ ...medicineItem, fromDate: formData.fromDate });
  };

  const handleFormSubmit = () => {
    if (Number(dailyDose) > Number(maxDailyDose)) {
      setDoseError(MESSAGES.MEDICINE_DAILY_DOSE_ERROR);
      return;
    }

    onUpdateItem(formData);
  };

  const handleClose = () => {
    onClose();
    setFormData(defaultData);
  };

  return (
    <CalendarItemDrawer
      title="PN Administrering"
      isOpen={isOpened}
      buttonsRight={
        <>
          <LoadingAnimation active={isSaving} />
          <Button title="Gem" disabled={isSaving} onClick={handleFormSubmit} />
          <Button title="Annuller" onClick={handleClose} />
        </>
      }
      onClose={handleClose}>
      <Form className="pn-form" onSubmit={handleFormSubmit}>
        <SelectMenu
          title="Vælg borger"
          value={citizenEnrollmentId}
          name="citizenEnrollmentId"
          options={citizensEnrollments}
          valueKey="id"
          labelKey={['firstname', 'lastname']}
          onChange={handleCitizenChange}
        />
        <SelectMenu
          title="Vælg medicin"
          value={medicineId}
          name="medicineId"
          options={medicineSelectOption}
          valueKey="value"
          labelKey="label"
          onChange={handleMedicineChange}
        />
        <TextInput
          title="Daglig dosis"
          value={dailyDose}
          name="dailyDose"
          error={doseError}
          onChange={handleMedicineFieldChange}
        />
        <TextInput
          title="Maksimal dosis"
          value={maxDailyDose}
          name="maxDailyDose"
          onChange={handleMedicineFieldChange}
        />
        <TextInput title="Dosis" value={actualDose} name="actualDose" onChange={handleMedicineFieldChange} />
        {medicineId && <p className={dose24Class}>{dose24Text}</p>}
        <DatePicker
          title="Administreringstidspunkt"
          value={fromDate}
          name="fromDate"
          showTime
          format={DATE_TIME_FORMAT}
          onChange={handleFieldChange}
        />
      </Form>
    </CalendarItemDrawer>
  );
};
