import React, { ReactNode } from 'react';
import { Drawer } from '../../_shared/Drawer/Drawer';

import './CalendarItemDrawer.scss';

interface Props {
  title: ReactNode | string;
  subtitle?: string;
  children: ReactNode;
  buttonsLeft?: ReactNode;
  buttonsRight: ReactNode;
  isOpen: boolean;
  hideFooter?: boolean;
  onClose(): void;
}

export default function CalendarItemDrawer(props: Props) {
  const { children, title, subtitle, buttonsLeft, buttonsRight, isOpen, hideFooter, onClose } = props;

  return (
    <Drawer
      title={title}
      subtitle={subtitle}
      className="cai-drawer"
      visible={isOpen}
      width={700}
      onClose={onClose}
      onOk={() => {
      }}
      buttons={(
        <>
          {!hideFooter && (
            <div className="cai-drawer__buttons">
              <div>{buttonsLeft}</div>
              <div className="cai-drawer__buttons--right">{buttonsRight}</div>
            </div>
          )}
        </>
      )}
    >
      {children}
    </Drawer>
  );
}
