import * as React from 'react';
import { Button as AntButton } from 'antd';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';

import './Button.scss';

type Props = {
  title?: string;
  icon?: string;
  className?: string;
  disabled?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  htmlType?: 'button' | 'reset' | 'submit';
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed';
  size?: 'large' | 'middle' | 'small';
  shape?: 'circle';
  error?: string;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
};

export default function Button(props: Props) {
  const { title, icon, htmlType, size, isActive, className, disabled, type, isLoading, onClick, error, shape } = props;

  const buttonClass = classNames('common-btn', {
    [className]: !!className,
    'common-btn--active': isActive,
  });
  const buttonIcon = icon ? <Icon name={icon} /> : null;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <AntButton
      danger={!!error}
      title={error}
      className={buttonClass}
      type={type}
      shape={shape}
      size={size}
      htmlType={htmlType || 'button'}
      icon={buttonIcon}
      loading={isLoading}
      disabled={disabled}
      onClick={handleClick}>
      {title}
    </AntButton>
  );
}
