import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { RefObject, useEffect, useRef } from 'react';
import DatePicker from '../components/_shared/_controls/DatePicker/DatePicker';
import SelectMenu from '../components/_shared/_controls/SelectMenu/SelectMenu';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { MomentInput } from 'moment';

interface FilterInputProps extends FilterDropdownProps {
  callback(value: string);
}

const FilterInput: React.FC<FilterInputProps> = ({ callback, setSelectedKeys, confirm, visible }) => {
  const ref = useRef() as RefObject<any>;

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        ref.current.focus();
      }, 100);
    }
  }, [visible]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={ref}
        allowClear
        onPressEnter={e => {
          confirm();
          callback((e.target as any).value);
        }}
        onBlur={e => {
          confirm();
          callback(e.target.value);
        }}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      />
    </div>
  );
};

export function getColumnTextSearchProps(callback: (e: string) => void) {
  return {
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => {
      return (
        <FilterInput
          prefixCls=""
          selectedKeys={[]}
          callback={callback}
          visible={visible}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          close={() => {}}
        />
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}

export function getColumnEnumSearchProps(enumValues: { label: string; value: string }[], callback: (e: any) => void) {
  return {
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8, minWidth: 250 }}>
        <SelectMenu
          name="status"
          labelKey="label"
          valueKey="value"
          allowClear
          options={enumValues}
          onChange={e => {
            callback(e);
            setSelectedKeys(e ? [e] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}

export function getColumnDateSearchProps(
  handleDateFromChange: (value: MomentInput) => void,
  handleDateToChange: (value: MomentInput) => void
) {
  return {
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8, display: 'flex', gap: '7px' }}>
        <DatePicker
          title="Fra dato"
          name="from"
          allowClear
          onChange={e => {
            handleDateFromChange(e);
            setSelectedKeys(e ? [e] : []);
            confirm();
          }}
        />
        <DatePicker
          title="Til dato"
          name="to"
          allowClear
          onChange={e => {
            handleDateToChange(e);
            setSelectedKeys(e ? [e] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}
