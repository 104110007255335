import React from 'react';
import { Modal } from 'antd';
import Button from '../../_shared/_controls/Button/Button';

interface Props {
  isVisible: boolean;
  onDelete(): void;
  onDeleteAll(): void;
  onCLose(): void;
}

export const AppointmentDeleteModal: React.FC<Props> = (props) => {
  const { isVisible, onCLose, onDelete, onDeleteAll } = props;

  return (
    <Modal
      visible={isVisible}
      width={602}
      className="delete-window"
      title="Ønsker du at slette denne aftale for alle brugere?"
      onCancel={onCLose}
      footer={(
        <div className="delete-window__footer">
          <Button title="Slet kun aftalen for mig" type="primary" onClick={onDelete} />
          <Button title="Slet aftalen for alle" type="primary" onClick={onDeleteAll} />
          <Button title="Annuller" onClick={onCLose} />
        </div>
      )}
    >
    </Modal>
  );
};
