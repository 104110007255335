import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Employee } from '../../../services/prodocApi';
import { employeeDefault } from '../../../services/defaults';
import {
  addEmployee,
  EmployeeState,
  getEmployeeById,
  loadAvailableSearchEmployees,
  setEmployeeEdit,
  updateEmployee,
} from '../../../store/employeeStore';
import { useCitizenNavigationItems, useRouterParams } from '../../../hooks/navigation';
import { navigateTo, navigateToHome } from '../../../store/navigationStore';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { ROUTES } from '../../../constants/routes';
import { setCenterEdit } from '../../../store/centerStore';
import { DepartmentState, setDepartmentEdit } from '../../../store/departmentStore';
import { URLS } from '../../../constants/urls';
import { ApplicationState } from '../../../store';
import EmployeeEditForm from '../../../components/employee/EmployeeEditForm/EmployeeEditForm';
import { loadUserDepartments } from '../../../store/userStore';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';

import './EmployeeEditPage.scss';
import { useHistory } from 'react-router';

export default function EmployeeEditPage() {
  const dispatch = useDispatch();
  const [match] = useRouterParams();
  const { employeeItems } = useCitizenNavigationItems();
  const employeesState = useSelector<any, EmployeeState>(state => state.employees);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const { editEmployee, leaderCreate } = employeesState;
  const employeeData = editEmployee.data || employeeDefault();
  const { userDepartments } = departmentState;
  const employeeId = match.params.id;
  const history = useHistory();

  usePageTitle('Rediger medarbejder');

  useEffect(() => {
    dispatch(loadUserDepartments());
  }, []);

  useEffect(() => {
    if (userDepartments.length) {
      dispatch(loadAvailableSearchEmployees());
    }
  }, [userDepartments.length]);

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeById(employeeId));
    }

    return () => {
      dispatch(setEmployeeEdit({}));
    };
  }, [employeeId]);

  const handleFormSubmit = (data: Employee) => {
    if (employeeId) {
      dispatch(updateEmployee(data));
    } else {
      dispatch(addEmployee(data));
    }
  };

  const handleLeaderCreationCanceling = () => {
    if (leaderCreate.department) {
      const route = UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.ADD);
      dispatch(navigateTo(route));
      dispatch(setDepartmentEdit(leaderCreate.department));
      return;
    }

    dispatch(navigateTo(ROUTES.CENTER_ADD));
    dispatch(setCenterEdit(leaderCreate.center));
  };

  const handleCancel = () => {
    // if (UtilsNavigation.shouldReturnToOverview()) {
    //   dispatch(navigateTo(ROUTES.OVERVIEW));
    // } else if (leaderCreate) {
    //   handleLeaderCreationCanceling();
    // } else {
    //   dispatch(navigateToHome());
    // }
    history.push(ROUTES.EMPLOYEE_OVERVIEW);
  };

  return (
    <>
      <Sidebar>
        <NavigationMenu items={employeeItems} />
      </Sidebar>
      <div className="employee-page page">
        <div className="employee-edit">
          <div className="employee-edit__wrap">
            <EmployeeEditForm data={employeeData} onSubmit={handleFormSubmit} onCancel={handleCancel} />
          </div>
        </div>
      </div>
    </>
  );
}
