import React from 'react';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import { Measurement } from '../../../../services/prodocApi';
import TextArea from '../../../_shared/_controls/TextArea/TextArea';

interface Props {
  measurement: Measurement;
  formName: string;
  onChange(measurement: Measurement, name: string);
}

export default function BloodPressureForm(props: Props) {
  const { measurement, formName, onChange } = props;
  const { systolisk, diastolisk, puls, remarks } = measurement;

  const handleFieldChange = (value: any, name: string) => {
    const newData = { ...measurement, [name]: value };

    onChange(newData, formName);
  };

  return (
    <div className="common-form">
      <TextInput
        title="Systolisk blodtryk"
        value={systolisk || ''}
        name="systolisk"
        type="number"
        onChange={handleFieldChange}
      />
      <TextInput
        title="Diastolisk blodtryk"
        value={diastolisk || ''}
        name="diastolisk"
        type="number"
        onChange={handleFieldChange}
      />
      <TextInput title="Puls" value={puls || ''} name="puls" type="number" onChange={handleFieldChange} />
      <TextArea title="Bemærkning" value={remarks} name="remarks" onChange={handleFieldChange} />
    </div>
  );
}
