import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { MedicineCitizenFields } from '../MedicineCitizenFields/MedicineCitizenFields';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { UtilsMeasurements } from '../../../utils/UtilsMeasurements';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import { setMeasurementWindowStateAction } from '../../../store/measurementStore';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { URLS } from '../../../constants/urls';
import MedicineLatestItem from '../MedicineLatestItem/MedicineLatestItem';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionSetMedicineActiveCitizen, loadHealthCitizenData, MedicineState } from '../../../store/medicineStore';
import { CalendarState, setCalendarDepartmentId } from '../../../store/calendarStore';
import { UtilsData } from '../../../utils/UtilsData';
import { CitizenEnrollmentResult, MeasurementType } from '../../../services/prodocApi';
import { useTranslation } from 'react-i18next';
import './MedicineHead.scss';
import { ROUTES } from '../../../constants/routes';

interface Props {
  onLatestTypeChange(value: MeasurementType): void;
  onCitizenChange(citizenEnrollmentId: string): void;
  citizenEnrollment: CitizenEnrollmentResult;
}

export const MedicineHead: React.FC<Props> = props => {
  const { onLatestTypeChange, citizenEnrollment, onCitizenChange } = props;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const medicineStore = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const { latestMeasurements } = medicineStore;
  const { t } = useTranslation();

  const renderLatest = () => {
    return latestMeasurements.map((item, index) => {
      const { created, measurementType } = item;
      const value = UtilsMeasurements.getMeasurementMinifiedValueByType(item);
      const date = moment(created).format(DATE_TIME_FORMAT);

      const handleMeasurementAdd = () => {
        onLatestTypeChange(measurementType);
        dispatch(setMeasurementWindowStateAction(true));
      };

      const handleMeasurementClick = () => {
        push(UtilsNavigation.buildRoute(URLS.MEASUREMENTS, citizenEnrollment.citizenId, measurementType.toString()));
      };

      return (
        <MedicineLatestItem
          key={index}
          title={t(measurementType)}
          value={value}
          date={date}
          onAdd={handleMeasurementAdd}
          onClick={handleMeasurementClick}
        />
      );
    });
  };

  return (
    <Card style={{ marginBottom: '8px' }}>
      <Row gutter={8}>
        {!!latestMeasurements.length && citizenEnrollment?.id && (
          <Col span={24}>
            <div className="health-section__latest">
              <div className="health-section__latest-items">{renderLatest()}</div>
            </div>
          </Col>
        )}
      </Row>
      {citizenEnrollment?.id && (
        <Row gutter={8}>
          <Col span={24}>
            <MedicineCitizenFields disabled={!citizenEnrollment?.id} citizenEnrollment={citizenEnrollment} />
          </Col>
        </Row>
      )}
    </Card>
  );
};
