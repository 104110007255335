import React, { useEffect, useState } from 'react';
import { Space, Table } from 'antd';
import moment from 'moment';
import { ICONS } from '../../_shared/Icon/Icon';
import { FileShare, FileShareDto, Tag } from '../../../services/prodocApi';
import { DATE_FORMAT } from '../../../constants/common';
import Button from '../../_shared/_controls/Button/Button';
import { useDocumentDelete, useDocumentDownloading } from '../../../hooks/documents-hooks';
import { Checkbox } from '../../_shared/_controls/Checkbox/Checkbox';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import { getColumnTextSearchProps } from '../../../utils/UtilsTable';

interface Props {
  data: FileShareDto[];
  isProcedures?: boolean;
  showDeleteButton: boolean;
  onReadStateChange?(data: FileShareDto, isRead: boolean): void;
  onDocumentEdit(data: FileShareDto): void;
  onListUpdate(): void;
}
interface FileFilter {
  documentTitle: string;
  createdByName: string;
  createdByDepartmentName: string;
  createdByCenterName: string;
  citizen: string;
}
export const DocumentsTable: React.FC<Props> = props => {
  const { data, isProcedures, showDeleteButton, onReadStateChange, onDocumentEdit, onListUpdate } = props;
  const { downloadDocument, isLoadingName } = useDocumentDownloading();
  const { deleteDocument, isLoadingId: isDeletingId } = useDocumentDelete();
  const userId = UtilsAuth.getEmployeeId();
  const [rows, setRows] = useState([]);
  const [filterData, setFilterData] = useState<FileFilter>({
    documentTitle: '',
    createdByName: '',
    createdByDepartmentName: '',
    createdByCenterName: '',
    citizen: '',
  });
  useEffect(() => {
    setRows(data);
  }, [data]);

  const search = (v, prop: string) => {
    const value = v.toLowerCase();
    const filter = { ...filterData, [prop]: value };
    setFilterData(filter);

    let f = [...data].filter(
      x =>
        x.fileName.toLocaleLowerCase().includes(filter.documentTitle) &&
        x.createdByName.toLocaleLowerCase().includes(filter.createdByName) &&
        x.createdByDepartmentName.toLocaleLowerCase().includes(filter.createdByDepartmentName) &&
        x.createdByCenterName.toLocaleLowerCase().includes(filter.createdByCenterName) &&
        (x.citizen?.firstname || '' + x.citizen?.firstname || '').toLocaleLowerCase().includes(filter.citizen)
    );

    setRows(f);
  };
  const documentsColumns = [
    {
      title: 'Dokument titel',
      dataIndex: 'fileName',
      key: 'fileName',
      ...getColumnTextSearchProps(v => search(v, 'documentTitle')),
    },
    {
      title: 'Borger',
      render: (item: FileShareDto) => {
        return item?.citizen ? `${item.citizen.firstname} ${item.citizen.lastname}` : '';
      },
      ...getColumnTextSearchProps(v => search(v, 'citizen')),
    },
    {
      title: 'Oprettet af',
      dataIndex: 'createdByName',
      key: 'createdByName',
      ...getColumnTextSearchProps(v => search(v, 'createdByName')),
    },
    {
      title: 'Oprettet afdeling',
      dataIndex: 'createdByDepartmentName',
      key: 'createdByDepartmentName',
      ...getColumnTextSearchProps(v => search(v, 'createdByDepartmentName')),
    },
    {
      title: 'Oprettet center',
      dataIndex: 'createdByCenterName',
      key: 'createdByCenterName',
      ...getColumnTextSearchProps(v => search(v, 'createdByCenterName')),
    },
    {
      title: 'Sidst ændret af',
      dataIndex: 'modifiedByName',
      key: 'modifiedByName',
    },
    {
      title: 'Oprettet dato',
      dataIndex: 'created',
      key: 'created',
      render: date => moment(date).format(DATE_FORMAT),
      sorter: (a, b) => Number(new Date(a.created)) - Number(new Date(b.created)),
    },
    // {
    //   title: 'Tags',
    //   dataIndex: 'tags',
    //   key: 'tags',
    //   render: (tags: Tag[]) => tags.map(item => item.name).join(', '),
    // },
    {
      title: '',
      render: (row: FileShareDto) => {
        const isDownloading = isLoadingName === row.blobFileName;
        const isDeleting = isDeletingId === row.id;

        const handleDownload = () => {
          downloadDocument(row.blobFileName);
        };

        const handleEdit = () => {
          onDocumentEdit(row);
        };

        const handleDelete = async () => {
          const isDeleted = await deleteDocument(row.id);

          if (isDeleted) onListUpdate();
        };

        return (
          <Space size={5}>
            <Button icon={ICONS.edit} onClick={handleEdit} />
            <Button icon={ICONS.download} isLoading={isDownloading} onClick={handleDownload} />
            {showDeleteButton && <Button icon={ICONS.delete} isLoading={isDeleting} onClick={handleDelete} />}
          </Space>
        );
      },
    },
  ];

  const proceduresColumns = [
    {
      title: 'Dokument titel',
      dataIndex: 'fileName',
      key: 'fileName',
      ...getColumnTextSearchProps(v => search(v, 'documentTitle')),
    },
    {
      title: 'Oprettet af',
      dataIndex: 'createdByName',
      key: 'createdByName',
      ...getColumnTextSearchProps(v => search(v, 'documentTitle')),
    },
    {
      title: 'Sidst ændret af',
      dataIndex: 'modifiedByName',
      key: 'modifiedByName',
    },
    {
      title: 'Oprettet dato',
      dataIndex: 'created',
      key: 'created',
      render: date => moment(date).format(DATE_FORMAT),
      sorter: (a, b) => Number(new Date(a.created)) - Number(new Date(b.created)),
    },
    {
      title: 'Læst',
      align: 'center',
      render: (row: FileShareDto) => {
        const readArr = row.readByEmployeeIds ?? [];

        const handleChange = (checked: boolean) => {
          onReadStateChange(row, checked);
        };

        return <Checkbox checked={readArr.includes(userId)} onChange={handleChange} />;
      },
    },
    {
      title: '',
      render: (row: FileShareDto) => {
        const isDeleting = isDeletingId === row.id;

        const handleEdit = () => {
          onDocumentEdit(row);
        };

        const handleDelete = async () => {
          const isDeleted = await deleteDocument(row.id);

          if (isDeleted) onListUpdate();
        };

        return (
          <Space size={5}>
            <Button icon={ICONS.edit} onClick={handleEdit} />
            {showDeleteButton && <Button icon={ICONS.delete} isLoading={isDeleting} onClick={handleDelete} />}
          </Space>
        );
      },
    },
  ];

  const tableColumns = isProcedures ? proceduresColumns : documentsColumns;

  return (
    <Table
      dataSource={rows}
      pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }}
      columns={tableColumns}
      rowKey="id"
    />
  );
};
