import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Tabs, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  actionSetHealthCareAreasItem,
  actionSetHealthSelectedCitizenData,
  actionSetMedicineActiveCitizen,
  actionSetMedicineItems,
  loadHealthCitizenData,
} from '../../store/medicineStore';
import { PAGES_ITEMS, setPageItemSaved } from '../../store/pagesStore';
import { loadUserEnrollments } from '../../store/commonStore';
import { MeasurementType } from '../../services/prodocApi';
import { citizenDefault } from '../../services/defaults';
import { MedicineSection } from '../../components/medicine/MedicineSection/MedicineSection';
import { usePageTitle } from '../../hooks/common-hooks';
import { useCalendarEnrollmentsLoading } from '../../hooks/calendar-hooks';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import { MedicineHead } from '../../components/medicine/MedicineHead/MedicineHead';
import { HealthHistory } from '../../components/medicine/HealthHistory/HealthHistory';
import HealthSection from '../../components/medicine/HealthSection/HealthSection';

import './MedicinePage.scss';
import { matchPath, useHistory, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useMatchRoute } from '../../hooks/useMatchRoute';
import { CalendarState, setCalendarDepartmentId } from '../../store/calendarStore';
import { ApplicationState } from '../../store';
import {
  HistoryOutlined,
  IdcardOutlined,
  LoginOutlined,
  MedicineBoxOutlined,
  PicLeftOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons';
const { TabPane } = Tabs;
const TABS = {
  HEALTH: 'MEDICINE_HEALTH',
  CARD: 'MEDICINE_CARD',
  HISTORY: 'MEDICINE_HISTORY',
  FMK: 'MEDICINE_FMK',
};

export default function MedicinePage() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TABS.HEALTH);
  const [latestType, setLatestType] = useState<MeasurementType>();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const { citizenEnrollmentId } = useParams<{ citizenEnrollmentId: string }>();
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const { citizensEnrollments } = calendarState;

  const history = useHistory();
  const location = useLocation();
  const { routeMatch, currentRoute } = useMatchRoute();

  const citizenEnrollment = citizensEnrollments.find(item => item.id === citizenEnrollmentId);

  useCalendarEnrollmentsLoading();
  usePageTitle(
    'Helbred og medicin' +
      (citizenEnrollment ? ' - ' + citizenEnrollment.firstname + ' ' + citizenEnrollment.lastname : '')
  );

  useEffect(() => {
    dispatch(loadUserEnrollments());

    return () => {
      dispatch(actionSetHealthSelectedCitizenData(citizenDefault()));
      dispatch(actionSetMedicineActiveCitizen(''));
      dispatch(actionSetHealthCareAreasItem([]));
      dispatch(actionSetMedicineItems([]));
      dispatch(setPageItemSaved(PAGES_ITEMS.MEDICINE_STATUS, false));
    };
  }, []);
  useEffect(() => {
    if (citizenEnrollmentId) {
      handleCitizenChange(citizenEnrollmentId);
    }
  }, [citizenEnrollmentId, citizensEnrollments]);
  const handleTabChange = (tabKey: string) => {
    history.push(ROUTES[tabKey].replace(':citizenEnrollmentId', citizenEnrollmentId || ''));
    // setActiveTab(tabKey);
    // setShowHistory(false);
  };

  function handleCitizenChange(enrollmentId: string) {
    // setSelectedCitizen(enrollmentId);
    // history.push(ROUTES.MEDICINE.replace(URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT, enrollmentId));
    history.push(currentRoute().replace(':citizenEnrollmentId', enrollmentId || ''));

    const enrollmentData = citizensEnrollments.find(item => item.id === enrollmentId);
    if (enrollmentData) {
      const { citizenId, departmentId } = enrollmentData;

      dispatch(loadHealthCitizenData(citizenId));
      dispatch(actionSetMedicineActiveCitizen(enrollmentId));
      dispatch(setCalendarDepartmentId(departmentId));
    }
  }

  function solid(arg0: string): import('@fortawesome/fontawesome-svg-core').IconProp {
    throw new Error('Function not implemented.');
  }

  return (
    <>
      <div className="citizen-page page" style={{ display: 'flex', flexGrow: routeMatch('MEDICINE_HISTORY') ? 1 : 0 }}>
        <div>
          {!routeMatch('MEDICINE_HISTORY') && (
            <MedicineHead
              citizenEnrollment={citizenEnrollment}
              onLatestTypeChange={setLatestType}
              onCitizenChange={handleCitizenChange}
            />
          )}

          <Card>
            <Row gutter={8}>
              <Col span={24}>
                {routeMatch('MEDICINE_HEALTH') && citizenEnrollmentId && <HealthSection latestType={latestType} />}
                {routeMatch('MEDICINE_CARD') && citizenEnrollmentId && <MedicineSection />}
                {routeMatch('MEDICINE_FMK') && citizenEnrollmentId && <div />}
                {routeMatch('MEDICINE_HISTORY') && citizenEnrollmentId && (
                  <HealthHistory citizenEnrollmentId={citizenEnrollmentId} />
                )}
              </Col>
            </Row>
          </Card>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
          <Tooltip title="Helbred">
            <Button
              style={{ marginBottom: '8px' }}
              icon={<MedicineBoxOutlined translate={undefined} />}
              onClick={() => handleTabChange(TABS.HEALTH)}
              size="large"
            />
          </Tooltip>
          <Tooltip title="Medicinkort">
            <Button
              style={{ marginBottom: '8px' }}
              icon={<IdcardOutlined translate={undefined} />}
              onClick={() => handleTabChange(TABS.CARD)}
              size="large"
            />
          </Tooltip>
          <Tooltip title="FMK">
            <Button
              style={{ marginBottom: '8px' }}
              icon={<PicLeftOutlined translate={undefined} />}
              onClick={() => handleTabChange(TABS.FMK)}
              size="large"
            />
          </Tooltip>
          <Tooltip title="Helbredshistorik">
            <Button
              style={{ marginBottom: '8px' }}
              icon={<HistoryOutlined translate={undefined} />}
              onClick={() => handleTabChange(TABS.HISTORY)}
              size="large"
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
}
