import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Card } from '../../_shared/Card/Card';
import Button from '../../_shared/_controls/Button/Button';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { CenterState, getAllCenters } from '../../../store/centerStore';
import { departmentSchema } from '../../../utils/UtilsValidation';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { deleteDepartment, DepartmentState } from '../../../store/departmentStore';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import { Department } from '../../../services/prodocApi';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { URLS } from '../../../constants/urls';
import { useNavigation, useRouterParams } from '../../../hooks/navigation';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { EmployeeState } from '../../../store/employeeStore';
import { useMemo } from 'react';
import { useZipCodeField } from '../../../hooks/common-hooks';
import { ICONS } from '../../_shared/Icon/Icon';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import { CardActions } from '../../_shared/CardActions/CardActions';
import { Row, Col } from 'antd';

interface Props {
  data: Department;
  editMode: boolean;
  onSubmit(data: any): void;
  onCancel(): void;
}

export default function DepartmentForm(props: Props) {
  const { data, editMode, onSubmit, onCancel } = props;
  const [navigateTo] = useNavigation();
  const [match] = useRouterParams();
  const dispatch = useDispatch();
  const { getZipCodeData } = useZipCodeField();
  const departmentStore = useSelector<any, DepartmentState>(state => state.department);
  const centerStore = useSelector<any, CenterState>(state => state.center);
  const pagesItemsStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const employeeState = useSelector<any, EmployeeState>(state => state.employees);
  const isDataSaved = pagesItemsStore[PAGES_ITEMS.DEPARTMENT_FORM].saved;
  const centersList = centerStore.allCentersList;
  const { leaderCreate } = employeeState;
  const { userDepartments } = departmentStore;
  const newLeaderId = leaderCreate && leaderCreate.leaderId;
  const { centerId: defaultCenterId } = match.params;
  const actionText = editMode ? 'Opdater' : 'Gem';

  const [department, setDepartment] = React.useState<Department>(data);
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: departmentSchema,
    defaultValues: data as any,
  });
  useRegisteredFields(departmentSchema, register, unregister);
  const { centerId, name, address, zipCode, city, phoneNumber, email, id } = department;
  const departmentsList = useMemo(() => {
    return userDepartments.filter(item => item.centerId === centerId);
  }, [centerId, userDepartments.length]);
  const dataIsLoaded = !!data.id;
  const centerValue = !centersList?.length && dataIsLoaded && !!centerId ? 'Manglende rettighed' : centerId;
  const isUserCanSeeCenter = useMemo(() => {
    if (!centersList.length || !centerId) return true;

    return !!centersList.find(center => center.id === centerId);
  }, [centersList.length, centerId]);

  const resetFormValues = () => {
    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });

    setDepartment(data);
  };

  useEffect(() => {
    dispatch(getAllCenters());
  }, []);

  useEffect(() => {
    resetFormValues();
  }, [data.id]);

  useEffect(() => {
    if (isDataSaved && !editMode) {
      resetFormValues();
    }
  }, [isDataSaved]);

  useEffect(() => {
    if (defaultCenterId) {
      setDepartment({ ...department, centerId: defaultCenterId });
      setValue('centerId', defaultCenterId);
    }
  }, [defaultCenterId]);

  useEffect(() => {
    let newDepartmentData = { ...data, centerId: defaultCenterId };

    setDepartment(newDepartmentData);

    Object.keys(newDepartmentData).forEach(key => {
      setValue(key, newDepartmentData[key]);
    });
  }, [data.id, newLeaderId, defaultCenterId]);

  const handleFieldsChange = (value: string, name: string) => {
    const departmentIdValue = name === 'centerId' ? undefined : id;
    const newData = { ...department, [name]: value, id: departmentIdValue };
    const zipData = getZipCodeData(value, name, newData);

    setDepartment(zipData);
    setValue(name, value);
    clearError(name);

    if (name === 'centerId') {
      setValue('id', departmentIdValue);
    }
  };

  const handleFormSubmit = () => {
    onSubmit(department);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDepartmentSelect = (selectedId: string) => {
    if (selectedId) {
      const route = UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.DETAILS, selectedId, centerId);

      navigateTo(route);
    }
  };

  const handleDelete = () => {
    dispatch(
      showConfirmModal({
        title: 'Ønsker du at slette denne afdeling?',
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          dispatch(deleteDepartment(id));
        },
      })
    );
  };
  return (
    <form className="department-form item-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Card>
        {/*<Row gutter={8}>
           <Col span={8}>
            {isUserCanSeeCenter ? (
              <SelectMenu
                title="Vælg center*"
                name="centerId"
                value={centerValue}
                options={centersList}
                valueKey="id"
                labelKey="name"
                error={errors.centerId?.message}
                onChange={handleFieldsChange}
              />
            ) : (
              <TextInput
                title="Vælg center*"
                name="name"
                value="Manglende rettighed"
                disabled
                onChange={handleFieldsChange}
              />
            )}
          </Col>
          <Col span={editMode ? 8 : 0}>
            {editMode && (
              <SelectMenu
                title="Vælg afdeling"
                value={id}
                options={departmentsList}
                valueKey="id"
                disabled={!centerValue}
                labelKey="name"
                onChange={handleDepartmentSelect}
              />
            )}
          </Col>
          <Col span={8}>
            <TextInput
              disabled={!centerValue}
              title="Afdelingsnavn*"
              name="name"
              value={name}
              error={errors.name?.message}
              onChange={handleFieldsChange}
            />
          </Col>
        </Row> */}
        <Row gutter={8}>
          <Col span={8}>
            <TextInput
              disabled={!centerValue}
              title="Afdelingsnavn*"
              name="name"
              value={name}
              error={errors.name?.message}
              onChange={handleFieldsChange}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput
              disabled={!centerValue}
              title="Adresse"
              name="address"
              value={address}
              onChange={handleFieldsChange}
            />
          </Col>
          <Col span={4}>
            <TextInput
              disabled={!centerValue}
              title="Postnummer"
              name="zipCode"
              value={zipCode}
              onChange={handleFieldsChange}
            />
          </Col>
          <Col span={8}>
            <TextInput disabled={!centerValue} title="By" name="city" value={city} onChange={handleFieldsChange} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput
              disabled={!centerValue}
              title="Telefonnummer"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleFieldsChange}
            />
          </Col>
          <Col span={12}>
            <TextInput
              disabled={!centerValue}
              title="Email"
              name="email"
              error={errors.email?.message}
              value={email}
              onChange={handleFieldsChange}
            />
          </Col>
        </Row>
        <CardActions
          leftActions={
            <>
              {editMode && (
                <Button title="Slet" icon={ICONS.delete} htmlType="button" disabled={!id} onClick={handleDelete} />
              )}
            </>
          }
          rightActions={
            <>
              <Button title={actionText} htmlType="submit" />
              <Button title="Annuller" onClick={handleCancel} />
            </>
          }
        />
      </Card>
    </form>
  );
}
