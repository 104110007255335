import { notification } from 'antd';

interface NotificationSettings {
  message: string;
  placement?: 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';
  duration?: number;
  top?: number;
}

export const defaultSettings: NotificationSettings = {
  message: '',
  placement: 'topRight',
  duration: 4,
  top: 90,
};

export function showSuccessMessage(options: NotificationSettings) {
  return () => {
    notification.success({
      ...defaultSettings,
      ...options,
    });
  };
}

export function showErrorMessage(options: NotificationSettings) {
  return () => {
    notification.error({
      ...defaultSettings,
      ...options,
    });
  };
}
