import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OverviewItemsTree from '../../components/overviewPage/OverviewItemsTree/OverviewItemsTree';
import CommonDrawer from '../../components/_shared/CommonDrawer/CommonDrawer';
import OverviewDetailsCenter from '../../components/overviewPage/OverviewDetails/OverviewDetailsCenter';
import OverviewDetailsDepartment from '../../components/overviewPage/OverviewDetails/OverviewDetailsDepartment';
import OverviewDetailsCitizen from '../../components/overviewPage/OverviewDetails/OverviewDetailsCitizen';
import OverviewDetailsEmployee from '../../components/overviewPage/OverviewDetails/OverviewDetailsEmployee';
import { ApplicationState } from '../../store';
import { OverViewItem } from '../../types/commonItems';
import { EntityTypes } from '../../constants/common';
import prodocApi, {
  Center,
  CitizenEnrollment,
  CitizenEnrollmentResult,
  Department,
  EmployeeEnrollment,
  EmployeeEnrollmentResult,
} from '../../services/prodocApi';
import { loadOverviewCenters, OverviewState, setLoadedKeys } from '../../store/overviewStore';
import { Card } from '../../components/_shared/Card/Card';
import { usePageTitle } from '../../hooks/common-hooks';
import { useOverviewItemsLoading } from '../../hooks/overview-hooks';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import Button from '../../components/_shared/_controls/Button/Button';
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
import './OverviewPage.scss';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { ICONS } from '../../components/_shared/Icon/Icon';

const StyledNode = styled.div`
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid red;
  background-color: #fff;
`;
export default function OverviewPage() {
  const dispatch = useDispatch();
  const { loadOverviewDepartments, loadOverviewPeople } = useOverviewItemsLoading();
  const overviewState = useSelector<ApplicationState, OverviewState>(state => state.overview);
  const { loadedItemsKeys, data: loadedOverviewData } = overviewState;
  const [itemDetails, setItemDetails] = React.useState<OverViewItem>(null);
  const [showDrawer, setShowDrawer] = React.useState(null);
  const [centers, setCenters] = useState<Center[]>([]);
  const [departments, setDepartments] = useState<{ department: Department; visible: boolean }[]>([]);
  const [employeeEnrollments, setEmployeeEnrollments] = useState<
    { employee: EmployeeEnrollmentResult; visible: boolean }[]
  >([]);
  const [citizenEnollments, setCitizenEnollments] = useState<{ citizen: CitizenEnrollmentResult; visible: boolean }[]>(
    []
  );
  usePageTitle('Organisationsoverblik');
  const history = useHistory();

  useEffect(() => {
    if (!loadedItemsKeys.length) {
      dispatch(loadOverviewCenters());
    }
  }, [loadedItemsKeys]);
  useEffect(() => {
    async function init() {
      const c = await prodocApi.center.getMyCenters();
      setCenters(c);
      const d = await prodocApi.department.getMyDepartments();
      setDepartments(d.map(x => ({ department: x, visible: false })));
      const e = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds: d.map(x => x.id),
      });
      setEmployeeEnrollments(e.map(x => ({ employee: x, visible: false })));
      const ci = await prodocApi.citizenEnrollment.getManyByDepartments({
        departmentIds: d.map(x => x.id),
      });
      setCitizenEnollments(ci.map(x => ({ citizen: x, visible: false })));
    }
    init();
  }, []);

  const handleTreeExpand = (keys: string[]) => {
    dispatch(setLoadedKeys(keys));
  };

  const handleItemSelect = (item: OverViewItem) => {
    if (itemDetails && item.eventKey === itemDetails.eventKey) {
      setShowDrawer(false);

      setTimeout(() => {
        setItemDetails(null);
      }, 300);
    } else {
      setItemDetails(item);
      setShowDrawer(true);
    }
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const handleTreeLoad = async ({ key, type }) => {
    switch (type) {
      case EntityTypes.center: {
        await loadOverviewDepartments(key);
        break;
      }
      default: {
        await loadOverviewPeople(key);
      }
    }
  };

  const renderItemDetails = () => {
    if (!itemDetails) return null;

    switch (itemDetails.type) {
      case EntityTypes.center: {
        return <OverviewDetailsCenter data={itemDetails.itemData} />;
      }
      case EntityTypes.department: {
        return <OverviewDetailsDepartment data={itemDetails.itemData} />;
      }
      case EntityTypes.citizen: {
        return <OverviewDetailsCitizen data={itemDetails.itemData as CitizenEnrollmentResult} />;
      }
      case EntityTypes.employee: {
        return <OverviewDetailsEmployee data={itemDetails.itemData as EmployeeEnrollmentResult} />;
      }
      default:
        return null;
    }
  };
  function toggleCitizenAndEmployees(departmentId: string) {
    citizenEnollments.forEach(x => {
      if (x.citizen.departmentId === departmentId) {
        x.visible = !x.visible;
      }
    });
    setCitizenEnollments([...citizenEnollments]);
    employeeEnrollments.forEach(x => {
      if (x.employee.departmentId === departmentId) {
        x.visible = !x.visible;
      }
    });
    setEmployeeEnrollments([...employeeEnrollments]);
  }
  function toggleDepartment(centerId: string) {
    departments.forEach(x => {
      if (x.department.centerId === centerId) {
        x.visible = !x.visible;
      }
    });
    setDepartments([...departments]);
  }
  return (
    <div style={{ width: '100vw', overflow: 'scroll' }}>
      <Tree
        lineWidth={'2px'}
        lineColor={'#d7d7d7'}
        lineBorderRadius={'10px'}
        label={<Button title="Organisation"></Button>}>
        {centers.map(ce => {
          return (
            <TreeNode
              label={
                <span>
                  <div>
                    <Button
                      icon={ICONS.bank}
                      title={ce.name}
                      onClick={() => history.push(ROUTES.CENTER_DETAILS.replace(':id?', ce.id))}></Button>
                  </div>
                  <div>
                    <Button
                      shape="circle"
                      icon={ICONS.alert}
                      size="small"
                      onClick={() => toggleDepartment(ce.id)}></Button>
                  </div>
                </span>
              }>
              {departments
                .filter(d => d.department.centerId === ce.id && d.visible)
                .map(d => {
                  return (
                    <TreeNode
                      label={
                        <span>
                          <div>
                            <Button
                              icon={ICONS.home}
                              title={d.department.name}
                              onClick={() =>
                                history.push(ROUTES.DEPARTMENT_DETAILS.replace(':id?', d.department.id))
                              }></Button>
                          </div>
                          <div>
                            <Button
                              shape="circle"
                              size="small"
                              icon={ICONS.alert}
                              onClick={() => toggleCitizenAndEmployees(d.department.id)}></Button>
                          </div>
                        </span>
                      }>
                      {citizenEnollments
                        .filter(ce => ce.citizen.departmentId == d.department.id && ce.visible)
                        .map(ce => {
                          return (
                            <TreeNode
                              label={
                                <Button
                                  title={ce.citizen.firstname + ' ' + ce.citizen.lastname}
                                  icon={ICONS.user}
                                  onClick={() =>
                                    history.push(ROUTES.CITIZEN_DETAILS.replace(':id?', ce.citizen.id))
                                  }></Button>
                              }></TreeNode>
                          );
                        })}
                      {employeeEnrollments
                        .filter(ee => ee.employee.departmentId == d.department.id && ee.visible)
                        .map(ee => {
                          return (
                            <TreeNode
                              label={
                                <Button
                                  title={ee.employee.firstname + ' ' + ee.employee.lastname}
                                  icon={ICONS.crown}></Button>
                              }></TreeNode>
                          );
                        })}
                    </TreeNode>
                  );
                })}
            </TreeNode>
          );
        })}
      </Tree>
    </div>
    // <>
    //   <div className="page ">
    //     <Card>
    //       <OverviewItemsTree
    //         data={loadedOverviewData}
    //         onLoad={handleTreeLoad}
    //         loadedKeys={loadedItemsKeys}
    //         onSelect={handleItemSelect}
    //         onExpand={handleTreeExpand}
    //       />
    //     </Card>
    //     <CommonDrawer isOpen={showDrawer} width={350} onClose={handleDrawerClose}>
    //       {renderItemDetails()}
    //     </CommonDrawer>
    //   </div>
    // </>
  );
}
