import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouterParams } from '../../../hooks/navigation';
import { CenterState, getAllCenters, getCenterById, setCenterEdit, updateCenter } from '../../../store/centerStore';
import { centerDefault } from '../../../services/defaults';
import { Center } from '../../../services/prodocApi';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { navigateTo, navigateToHome } from '../../../store/navigationStore';
import { ROUTES } from '../../../constants/routes';
import CenterForm from '../../../components/centerPage/CenterForm/CenterForm';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import Button from '../../../components/_shared/_controls/Button/Button';
import { useHistory } from 'react-router';

export default function CenterEditPage() {
  const dispatch = useDispatch();
  const [match] = useRouterParams();
  const centerState = useSelector<any, CenterState>(state => state.center);
  const { editCenter } = centerState;
  const centerData = editCenter || centerDefault();
  const history = useHistory();

  const centerId = match.params.id;

  usePageTitle('Rediger center');

  useEffect(() => {
    dispatch(getAllCenters());
  }, []);

  React.useEffect(() => {
    if (centerId) {
      dispatch(getCenterById(centerId));
    }

    return () => {
      dispatch(setCenterEdit(null));
    };
  }, [centerId]);

  const handleFormSubmit = (data: Center) => {
    dispatch(updateCenter(data));
  };

  const handleCancel = () => {
    // if (UtilsNavigation.shouldReturnToOverview()) {
    //   dispatch(navigateTo(ROUTES.OVERVIEW));
    // } else {
    //   dispatch(navigateToHome());
    // }
    history.push(ROUTES.CENTER_OVERVIEW);
  };

  return (
    <>
      <div className="center-page page">
        <CenterForm data={centerData} editMode onSubmit={handleFormSubmit} onCancel={handleCancel} />
      </div>
    </>
  );
}
