import React, { useEffect, useState } from 'react';
import Button from '../../_shared/_controls/Button/Button';
import CalendarItemDrawer from '../CalendarItemDrawer/CalendarItemDrawer';
import { Form } from '../../_shared/Form/Form';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { DATE_FORMAT } from '../../../constants/common';
import { CalendarItem, Medicine } from '../../../services/prodocApi';
import { calendarItemDefault, medicineDefault } from '../../../services/defaults';
import TimePicker from '../../_shared/_controls/TimePicker/TimePicker';
import TextEditor from '../../_shared/_controls/TextEditor/TextEditor';
import { LoadingAnimation } from '../../_shared/LoadingAnimation/LoadingAnimation';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { useMedicineData } from '../../../hooks/medicine-hooks';

interface Props {
  data: CalendarItem;
  isOpened: boolean;
  onUpdateCalendarItem(data: Medicine): void;
  onClose(): void;
}

export const CalendarPnEvaluationWindow: React.FC<Props> = props => {
  const { data, isOpened, onClose, onUpdateCalendarItem } = props;
  const { title } = data || calendarItemDefault();
  const { getMedicineData } = useMedicineData();
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const isSaving = pagesState[PAGES_ITEMS.CALENDAR_ITEM].loading;
  const isSaved = pagesState[PAGES_ITEMS.CALENDAR_ITEM].saved;

  const [medicineData, setMedicineData] = useState<Medicine>(medicineDefault());
  const { actualDose, note, fromDate } = medicineData || medicineDefault();
  const subtitle = `Administreret dosis: ${actualDose || 0}`;

  const loadMedicineData = async (medicineId: string) => {
    if (!medicineId) return;

    const data = await getMedicineData(medicineId);

    setMedicineData(data);
  };

  useEffect(() => {
    if (isSaved) handleClose();
  }, [isSaved]);

  useEffect(() => {
    if (data && isOpened) {
      setMedicineData({ ...medicineData, fromDate: new Date(data.fromDate) });
      loadMedicineData(data.entityId);
    }
  }, [data, isOpened]);

  const handleFieldChange = (value: any, name: string) => {
    setMedicineData({ ...medicineData, [name]: value });
  };

  const handleFormSubmit = () => {
    onUpdateCalendarItem(medicineData);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CalendarItemDrawer
      title={title}
      subtitle={subtitle}
      isOpen={isOpened}
      buttonsRight={
        <>
          <LoadingAnimation active={isSaving} />
          <Button title="Gem" onClick={handleFormSubmit} />
          <Button title="Annuller" onClick={handleClose} />
        </>
      }
      onClose={handleClose}>
      <Form onSubmit={handleFormSubmit}>
        <DatePicker title="Dato" value={fromDate} name="fromDate" format={DATE_FORMAT} onChange={handleFieldChange} />
        <TimePicker title="Tidspunkt" value={fromDate} name="fromDate" onChange={handleFieldChange} />
        <TextEditor title="Evaluering" value={note} name="note" onChange={handleFieldChange} />
      </Form>
    </CalendarItemDrawer>
  );
};
