import React from 'react';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import { UtilsMeasurements } from '../../../../utils/UtilsMeasurements';
import { Measurement } from '../../../../services/prodocApi';
import TextArea from '../../../_shared/_controls/TextArea/TextArea';

interface Props {
  measurement: Measurement;
  formName: string;
  onChange(measurement: Measurement, name: string);
}

export default function BmiForm(props: Props) {
  const { measurement, formName, onChange } = props;
  const { height, weight, remarks } = measurement;
  const bmiValue = UtilsMeasurements.countBmiValue(height, weight);

  const handleFieldChange = (value: any, name: string) => {
    const newmeasurement = { ...measurement, [name]: value };

    onChange(newmeasurement, formName);
  };

  return (
    <div className="common-form">
      <TextInput
        title="Højde i meter"
        value={height || ''}
        name="height"
        type="number"
        numberStep={0.1}
        onChange={handleFieldChange}
      />
      <TextInput
        title="Vægt"
        value={weight || ''}
        name="weight"
        type="number"
        numberStep={0.1}
        onChange={handleFieldChange}
      />
      <TextInput title="BMI" value={bmiValue || ''} name="bmi" readonly onChange={handleFieldChange} />
      <TextArea title="Bemærkning" value={remarks} name="remarks" onChange={handleFieldChange} />
    </div>
  );
}
