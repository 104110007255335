import prodocApi, { ForumMessage } from '../services/prodocApi';
import { actionCreator, isType } from './Action';

export interface ForumState {
  forumMessages: ForumMessage[];
}

export const defaultState: ForumState = {
  forumMessages: [],
};

export const setMessages = actionCreator<ForumMessage[]>('FORUMMESSAGES_SET_ITEMS');

export default (state: ForumState = defaultState, action: any): ForumState => {
  if (isType(action, setMessages)) {
    return { ...state, forumMessages: action.payload };
  }

  return state;
};

export const createMessage = (message: ForumMessage) => {
  return async dispatch => {
    try {
      await prodocApi.forum.add(message);
      dispatch(loadForumMessages());
    } catch (e) {
    } finally {
    }
  };
};

export const updateMessage = (message: ForumMessage) => {
  return async dispatch => {
    try {
      await prodocApi.forum.update(message);
      dispatch(loadForumMessages());
    } catch (e) {
    } finally {
    }
  };
};

export const deleteMessage = (id: string) => {
  return async dispatch => {
    try {
      await prodocApi.forum.delete(id);
      dispatch(loadForumMessages());
    } catch (e) {
    } finally {
    }
  };
};
export const deleteComment = (id: string, index: number) => {
  return async (dispatch, getState) => {
    try {
      const message = getState().forum.forumMessages.find(x => x.id === id);
      message.comments.splice(index, 1);
      await prodocApi.forum.update(message);
      dispatch(loadForumMessages());
    } catch (e) {
    } finally {
    }
  };
};
export const loadForumMessages = () => {
  return async dispatch => {
    try {
      //   dispatch(setPageItemLoading(PAGES_ITEMS.NOTES_LIST, true));
      const items = await prodocApi.forum.getAll();
      dispatch(setMessages(items));
    } catch (e) {
    } finally {
      //   dispatch(setPageItemLoading(PAGES_ITEMS.NOTES_LIST, false));
    }
  };
};
