import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Button from '../../_shared/_controls/Button/Button';
import { ICONS } from '../../_shared/Icon/Icon';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import { HealthCare, HealthCareType } from '../../../services/prodocApi';
import HealthAreaListItem from '../HealthAreaListItem/HealthAreaListItem';
import { CommonOption } from '../../../types/common';

import './HealthArea.scss';

interface Props {
  title: string;
  subAreas: HealthCare[];
  onAreaEdit(): void;
  onSubAreaEdit(value: CommonOption): void;
  onNoteCreate(name: HealthCareType): void;
}

export const HealthArea: React.FC<Props> = props => {
  const { title, subAreas, onAreaEdit, onNoteCreate, onSubAreaEdit } = props;
  const { t } = useTranslation();

  const renderSubAreas = () => {
    return subAreas.map((areaItem, areaItemIndex) => {
      const { care, createdByName, created, description } = areaItem;
      const itemOption = { label: t(care), value: care };
      const modifiedDate = createdByName ? moment(created).format(DATE_TIME_FORMAT) : '';
      const info = `${modifiedDate} ${createdByName}`;

      const handleNoteCreate = () => {
        onNoteCreate(care);
      };

      return (
        <HealthAreaListItem
          key={areaItemIndex}
          title={t(care)}
          info={info}
          tooltipText={description}
          value={itemOption}
          onEdit={onSubAreaEdit}
          onNoteCreate={handleNoteCreate}
        />
      );
    });
  };

  return (
    <div className="health-area">
      <div className="health-area__content">
        <div className="health-area__head">
          <span className="health-area__name">{title}</span>
          <div className="health-area__head-actions">
            <Button icon={ICONS.edit} size="small" onClick={onAreaEdit} />
          </div>
        </div>
        {renderSubAreas()}
      </div>
    </div>
  );
};
