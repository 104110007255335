import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import Button from '../../_shared/_controls/Button/Button';
import { Drawer } from '../../_shared/Drawer/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { DocumentsState, setDocumentEditWindowState, updateDocument } from '../../../store/documentStore';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { FileShareDto, Tag } from '../../../services/prodocApi';
import { fileShareDtoDefault } from '../../../services/defaults';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { useDebouncedCallback } from 'use-debounce';
import { useTagsSearch } from '../../../hooks/common-hooks';
import { UtilsData } from '../../../utils/UtilsData';

export const DocumentEditWindow: React.FC = () => {
  const dispatch = useDispatch();
  const { searchTags, tags } = useTagsSearch();
  const documentsState = useSelector<ApplicationState, DocumentsState>(state => state.documents);
  const pagesStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { showDocumentEditWindow, activeDocument } = documentsState;
  const isLoading = pagesStore[PAGES_ITEMS.DOCUMENT_UPDATING].loading;
  const isSaved = pagesStore[PAGES_ITEMS.DOCUMENT_UPDATING].saved;
  const [formData, setFormData] = useState<FileShareDto>(fileShareDtoDefault());
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagsList, setTagsList] = useState<Tag[]>([]);

  const { fileName } = formData;

  // Set form data of active document
  useEffect(() => {
    if (activeDocument) {
      setFormData(activeDocument);
      setSelectedTags(activeDocument.tagIds);
      setTagsList(activeDocument.tags);
    }
  }, [activeDocument]);

  useEffect(() => {
    if (isSaved) handleClose();
  }, [isSaved]);

  // Update tags list with loaded tags
  useEffect(() => {
    const updatedList = UtilsData.removeDuplicatesFromArray([...tagsList, ...tags], 'id');

    setTagsList(updatedList);
  }, [tags]);

  const debouncedTagsSearch = useDebouncedCallback(
    (value: string) => {
      searchTags(value);
    },
    300,
  );

  const handleFieldChange = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    dispatch(setDocumentEditWindowState(false));
  };

  const handleSave = () => {
    dispatch(updateDocument(formData));
  };

  const handleTagsChange = (value: any) => {
    debouncedTagsSearch.callback(value);
  };

  const handleTagsSelect = (value: string[]) => {
    setSelectedTags(value);

    handleFieldChange(value, 'tagIds');
  };

  return (
    <Drawer
      width={600}
      title="Filupload"
      visible={showDocumentEditWindow}
      onClose={handleClose}
      buttons={
        <Space size={10}>
          <Button title="Gem" type="primary" isLoading={isLoading} disabled={isLoading} onClick={handleSave} />
          <Button title="Annuller" onClick={handleClose} />
        </Space>
      }>
      <form className="common-form">
        <TextInput title="Navn" value={fileName} name="fileName" onChange={handleFieldChange} />
        <SelectMenu
          title="Tags"
          options={tagsList}
          multiple
          value={selectedTags}
          valueKey="id"
          labelKey="name"
          onChange={handleTagsSelect}
          onSearch={handleTagsChange}
        />
      </form>
    </Drawer>
  );
};
