import React from 'react';
import { Tag as AntdTag } from 'antd';

interface Props {
  children: React.ReactNode;
}

export default function Tag(props: Props) {
  const { children } = props;

  return (
    <AntdTag className="tag">{children}</AntdTag>
  );
}
