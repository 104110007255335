import { jsPDF } from 'jspdf';
import moment from 'moment';
import { RRule, rrulestr } from 'rrule';
import { CalendarItem, CalendarItemType, CalendarStatus, Employee, GoalResult, Medicine } from '../services/prodocApi';
import { calendarItemDefault } from '../services/defaults';
import { CalendarItemsData, NotesFilterParams } from '../types/calendar';
import { UtilsMedicine } from './UtilsMedicine';
import i18n from '../i18n';
import { UtilsDate } from './UtilsDate';
import { UtilsMeasurements } from './UtilsMeasurements';
import { LocalStorage } from '../services/storage';
import { SIDEBAR_CALENDAR_DATA_NAMESPACE } from '../constants/namespaces';
import { UtilsAuth } from './UtilsAuth';
import { Duration } from '@fullcalendar/react';

export class UtilsCalendar {
  public static isDefaultAppointment(item?: CalendarItem) {
    return item.groupId === '00000000-0000-0000-0000-000000000000';
  }

  public static exportNotesToPdf(items: CalendarItem[], employees: Employee[]) {
    const doc = new jsPDF();
    const lineHeight = 10;
    const contentXPos = 20;
    let textYPos = 20;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    doc.setFontSize(14);

    const moveToNextLine = (isLast?: boolean) => {
      const ySpace = isLast ? lineHeight + 10 : lineHeight;

      textYPos += ySpace;
    };

    items.forEach(item => {
      const { fromDate, toDate, note, employeeId } = item;
      const formattedDate = moment(fromDate).format('DD MMMM YYYY');
      const formattedTime = moment(toDate).format('hh:mm');
      const employee = employees.find(item => item.id === employeeId);
      const employeeName = employee ? `${employee.firstname} ${employee.lastname}` : '';

      if (textYPos >= pageHeight) {
        doc.addPage('a4');
        textYPos = 20;
      }

      doc.text(formattedDate, contentXPos, textYPos);
      moveToNextLine();

      doc.text(formattedTime, contentXPos, textYPos);
      moveToNextLine();

      doc.text(employeeName, contentXPos, textYPos);
      moveToNextLine();

      doc.text(note, contentXPos, textYPos);
      doc.line(contentXPos, textYPos + 5, pageWidth - 20, textYPos + 5);
      moveToNextLine(true);
    });

    doc.save('notes.pdf');
  }

  public static removeEmptyFilterParameters(params: NotesFilterParams): any {
    const newParams = { ...params };
    const keys = Object.keys(newParams);

    keys.forEach(key => {
      const paramValue = newParams[key];
      const paramIsEmpty = !paramValue || (Array.isArray(paramValue) && !paramValue.length);

      if (paramIsEmpty) {
        delete newParams[key];
      }
    });

    return newParams;
  }

  public static sortNotes(items: CalendarItem[]): CalendarItem[] {
    return items.sort((a, b) => {
      const aDate = moment(a.fromDate);
      const aTime = moment(a.fromDate);
      const bDate = moment(b.fromDate);
      const bTime = moment(b.fromDate);

      if (aDate.isAfter(bDate) && aTime.isAfter(bTime)) {
        return -1;
      }

      if (aDate.isBefore(bDate) && aTime.isBefore(bTime)) {
        return 1;
      }

      return 0;
    });
  }

  public static getCalendarItemColor(item: CalendarItem): string {
    if (!item.citizenId && !item.isAccepted) {
      return '#2e4903';
    }

    if (item.goalIds && item.goalIds.length) {
      return '#000080';
    }

    return '#6ba907';
  }

  public static createGoalsCalendarItems(goals: GoalResult[], departmentId: string): CalendarItem[] {
    let items: CalendarItem[] = [];
    const defaultItem = calendarItemDefault();

    goals.forEach(goal => {
      const { citizenEnrollmentId, fromDate, toDate, evaluationDate, title, id, parentGoalId, subGoals } = goal;
      const defaultCalendarItem = {
        ...defaultItem,
        citizenEnrollmentId,
        goalIds: [id],
        groupId: '00000000-0000-0000-0000-000000000000',
        allday: true,
        departmentId,
        isGoal: true,
        isSubGoal: !!parentGoalId,
      };

      const startGoalItem: CalendarItem = {
        ...defaultCalendarItem,
        fromDate: moment(fromDate).startOf('day').toDate(),
        toDate: moment(fromDate).endOf('day').toDate(),
        title: `${title} - start dato`,
      };

      const evaluationGoalItem: CalendarItem = {
        ...defaultCalendarItem,
        fromDate: moment(evaluationDate).startOf('day').toDate(),
        toDate: moment(evaluationDate).endOf('day').toDate(),
        title: `${title} - evaluerings dato`,
      };

      const endGoalItem: CalendarItem = {
        ...defaultCalendarItem,
        fromDate: moment(toDate).startOf('day').toDate(),
        toDate: moment(toDate).endOf('day').toDate(),
        title: `${title} - slut dato`,
      };

      items.push(startGoalItem);
      items.push(evaluationGoalItem);
      items.push(endGoalItem);

      if (subGoals) {
        items = items.concat(UtilsCalendar.createGoalsCalendarItems(subGoals, departmentId));
      }
    });

    return items;
  }

  public static generateBirthdayItems(initialItem: CalendarItem): CalendarItem[] {
    const itemsRule = new RRule({
      freq: RRule.YEARLY,
      dtstart: moment(initialItem.fromDate).toDate(),
      count: 100,
      interval: 1,
    });

    const schedule = rrulestr(itemsRule.toString());
    const datesArray = schedule.all().map(UtilsDate.setUTCPartsToDate);

    return datesArray.map((date, index) => ({
      ...initialItem,
      id: initialItem.id + index,
      fromDate: date,
      toDate: date,
    }));
  }

  public static convertCalendarItemsToCalendarData(
    calendarItems: CalendarItem[],
    medicineItems: Medicine[],
  ): CalendarItemsData {
    const notes = [];
    const medicine = [];
    const goals = [];
    const health = [];
    const timeTrack = [];
    const measurements = [];
    const useOfForce = [];
    let appointments = [];

    calendarItems.forEach(item => {
      const { calendarItemType, calendarStatus } = item;
      const isAppointment = calendarItemType === CalendarItemType.Normal && calendarStatus !== CalendarStatus.Declined;
      const isNote = item.calendarItemType === CalendarItemType.Note;
      const isMedicine =
        item.calendarItemType === CalendarItemType.PN || item.calendarItemType === CalendarItemType.Medicine;
      const isHealthAppointment = calendarItemType === CalendarItemType.HealthCare;
      const isHealthNote = calendarItemType === CalendarItemType.HealthCareNote;
      const isTimeTack = calendarItemType === CalendarItemType.TimeTrack;
      const isMeasurement = calendarItemType === CalendarItemType.Measurement;
      const isGoal =
        calendarItemType === CalendarItemType.GoalTo ||
        calendarItemType === CalendarItemType.GoalFrom ||
        calendarItemType === CalendarItemType.GoalEvaluation;
      const isBirthday = calendarItemType === CalendarItemType.BirthDay;
      const isUseOfForce = calendarItemType === CalendarItemType.UseOfForce;

      if (isAppointment || isBirthday) appointments.push(item);
      if (isNote) notes.push(item);
      if (isMedicine) medicine.push(item);
      if (isGoal) goals.push(item);
      if (isTimeTack) timeTrack.push(item);
      if (isUseOfForce) useOfForce.push(item);

      if (isMeasurement) {
        measurements.push({
          ...item,
          title: UtilsMeasurements.normalizeMeasurementCalendarTitle(item.title),
        });
      }

      if (isHealthAppointment) {
        const modifiedItem = {
          ...item,
          title: UtilsCalendar.translateAppointmentText(item.title),
          note: UtilsCalendar.translateAppointmentText(item.note),
          calendarItemType: CalendarItemType.Normal,
          locked: true,
        };

        health.push(modifiedItem);
      }

      if (isHealthNote) {
        const modifiedItem = {
          ...item,
          title: UtilsCalendar.translateAppointmentText(item.title),
          note: UtilsCalendar.translateAppointmentText(item.note),
          calendarItemType: CalendarItemType.Note,
          locked: true,
        };

        health.push(modifiedItem);
      }

      if (isBirthday) appointments = appointments.concat(UtilsCalendar.generateBirthdayItems(item));
    });
    const calendarMedicineItems = UtilsMedicine.createCalendarItemsFromMedicineItems(medicineItems);
    const modifiedGoals = goals.map(item => UtilsCalendar.modifyTitleForGoalItem(item));

    return {
      appointments,
      notes,
      health,
      medicine: medicine.concat(calendarMedicineItems),
      goals: modifiedGoals,
      timeTrack,
      measurements,
      useOfForce,
    };
  }

  public static translateAppointmentText(text: string) {
    let untranslatedStarted = false;
    let res = '';
    let keyToTranslate = '';

    for (let i = 0; i < text.length; i++) {
      if (text[i] === '[') {
        untranslatedStarted = true;
        continue;
      }

      if (text[i] === ']') {
        untranslatedStarted = false;
        res += i18n.t(keyToTranslate);
        keyToTranslate = '';
        continue;
      }

      if (!untranslatedStarted) res += text[i];

      if (untranslatedStarted) keyToTranslate += text[i];
    }

    return res;
  }

  public static modifyTitleForGoalItem(calendarItem: CalendarItem): CalendarItem {
    const { calendarItemType } = calendarItem;
    let title = calendarItem.title;

    switch (calendarItemType) {
      case CalendarItemType.GoalFrom:
        title = `${title} Startdato`;
        break;
      case CalendarItemType.GoalEvaluation:
        title = `${title} Evalueringsdato`;
        break;
      case CalendarItemType.GoalTo:
        title = `${title} Slutdato`;
        break;
      default:
        break;
    }

    return { ...calendarItem, title };
  }

  public static getTimeTrackDifference(toDate: Date, fromDate: Date): string {
    const diffInMinutes = moment(toDate).diff(moment(fromDate), 'minutes');
    const diffInHours = diffInMinutes / 60;
    const fixedDiff = diffInHours.toFixed(1);
    const fixedDiffArr = fixedDiff.split('.');

    return fixedDiffArr[1] === '0' ? fixedDiffArr[0] : fixedDiff;
  }

  public static isCalendarColorLight(color: string): boolean {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

    return brightness > 155;
  }

  public static getSelectedSidebarDataFromLocalStorage(type: 'citizens' | 'employees'): string[] {
    const savedDataJson = LocalStorage.getItem(SIDEBAR_CALENDAR_DATA_NAMESPACE) || '{}';
    const savedData = JSON.parse(savedDataJson);
    const userId = UtilsAuth.getEmployeeId();
    const userData = savedData[userId] ?? {};

    // debugger;

    return userData[type] ?? [];
  }

  public static saveSelectedSidebarDataToLocalStorage(selectedIds: string[], type: 'citizens' | 'employees'): void {
    const savedDataJson = LocalStorage.getItem(SIDEBAR_CALENDAR_DATA_NAMESPACE) || '{}';
    const savedData = JSON.parse(savedDataJson);
    const userId = UtilsAuth.getEmployeeId();
    const userData = savedData[userId] ?? {};

    const updatedData = {
      [userId]: {
        ...userData,
        [type]: selectedIds,
      },
    };

    LocalStorage.setItem(SIDEBAR_CALENDAR_DATA_NAMESPACE, JSON.stringify(updatedData));
  }

  public static isDescendant(parent: HTMLElement | Element, child: HTMLElement) {
    let node = child.parentNode;

    while (node != null) {
      if (node == parent) {
        return true;
      }

      node = node.parentNode;
    }
    return false;
  }

  public static isCalendarMenuChildren(target: HTMLElement): boolean {
    const menuEl = document.getElementById('cm-wrap');
    const subMenus = document.querySelectorAll('.calendar-menu__submenu');

    if (UtilsCalendar.isDescendant(menuEl, target)) {
      return true;
    }

    for (let i = 0; i < subMenus.length; i++) {
      if (UtilsCalendar.isDescendant(subMenus[i], target)) {
        return true;
      }
    }

    return false;
  }

  public static getUpdatedDateByDelta(date: string | Date, delta:  Duration): Date {
    const { years, months, days, milliseconds } = delta;

    return moment(date)
      .add(years, 'years')
      .add(months, 'months')
      .add(days, 'days')
      .add(milliseconds, 'milliseconds')
      .toDate();
  }
}
