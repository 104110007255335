import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '../../_shared/Drawer/Drawer';
import { healthCareDefault } from '../../../services/defaults';
import TextEditor from '../../_shared/_controls/TextEditor/TextEditor';
import { HealthCare, HealthCareProblem } from '../../../services/prodocApi';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import { CommonOption } from '../../../types/common';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';

import './HealthAreaStatusWindow.scss';

interface Props {
  data: CommonOption;
  isOpened: boolean;
  onClose(): void;
  onSave(data: HealthCare): void;
}

export default function HealthAreaStatusWindow(props: Props) {
  const { data, isOpened, onClose, onSave } = props;
  const dispatch = useDispatch();
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { t } = useTranslation();
  const [isChanged, setIsChanged] = useState(false);
  const [formData, setFormData] = useState(healthCareDefault());
  const { description, action, partners, problem } = formData;
  const { label: areaName, value } = data;
  const statusChangeState = pagesState[PAGES_ITEMS.MEDICINE_STATUS];
  const isSaving = pagesState[PAGES_ITEMS.MEDICINE_STATUS].loading;
  const { saved } = statusChangeState;

  useEffect(() => {
    setFormData({ ...formData, care: value });
  }, [value]);

  useEffect(() => {
    if (saved && isOpened) {
      setFormData(healthCareDefault());
      onClose();
    }
  }, [saved]);

  const handleFieldChange = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
    setIsChanged(true);
  };

  const handleClose = () => {
    if (!isChanged) {
      onClose();
      return;
    }

    dispatch(showConfirmModal({
      content: `Ønsker du at annullere? Ikke gemte oplysninger vil blive slettet`,
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: () => {
        onClose();
      },
    }));
  };

  const handleFromSubmit = () => {
    onSave(formData);
  };

  return (
    <Drawer
      title={areaName}
      visible={isOpened}
      saving={isSaving}
      onClose={handleClose}
      onOk={handleFromSubmit}
      onCancel={handleClose}
    >
      <div className="has-window">
        <form className="item-form has-window__form">
          <SelectMenu
            title="Status"
            value={problem}
            name="problem"
            placeholder="Status"
            valueKey="value"
            labelKey="label"
            options={Object.entries(HealthCareProblem).map(([value, label]) => ({ label: t(label), value }))}
            onChange={handleFieldChange}
          />
          <TextEditor title="Beskrivelse" value={description} name="description" onChange={handleFieldChange} />
          <TextEditor title="Handling" value={action} name="action" onChange={handleFieldChange} />
          <TextEditor title="Kompensation" value={partners} name="partners" onChange={handleFieldChange} />
        </form>
      </div>
    </Drawer>
  );
}
