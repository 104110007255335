import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Center } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import Button from '../../_shared/_controls/Button/Button';
import { centerSchema } from '../../../utils/UtilsValidation';
import { CenterState, deleteCenter } from '../../../store/centerStore';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { URLS } from '../../../constants/urls';
import { useNavigation } from '../../../hooks/navigation';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { useZipCodeField } from '../../../hooks/common-hooks';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import { ICONS } from '../../_shared/Icon/Icon';
import { CardActions } from '../../_shared/CardActions/CardActions';
import { Col, Row } from 'antd';

interface Props {
  data: Center;
  editMode: boolean;
  onSubmit(data: Center): void;
  onCancel(): void;
}

export default function CenterForm(props: Props) {
  const { data, editMode, onSubmit, onCancel } = props;

  const dispatch = useDispatch();
  const [navigateTo] = useNavigation();
  const { getZipCodeData } = useZipCodeField();
  const centerStore = useSelector<any, CenterState>(state => state.center);
  const pagesItemsStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const isDataSaved = pagesItemsStore[PAGES_ITEMS.CENTER_FORM].saved;
  const { allCentersList } = centerStore;

  const [center, setCenter] = React.useState<Center>(data);
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: centerSchema,
    defaultValues: data as any,
  });
  useRegisteredFields(centerSchema, register, unregister);

  const { name, id, address, zipCode, city, phoneNumber, email } = center;

  const resetFormValues = () => {
    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });

    setCenter(data);
  };

  React.useEffect(() => {
    setCenter(data);

    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });
  }, [data.id]);

  React.useEffect(() => {
    if (isDataSaved && !editMode) {
      resetFormValues();
    }
  }, [isDataSaved]);

  const handleFieldsChange = (value: any, name: string) => {
    const data = getZipCodeData(value, name, { ...center, [name]: value });

    setCenter(data);
    setValue(name, value);
    clearError(name);
  };

  const handleFormSubmit = () => {
    onSubmit(center);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleCenterSelect = (selectedId: string) => {
    if (selectedId) {
      const route = UtilsNavigation.buildRoute(URLS.CENTER, URLS.DETAILS, selectedId);

      navigateTo(route);
    }
  };

  const handleDelete = () => {
    dispatch(
      showConfirmModal({
        title: 'Ønsker du at slette dette center?',
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          dispatch(deleteCenter(id));
        },
      })
    );
  };
  const isEditAndDisabled = editMode && !id;

  return (
    <form className="center-form item-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Card>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput
              disabled={isEditAndDisabled}
              title="Center navn*"
              name="name"
              value={name}
              error={errors.name?.message}
              onChange={handleFieldsChange}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput
              disabled={isEditAndDisabled}
              title="Adresse"
              name="address"
              value={address}
              onChange={handleFieldsChange}
            />
          </Col>
          <Col span={4}>
            <TextInput
              disabled={isEditAndDisabled}
              title="Postnummer"
              name="zipCode"
              value={zipCode}
              onChange={handleFieldsChange}
            />
          </Col>
          <Col span={8}>
            <TextInput disabled={isEditAndDisabled} title="By" name="city" value={city} onChange={handleFieldsChange} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput
              disabled={isEditAndDisabled}
              title="Telefonnummer"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleFieldsChange}
            />
          </Col>
          <Col span={12}>
            <TextInput
              disabled={isEditAndDisabled}
              title="Email"
              name="email"
              value={email}
              error={errors.email?.message}
              onChange={handleFieldsChange}
            />
          </Col>
        </Row>
        <CardActions
          leftActions={
            <>{editMode && <Button title="Slet" icon={ICONS.delete} disabled={!id} onClick={handleDelete} />}</>
          }
          rightActions={
            <>
              <Button disabled={isEditAndDisabled} title={editMode ? 'Opdater' : 'Gem'} htmlType="submit" />
              <Button title="Annuller" onClick={handleCancel} />
            </>
          }
        />
      </Card>
    </form>
  );
}
