import * as React from 'react';
import { CPR_SYMBOLS_REGEX } from '../constants/regex';

export function useRegisteredFields(schema: any, register: Function, unregister: Function) {
  React.useEffect(() => {
    const fieldsNames = Object.keys(schema.fields);

    fieldsNames.forEach((name) => {
      unregister(name);
    });

    fieldsNames.forEach((name) => {
      register({ name: name });
    });

    return () => {
      fieldsNames.forEach((name) => {
        unregister(name);
      });
    };
  }, [schema]);
}

export function useCprValidation() {
  const isCprValid = (value: string): boolean => {
    // Cpr field accepts only numbers
    if (!CPR_SYMBOLS_REGEX.test(value)) return false;

    if (value.length > 11) return false;

    return true;
  };

  const isCprFilled = (value: string): boolean => {
    return value.length === 10 || value.length === 11;
  };

  return { isCprValid, isCprFilled };
}
