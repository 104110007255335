import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MomentInput } from 'moment';

import { CalendarItemType, HealthCareType, Note } from '../../../services/prodocApi';
import CalendarItemDrawer from '../CalendarItemDrawer/CalendarItemDrawer';
import RadioButtons from '../../_shared/_controls/RadioButtons/RadioButtons';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import TimePicker from '../../_shared/_controls/TimePicker/TimePicker';
import Button from '../../_shared/_controls/Button/Button';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import TextEditor from '../../_shared/_controls/TextEditor/TextEditor';
import { LoadingAnimation } from '../../_shared/LoadingAnimation/LoadingAnimation';
import { NoteTypes } from '../../../constants/calendar';
import { noteDefault } from '../../../services/defaults';
import { CalendarState } from '../../../store/calendarStore';
import { showErrorMessage } from '../../../store/notificationStore';
import { CommonState } from '../../../store/commonStore';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { useGoalsRequest } from '../../../hooks/goal-hooks';
import { CalendarItemExt } from '../../../types/calendar';

export interface Props {
  isOpened: boolean;
  preselectedEnrollmentId?: string;
  data?: CalendarItemExt;
  noteData?: Note;
  isLoading?: boolean;
  onClose(): void;
  onUpdateCalendarItem(data: Note);
}

const noteTypes = [
  {
    label: 'Borger',
    value: NoteTypes.Citizen,
  },
  {
    label: 'Afdeling',
    value: NoteTypes.Department,
  },
];

export default function CalendarNoteWindow(props: Props) {
  const { data, isOpened, isLoading, noteData, onClose, preselectedEnrollmentId, onUpdateCalendarItem } = props;
  const dispatch = useDispatch();
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const { goals, loadGoals, resetGoals } = useGoalsRequest();
  const commonStore = useSelector<ApplicationState, CommonState>(state => state.common);
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pageStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { userEnrollments } = commonStore;
  const { citizensEnrollments, departmentId: calendarDepartmentId } = calendarStore;
  const isSaving = pageStore[PAGES_ITEMS.CALENDAR_ITEM].loading || isLoading;
  const areasOptions = useMemo(() => translateEnumOptions(HealthCareType).filter(x => x.value !== 'None'), []);

  const [defaultData, setDefaultData] = useState<Note>(noteDefault());
  const [formData, setFormData] = useState<Note>(noteDefault());
  const [noteType, setNoteType] = useState(NoteTypes.Citizen);
  const [isEdited, setIsEdited] = useState(false);
  const [goal, setGoal] = useState<string>();
  const { citizenEnrollmentId, departmentId, fromDate, health, text, subGoalId } = formData;
  const isCitizenType = noteType === NoteTypes.Citizen;
  const citizenEnrollmentsList = useMemo(() => {
    return citizensEnrollments.filter(item => item.departmentId === calendarDepartmentId);
  }, [calendarDepartmentId, citizensEnrollments.length]);
  const isForDepartment = noteType === NoteTypes.Department;
  const disableTags = isForDepartment;
  const disableFields = data?.locked;

  const loadNoteData = async () => {
    let updatedData = noteDefault();

    updatedData = {
      ...updatedData,
      fromDate: data?.fromDate,
      text: data?.note,
      health: data?.health as any[],
      subGoalId: data?.goal?.id,
      citizenEnrollmentId: preselectedEnrollmentId,
    };

    const newNoteType = updatedData.departmentId ? NoteTypes.Department : NoteTypes.Citizen;

    setGoal(updatedData.subGoalId);
    setFormData(updatedData);
    setDefaultData(updatedData);
    setNoteType(newNoteType);
  };

  const subGoalsList = useMemo(() => {
    if (!goals.length) return [];

    return goals.filter(item => item.parentGoalId);
  }, [goals.length]);

  useEffect(() => {
    if (!isOpened) {
      setIsEdited(false);
      setFormData(noteDefault());
    }
  }, [isOpened]);

  useEffect(() => {
    if (data?.calendarItemType === CalendarItemType.Note) {
      loadNoteData();
    }
  }, [data?.calendarItemType, data?.id, isOpened]);

  useEffect(() => {
    if (noteType === NoteTypes.Department) {
      setFormData({ ...formData, citizenEnrollmentId: '', health: [], subGoalId: undefined });
    } else {
      setFormData({ ...formData, departmentId: '' });
    }
  }, [noteType]);

  useEffect(() => {
    if (citizenEnrollmentId) loadGoals(citizenEnrollmentId);
  }, [citizenEnrollmentId]);

  useEffect(() => {
    if (!noteData) return;

    setFormData(noteData);
    setNoteType(noteData.citizenEnrollmentId ? NoteTypes.Citizen : NoteTypes.Department);
  }, [noteData]);

  const handleClose = () => {
    if (!isEdited) {
      setFormData(noteDefault());
      setGoal(undefined);
      resetGoals();
      onClose();
      return;
    }

    dispatch(
      showConfirmModal({
        content: `Ønsker du at annullere? Ikke gemte oplysninger vil blive slettet`,
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          onClose();
        },
      })
    );
  };

  const handleFieldChange = (value: Date | string | boolean | MomentInput, name: string) => {
    setFormData({ ...formData, [name]: value });

    if (defaultData[name] !== value) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  };

  const handleNoteTypeChange = newType => {
    setNoteType(newType);
  };

  const handleGoalChange = (value: string) => {
    setGoal(value);
  };

  const handleFormSubmit = () => {
    if (!citizenEnrollmentId && !departmentId) {
      dispatch(showErrorMessage({ message: 'Vælg en borger eller en afdeling' }));
      return;
    }

    if (citizenEnrollmentId && departmentId) {
      dispatch(showErrorMessage({ message: 'Notat kan kun være knyttet til borger eller afdeling, ikke begge' }));
      return;
    }

    const dataToSave: Note = { ...formData };

    dataToSave.subGoalId = goal;

    onUpdateCalendarItem(dataToSave);
  };

  return (
    <CalendarItemDrawer
      title="Notat"
      isOpen={isOpened}
      buttonsRight={
        <>
          <LoadingAnimation active={isSaving} />
          <Button title="Gem" disabled={isSaving} onClick={handleFormSubmit} />
          <Button title="Annuller" onClick={handleClose} />
        </>
      }
      onClose={handleClose}>
      <form className="common-form" onSubmit={handleFormSubmit}>
        <RadioButtons
          title="Notat på"
          value={noteType}
          options={noteTypes}
          disabled={Boolean(noteData)}
          valueKey="value"
          labelKey="label"
          onChange={handleNoteTypeChange}
        />
        {isCitizenType ? (
          <SelectMenu
            value={citizenEnrollmentId}
            name="citizenEnrollmentId"
            title="Vælg borger"
            placeholder="Vælg borger"
            valueKey="id"
            withSearch
            disabled={disableFields}
            allowClear
            labelKey={['firstname', 'lastname']}
            options={citizenEnrollmentsList}
            onChange={handleFieldChange}
          />
        ) : (
          <SelectMenu
            value={departmentId}
            name="departmentId"
            title="Vælg afdeling"
            placeholder="Vælg afdeling"
            valueKey="departmentId"
            withSearch
            allowClear
            disabled={disableFields}
            labelKey="departmentName"
            options={userEnrollments}
            onChange={handleFieldChange}
          />
        )}
        <DatePicker
          title="Dato"
          name="fromDate"
          disabled={disableFields}
          value={fromDate}
          onChange={handleFieldChange}
        />
        <TimePicker
          title="Tidspunkt"
          name="fromDate"
          disabled={disableFields}
          value={fromDate}
          onChange={handleFieldChange}
        />
        <SelectMenu
          value={health}
          name="health"
          title="Tilknyt helbredsområde"
          placeholder="Tilknyt helbredsområde"
          valueKey="value"
          allowClear
          multiple
          disabled={disableTags || disableFields}
          labelKey="label"
          options={areasOptions}
          onChange={handleFieldChange}
        />
        <SelectMenu
          value={subGoalId}
          title="Tilknyt delmål"
          placeholder="Tilknyt delmål"
          valueKey="id"
          labelKey="title"
          disabled={isForDepartment}
          options={subGoalsList}
          allowClear
          onChange={handleGoalChange}
        />
        <TextEditor title="Notat" name="text" disabled={disableFields} value={text} onChange={handleFieldChange} />
      </form>
    </CalendarItemDrawer>
  );
}
