import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DepartmentState,
  getDepartmentById,
  setDepartmentEdit,
  updateDepartment,
} from '../../../store/departmentStore';
import { departmentDefault } from '../../../services/defaults';
import { useRouterParams } from '../../../hooks/navigation';
import PageTitleContext from '../../../context/PageTitleContext';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { navigateTo, navigateToHome } from '../../../store/navigationStore';
import { ROUTES } from '../../../constants/routes';
import DepartmentForm from '../../../components/departments/DepartmentForm/DepartmentForm';
import { loadUserDepartments } from '../../../store/userStore';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import Button from '../../../components/_shared/_controls/Button/Button';
import { useHistory } from 'react-router';

export default function DepartmentEditPage() {
  const dispatch = useDispatch();
  const centerState = useSelector<any, DepartmentState>(state => state.department);
  const { setTitle } = React.useContext(PageTitleContext);
  const [match] = useRouterParams();
  const { editDepartment } = centerState;
  const departmentData = editDepartment || departmentDefault();
  const departmentId = match.params.id;
  const history = useHistory();

  useEffect(() => {
    setTitle('Rediger afdeling');
    dispatch(loadUserDepartments());
  }, []);

  React.useEffect(() => {
    if (departmentId) {
      dispatch(getDepartmentById(departmentId));
    }

    return () => {
      dispatch(setDepartmentEdit(null));
    };
  }, [departmentId]);

  const handleFormSubmit = (data: any) => {
    dispatch(updateDepartment(data));
  };

  const handleCancel = () => {
    // if (UtilsNavigation.shouldReturnToOverview()) {
    //   dispatch(navigateTo(ROUTES.OVERVIEW));
    // } else {
    //   dispatch(navigateToHome());
    // }
    history.push(ROUTES.DEPARTMENT_OVERVIEW);
  };

  return (
    <>
      {/* <Sidebar>
        <Button title="Rediger afdeling" className="fullwidth" />
      </Sidebar> */}
      <div className="department-page page">
        <DepartmentForm data={departmentData} editMode onSubmit={handleFormSubmit} onCancel={handleCancel} />
      </div>
    </>
  );
}
