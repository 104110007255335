import { Modal, Upload as AntUpload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
export interface UploadProps {
    children: React.ReactNode;
    dragEnterControl?: React.ReactNode;
    onUpload: (file: RcFile) => Promise<void>
}

export function Upload(props: UploadProps) {
    const [mousehover, setMousehover] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [file, setFile] = useState<RcFile>()
    const { onUpload, children, dragEnterControl } = props;
    function handleBeforeUpload(f: RcFile, fl: RcFile[]): boolean {
        setIsVisible(true)
        setFile(f);
        
        return false;
    }
    function handleOk() {
        setIsVisible(false);
        onUpload(file)
    };

    function handleCancel() {
        setIsVisible(false);
    };
    return (
        <div style={{ width: '100%' }} onDrop={() => setMousehover(false)} onDragEnter={() => setMousehover(true)} onDragLeave={() => setMousehover(false)}>
            <AntUpload
                showUploadList={false}
                openFileDialogOnClick={false}
                beforeUpload={handleBeforeUpload}
                style={{ width: '100%' }}>
                {!mousehover && children}
                {mousehover && (dragEnterControl || children)}
            </AntUpload>
            <Modal width={800} title="Upload to..."  visible={isVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal>
        </div>
    );
};
