import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';

(window as any).__localeId__ = 'en';

 ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

declare let module: { hot: any };

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default;
    ReactDOM.render(<NewApp />, document.getElementById('root'));
  });
}
