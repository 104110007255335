import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Employee } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import Title from 'antd/lib/typography/Title';
import Button from '../../_shared/_controls/Button/Button';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import { employeeSchema } from '../../../utils/UtilsValidation';
import { useCprValidation, useRegisteredFields } from '../../../hooks/validation-hooks';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { UtilsItems } from '../../../utils/UtilsItems';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import { useExistingEmployeeCheck } from '../../../hooks/employee-hooks';
import { useZipCodeField } from '../../../hooks/common-hooks';
import { CardActions } from '../../_shared/CardActions/CardActions';

import './EmployeeCreateForm.scss';

interface Props {
  data: Employee;
  onSubmit(data: Employee): void;
  onCancel(): void;
  onEnroll(id: string): void;
}

interface PasswordData {
  password: string;
  passwordRepeat: string;
}

const authOptions = UtilsAuth.getAuthTypeOptions();
const defaultPasswordsData = {
  password: '',
  passwordRepeat: '',
};

export default function EmployeeCreateForm(props: Props) {
  const { data, onSubmit, onCancel, onEnroll } = props;
  const dispatch = useDispatch();
  const { getZipCodeData } = useZipCodeField();
  const pagesItemsStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { isCprUsed } = useExistingEmployeeCheck();
  const { isCprValid, isCprFilled } = useCprValidation();
  const isDataSaved = pagesItemsStore[PAGES_ITEMS.EMPLOYEE_FORM].saved;
  const [isDisabled, setIsDisabled] = useState(true);
  const [employee, setEmployee] = React.useState<Employee>(data);
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: employeeSchema,
    defaultValues: data as any,
  });
  useRegisteredFields(employeeSchema, register, unregister);
  const [passwordData, setPasswordData] = React.useState<PasswordData>(defaultPasswordsData);

  const {
    firstname,
    username,
    lastname,
    address,
    zipCode,
    city,
    phone,
    phonePrivate,
    email,
    emailPrivate,
    authType,
    cpr,
  } = employee;
  const { password, passwordRepeat } = passwordData;

  const resetFormFields = () => {
    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });

    setEmployee(data);
  };

  React.useEffect(() => {
    resetFormFields();

    setPasswordData({
      password: data.password,
      passwordRepeat: data.password,
    });
    setValue('passwordRepeat', data.password);

    if (!data.id) {
      setPasswordData(defaultPasswordsData);
    }
  }, [data.id]);

  React.useEffect(() => {
    if (isDataSaved) {
      resetFormFields();
    }
  }, [isDataSaved]);

  const handleExistingUserCheck = async (value: string) => {
    if (!value) return;

    const userId = await isCprUsed(value);

    if (!userId) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);

      dispatch(
        showConfirmModal({
          title: 'Medarbejderen eksisterer allerede. Ønsker du at ansætte medarbejderen på en afdeling?',
          okText: 'Ja',
          cancelText: 'Nej',
          onOk: () => {
            onEnroll(userId);
          },
        }),
      );
    }
  };

  const handleFieldsChange = (value: any, name: string) => {
    const zipData = getZipCodeData(value, name, { ...employee, [name]: value });

    setEmployee(zipData);
    setValue(name, value);
    clearError(name);
  };

  const handleCprFieldChange = (value: string) => {
    let handledValue = value;

    if (!isCprValid(value)) return;

    if (isCprFilled(value)) {
      handleExistingUserCheck(value);
    }

    handledValue = UtilsItems.formatCprNumber(value);

    setEmployee({ ...employee, cpr: handledValue });
    setValue('cpr', handledValue);
    clearError('cpr');
  };

  const handlePasswordChange = (value: any, name: string) => {
    setPasswordData({ ...passwordData, [name]: value });

    setValue(name, value);
    clearError(name);
  };

  const handleSelectChange = (value: string, name: string) => {
    setEmployee({ ...employee, [name]: value });
  };

  const handleFormSubmit = () => {
    onSubmit({ ...employee, password: passwordData.password });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <form className="employee-form item-form" style={{ display: 'block' }} onSubmit={handleSubmit(handleFormSubmit)}>
      <Card>
        <div className="flexContainer">
          <div className="employee-form__info">
            <Title level={3}>Medarbejder oplysninger</Title>
            <TextInput
              title="Cpr"
              name="cpr"
              value={cpr}
              error={errors.cpr && errors.cpr.message}
              onChange={handleCprFieldChange}
            />
            <div className="flexContainer">
            <span className="standardSpaceRightTextbox">
              <TextInput
                title="Fornavn*"
                name="firstname"
                value={firstname}
                error={errors.firstname && errors.firstname.message}
                onChange={handleFieldsChange}
                disabled={isDisabled}
              />
            </span>
              <TextInput
                title="Efternavn*"
                name="lastname"
                value={lastname}
                error={errors.lastname && errors.lastname.message}
                onChange={handleFieldsChange}
                disabled={isDisabled}
              />
            </div>
            <TextInput
              title="Adresse (Privat)"
              name="address"
              value={address}
              onChange={handleFieldsChange}
              disabled={isDisabled}
            />
            <div className="flexContainer">
            <span className="standardSpaceRightTextbox">
              <TextInput
                title="Postnummer"
                name="zipCode"
                value={zipCode}
                onChange={handleFieldsChange}
                disabled={isDisabled}
              />
            </span>
              <TextInput title="By" name="city" value={city} onChange={handleFieldsChange} disabled={isDisabled} />
            </div>
            <div className="flexContainer">
            <span className="standardSpaceRightTextbox">
              <TextInput
                title="Telefon (Arbejde)"
                name="phone"
                value={phone}
                onChange={handleFieldsChange}
                disabled={isDisabled}
              />
            </span>
              <TextInput
                title="Telefon (Privat)"
                name="phonePrivate"
                value={phonePrivate}
                onChange={handleFieldsChange}
                disabled={isDisabled}
              />
            </div>
            <TextInput
              title="Email (Arbejde)*"
              name="email"
              value={email}
              error={errors.email && errors.email.message}
              onChange={handleFieldsChange}
              disabled={isDisabled}
            />
            <TextInput
              title="Email (Privat)"
              name="emailPrivate"
              value={emailPrivate}
              onChange={handleFieldsChange}
              disabled={isDisabled}
            />
          </div>
          <div className="employee-form__account">
            <Title level={3}>Bruger oplysninger</Title>

            <TextInput
              title="Brugernavn*"
              autoComplete="off"
              name="username"
              value={username}
              error={errors.username && errors.username.message}
              onChange={handleFieldsChange}
              disabled={isDisabled}
            />
            <TextInput
              title="Kodeord*"
              autoComplete="off"
              name="password"
              type="password"
              value={password}
              error={errors.password && errors.password.message}
              onChange={handlePasswordChange}
              disabled={isDisabled}
            />
            <TextInput
              title="Gentag kodeord*"
              autoComplete="off"
              name="passwordRepeat"
              type="password"
              value={passwordRepeat}
              error={errors.passwordRepeat && errors.passwordRepeat.message}
              onChange={handlePasswordChange}
              disabled={isDisabled}
            />
            <SelectMenu
              title="System rettighed"
              name="authType"
              value={authType}
              options={authOptions}
              valueKey="value"
              labelKey="value"
              onChange={handleSelectChange}
              disabled={isDisabled}
            />
          </div>
        </div>
        <CardActions
          rightActions={(
            <>
              <Button title="Fortsæt" htmlType="submit" />
              <Button title="Annuller" onClick={handleCancel} />
            </>
          )}
        />
      </Card>
    </form>
  );
}
