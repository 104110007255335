import * as React from 'react';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { SelectOption } from '../types/commonItems';
import {
  CalendarItem,
  CalendarItemType,
  Citizen,
  CitizenEnrollment,
  DepartmentCitizenEnrollmentResult,
  EmployeeEnrollment,
  Goal,
  Medicine,
  MedicineType,
} from '../services/prodocApi';
import {
  calendarItemDefault,
  citizenDefault,
  citizenEnrollmentDefault,
  employeeEnrollmentDefault,
  medicineDefault,
} from '../services/defaults';
import { CitizenDischarge } from '../types/citizen';
import { EmployeeDischarge } from '../types/employee';
import { CitizenDepartmentOption } from '../types/common';

export class UtilsData {
  public static getEnrollFromOptions(): SelectOption[] {
    return [
      {
        value: 'PrivateLiving',
        label: 'Privat bolig',
      },
      {
        value: 'Psychiatry',
        label: 'Psykiatri',
      },
      {
        value: 'Municipally',
        label: 'Kommunalt tilbud',
      },
      {
        value: 'Regional',
        label: 'Regionalt tilbud',
      },
      {
        value: 'PrivateOffer',
        label: 'Privat tilbud',
      },
      {
        value: 'Street',
        label: 'Gaden',
      },
      {
        value: 'FosterHome',
        label: 'Plejefamilie',
      },
    ];
  }

  public static getCitizenDefaults(): Citizen {
    const emptyCitizen = citizenDefault();

    return {
      ...emptyCitizen,
      typeOfConviction: null,
      convictionStartDate: null,
      convictionEndtDate: null,
    };
  }

  public static getCitizenDischargeDefaults(): CitizenDischarge {
    return {
      departmentId: '',
      citizenId: '',
      date: null,
      cause: '',
    };
  }

  public static getEmployeeDischargeDefaults(): EmployeeDischarge {
    return {
      departmentId: '',
      employeeId: '',
      date: null,
    };
  }

  public static removeDuplicatesFromArray = (data: any[], fieldName: string): any[] => {
    return data.filter((thing, index, self) => index === self.findIndex(t => t[fieldName] === thing[fieldName]));
  };

  public static getCitizenEnrollmentDefaults(): CitizenEnrollment {
    return {
      ...citizenEnrollmentDefault(),
      enrollmentDate: new Date(),
    };
  }

  public static getEmployeeEnrollmentDefaults(): EmployeeEnrollment {
    return {
      ...employeeEnrollmentDefault(),
      enrollmentDate: new Date(),
    };
  }

  public static getGoalDefaults(): Goal {
    return {
      ...employeeEnrollmentDefault(),
      fromDate: new Date(),
      evaluationDate: new Date(),
      toDate: new Date(),
    };
  }

  public static getMedicineDefaults(): Medicine {
    return {
      ...medicineDefault(),
      doseUnit: null,
      fromDate: new Date(),
      medicineType: MedicineType.PrescribedMedicine,
      toDate: moment().add(1, 'day').toDate(),
    };
  }

  public static generateId(): string {
    return nanoid();
  }

  public static getCalendarItemDefault(type?: CalendarItemType): CalendarItem {
    return {
      ...calendarItemDefault(),
      calendarItemType: type || CalendarItemType.Normal,
      fromDate: new Date(),
      toDate: new Date(),
    };
  }

  public static isDefaultHtmlString(str: string): boolean {
    return !str || str === '<p></p>\n';
  }

  public static convertCitizenDepartmentResToOption(items: DepartmentCitizenEnrollmentResult, useCitizenEnrollment: boolean): CitizenDepartmentOption[] {
    const options: CitizenDepartmentOption[] = [];
    const { citizenEnrollments, departments } = items;

    const citizenEnrollmentsList = useCitizenEnrollment
      ? citizenEnrollments
      : UtilsData.removeDuplicatesFromArray(citizenEnrollments, 'citizenId');

    citizenEnrollmentsList.forEach((item) => {
      const { citizenId, firstname, lastname, id, departmentName } = item;
      const handledDepartmentName = useCitizenEnrollment ? ` (${departmentName})` : '';
      const handledValue = useCitizenEnrollment ? id : citizenId;

      options.push({
        value: handledValue,
        label: `${firstname} ${lastname}${handledDepartmentName}`,
        citizenEnrollmentId: id,
        isDepartment: false,
      });
    });

    departments.forEach(({ id, name }) => {
      options.push({
        value: id,
        label: name,
        isDepartment: true,
      });
    });

    return options;
  }
}
