import * as Yup from 'yup';
import { EMAIL_REGEX } from '../constants/regex';

export const requiredMessage = 'Skal udfyldes';
const cprMessage = 'Cpr nummer er ikke korrekt';
const passwordConfirmMessage = 'Adgangskoder er forskellige';
const goalStartDateMessage = 'Start dato skal være før evaluerings og slut dato';
const evaluationDateMessage = 'Evalueringsdato skal være før slutdato';
const emailMessage = 'Email er forkert';

const getMinMessage = ({ min }) => `Min ${min}`;
const getMaxMessage = ({ max }) => `Max ${max}`;

export const citizenSchema = Yup.object().shape({
  firstname: Yup.string().required(requiredMessage),
  lastname: Yup.string().required(requiredMessage),
  cpr: Yup.string()
    .required(requiredMessage)
    .matches(/(\d{10})|(\d{6}-\d{4})/, cprMessage),
});

export const citizenCreateFullSchema = Yup.object().shape(
  {
    firstname: Yup.string().required(requiredMessage),
    lastname: Yup.string().required(requiredMessage),
    cpr: Yup.string().when('foreignId', {
      is: foreignId => !foreignId || foreignId.length === 0,
      then: Yup.string()
        .required(requiredMessage)
        .matches(/(\d{10})|(\d{6}-\d{4})/, cprMessage),
      otherwise: Yup.string(),
    }),
    foreignId: Yup.string().when('cpr', {
      is: cpr => !cpr || cpr.length === 0,
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string(),
    }),
  },
  [['cpr', 'foreignId']]
);

export const citizenEnrollSchema = Yup.object().shape({
  citizenId: Yup.string().required(requiredMessage),
  enrolledFrom: Yup.string().required(requiredMessage).nullable(),
  enrollmentDate: Yup.string().nullable().required(requiredMessage),
});

export const citizenDischargeSchema = Yup.object().shape({
  date: Yup.string().nullable().required(requiredMessage).nullable(),
  // cause: Yup.string().nullable().required(requiredMessage),
});

export const employeeSchema = Yup.object().shape({
  firstname: Yup.string().required(requiredMessage),
  cpr: Yup.string()
    .required(requiredMessage)
    .matches(/(\d{10})|(\d{6}-\d{4})/, cprMessage),
  lastname: Yup.string().required(requiredMessage),
  username: Yup.string().required(requiredMessage),
  password: Yup.string().required(requiredMessage),
  email: Yup.string().required(requiredMessage).matches(EMAIL_REGEX, { message: emailMessage }),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], passwordConfirmMessage)
    .required(requiredMessage),
});

export const employeeEnrollSchema = Yup.object().shape({
  employeeId: Yup.string().required(requiredMessage),
  enrollmentDate: Yup.string().nullable().required(requiredMessage),
  position: Yup.string().nullable().required(requiredMessage),
});

export const employeeDischargeSchema = Yup.object().shape({
  departmentId: Yup.string().required(requiredMessage),
  employeeId: Yup.string().required(requiredMessage),
  date: Yup.string().nullable().required(requiredMessage).nullable(),
});

export const centerSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage),
  email: Yup.string().matches(EMAIL_REGEX, { message: emailMessage, excludeEmptyString: true }),
});

export const departmentSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage),
  centerId: Yup.string().required(requiredMessage),
  email: Yup.string().matches(EMAIL_REGEX, { message: emailMessage, excludeEmptyString: true }),
});

export const calendarAppointmentSchema = Yup.object().shape({
  title: Yup.string().required(requiredMessage),
  employees: Yup.string().required(requiredMessage),
});

export const goalSchema = Yup.object().shape({
  title: Yup.string().required(requiredMessage),
  goalStatus: Yup.string().required(requiredMessage),
  endDate: Yup.date().default(() => new Date()),
  evaluationDate: Yup.date().when('toDate', (toDate, schema) => toDate && schema.max(toDate, evaluationDateMessage)),
  startDate: Yup.date(requiredMessage)
    .when('toDate', (toDate, schema) => toDate && schema.max(toDate, goalStartDateMessage))
    .when(
      'evaluationDate',
      (evaluationDate, schema) => evaluationDate && schema.max(evaluationDate, goalStartDateMessage)
    ),
});

export const bloodSugarSchema = Yup.object().shape({
  millimol: Yup.number().typeError(requiredMessage).max(50, getMaxMessage),
});

export const temperatureSchema = Yup.object().shape({
  bodyTemperature: Yup.number().typeError(requiredMessage).min(25, getMinMessage).max(50, getMaxMessage),
});
