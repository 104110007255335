import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import PdfEdit, { Result } from './PdfEdit';
import prodocApi, { Citizen, UseOfForceDto, UseOfForceStatus } from '../../services/prodocApi';
import Button from '../../components/_shared/_controls/Button/Button';
import { usePageTitle } from '../../hooks/common-hooks';
import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UtilsAuth } from '../../utils/UtilsAuth';
import { defaultSettings } from '../../store/notificationStore';

import './UseOfForce.scss';

export interface SavedData {
  id: string;
  value: string;
  fieldName: string;
}

export default function UseOfForcePage() {
  const { search } = useLocation();
  const queryStrings = queryString.parse(search);
  const [citizen, setCitizen] = useState<Citizen>();
  const [data, setData] = useState<SavedData[]>([]);
  const [model, setModel] = useState<UseOfForceDto>({});
  const [hasBeenSaved, setHasBeenSaved] = useState(!!queryStrings.id);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { status } = model;
  usePageTitle(`${queryStrings.doc.toString().replace('Schema', 'Skema ')} ${getTitle()}`);

  useEffect(() => {
    loadData();
  }, [location.search]);

  async function loadData() {
    const citizenId = queryStrings.cid.toString();

    try {
      setIsLoading(true);
      if (queryStrings.id) {
        var result = await prodocApi.useOfForce.get(queryStrings.id.toString());
        setData(JSON.parse(result.jsonString) as []);
        setModel(result);
        // ;
      } else {
        var c = await prodocApi.citizen.get(citizenId);
        setCitizen(c);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function getTitle() {
    switch (status) {
      case UseOfForceStatus.Employee:
        return '(Sendt til medarbejder)';
      case UseOfForceStatus.Authority:
        return '(Sendt til socialtilsyn)';
      case UseOfForceStatus.CenterManager:
        return '(Sendt til centerleder)';
      case UseOfForceStatus.DepartmentManager:
        return '(Sendt til afdelingsleder)';
      default:
        return '';
    }
  }

  function handleChange(value: Result[]) {
    setData(value);
  }

  async function handleSaveClick() {
    try {
      setIsLoading(true);
      if (queryStrings.id) {
        const result = await prodocApi.useOfForce.update(queryStrings.id.toString(), JSON.stringify(data));
      } else {
        const result = await prodocApi.useOfForce.add(
          queryStrings.doc.toString(),
          queryStrings.v.toString(),
          queryStrings.ceid.toString(),
          queryStrings.dt.toString(),
          JSON.stringify(data)
        );
        history.push(`${search}&id=${result}`);
        setHasBeenSaved(true);

        loadData();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function gotoHomrPage() {
    notification.success({ ...defaultSettings, message: 'Magtanvendelse sendt' });

    history.push(`/home/${UtilsAuth.getEmployeeId()}`);
  }

  async function handleSendToEmployee() {
    try {
      setIsLoading(true);
      await prodocApi.useOfForce.sendToEmployee(queryStrings.id.toString());
      gotoHomrPage();
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSendToCenterManager() {
    try {
      setIsLoading(true);
      await prodocApi.useOfForce.sendToCenterManager(queryStrings.id.toString());
      gotoHomrPage();
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSendToDepartmentManager() {
    try {
      setIsLoading(true);
      await prodocApi.useOfForce.sendToDepartmentManager(queryStrings.id.toString());
      gotoHomrPage();
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSendToAuthority() {
    try {
      setIsLoading(true);
      await prodocApi.useOfForce.sendToAuthority(queryStrings.id.toString());
      gotoHomrPage();
    } finally {
      setIsLoading(false);
    }
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} translate={undefined} />;
  return (
    <>
      <Spin
        indicator={antIcon}
        spinning={isLoading}
        size="large"
        style={{ left: '50%', position: 'absolute', top: '50%', zIndex: 200 }}
      />
      <PdfEdit pdfName={queryStrings.doc + '.pdf'} onChange={handleChange} pdfData={data} citizen={citizen} />
      <div className="footer-action">
        <Button type="primary" title="Gem" disabled={isLoading} onClick={handleSaveClick} />
        &nbsp;
        {hasBeenSaved && (
          <Button
            onClick={handleSendToEmployee}
            disabled={isLoading || status == UseOfForceStatus.Employee}
            title="Send til medarbejder"
          />
        )}
        &nbsp;
        {hasBeenSaved && (
          <Button
            onClick={handleSendToDepartmentManager}
            disabled={isLoading || status == UseOfForceStatus.DepartmentManager}
            title="Send til afdelingsleder"
          />
        )}
        &nbsp;
        {hasBeenSaved && (
          <Button
            onClick={handleSendToCenterManager}
            disabled={isLoading || status == UseOfForceStatus.CenterManager}
            title="Send til centerleder"
          />
        )}
        &nbsp;
        {hasBeenSaved && (
          <Button
            onClick={handleSendToAuthority}
            disabled={isLoading || status == UseOfForceStatus.Authority}
            title="Send til socialtilsyn"
          />
        )}
      </div>
    </>
  );
}
