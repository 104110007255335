import React, { useEffect, useState } from 'react';
import prodocApi, { Center, CitizenEnrollmentResult } from '../../../services/prodocApi';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import { LogoutOutlined, MedicineBoxOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import CitizenDischargeForm from '../../../components/citizen/CitizenDischargeForm/CitizenDischargeForm';
import { dischargeCitizen } from '../../../store/citizenStore';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { ColumnType } from 'antd/es/table';

export function CenterOverviewPage() {
  //getManyByCenter
  const [centers, setCenters] = useState<Center[]>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  usePageTitle('Centre');

  useEffect(() => {
    GetEnrollments();
  }, []);
  async function GetEnrollments() {
    try {
      setIsLoading(true);
      const result = await prodocApi.center.getMyCenters();
      setCenters(result);
    } finally {
      setIsLoading(false);
    }
  }
  async function showHealthCitizen(e, citizenEnrollment: CitizenEnrollmentResult) {
    e.stopPropagation();
    history.push(ROUTES.MEDICINE_HEALTH.replace(':citizenEnrollmentId?', citizenEnrollment.id));
  }
  async function showGoalCitizen(e, citizenEnrollment: CitizenEnrollmentResult) {
    e.stopPropagation();
    history.push(ROUTES.GOALS.replace(':citizenEnrollmentId?', citizenEnrollment.id));
  }

  const columns: ColumnType<Center>[] = [
    {
      title: 'Center',
      render: (o: Center) => o.name,
      key: 'name',
    },
    {
      title: 'Adresse',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Postnummer',
      dataIndex: 'zipCode',
      key: 'zipCode',
    },
    {
      title: 'By',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Oprettet',
      dataIndex: 'created',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : ''),
    },
    // {
    //   title: '',
    //   render: (value, row: CitizenEnrollmentResult) => {
    //     return (
    //       <>
    //         <Tooltip title="Mål">
    //           <Button onClick={e => showGoalCitizen(e, row)} icon={<FontAwesomeIcon icon={faBullseye} />} />
    //         </Tooltip>
    //         &nbsp;
    //         <Tooltip title="Helbred og medicin">
    //           <Button onClick={e => showHealthCitizen(e, row)} icon={<MedicineBoxOutlined translate={undefined} />} />
    //         </Tooltip>
    //         &nbsp;
    //       </>
    //     );
    //   },
    // },
  ];
  function handleRowClicked(e) {
    history.push(ROUTES.CENTER_DETAILS.replace(':id?', e.id));
  }
  function handleCreateCitizen() {
    history.push(ROUTES.CITIZEN_ADD);
  }
  function handleDischarge(date: Date, cause: string, citizenEnrollmentId: string) {
    dispatch(dischargeCitizen({ date, cause, citizenEnrollmentId }));

    GetEnrollments();
  }
  return (
    <div className="citizen-page page">
      <Row>
        <Col span={24}>
          <div style={{ background: '#fafafa', padding: '8px' }}>
            <Tooltip title="Opret borger">
              <Button shape="circle" icon={<PlusOutlined translate="" />} onClick={handleCreateCitizen} />
            </Tooltip>
          </div>
          <Table
            style={{ cursor: 'pointer' }}
            loading={isLoading}
            pagination={{ pageSize: 15 }}
            dataSource={centers}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => handleRowClicked(record),
              };
            }}
            columns={columns}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
}
