import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  create,
  MeasurementState,
  setMeasurementPreselectedTypeAction,
  setMeasurementWindowStateAction,
  update,
} from '../../../store/measurementStore';
import { ApplicationState } from '../../../store';
import SelectMenu, { OptGroup, Option } from '../../_shared/_controls/SelectMenu/SelectMenu';
import BmiForm from './MeasurementWindowForms/BmiForm';
import { citizenEnrollmentResultDefault, measurementDefault } from '../../../services/defaults';
import BloodSugarForm from './MeasurementWindowForms/BloodSugarForm';
import BloodPressureForm from './MeasurementWindowForms/BloodPressureForm';
import TemperatureForm from './MeasurementWindowForms/TemperatureForm';
import TrafficLightsForm from './MeasurementWindowForms/TrafficLightsForm';
import FluidForm from './MeasurementWindowForms/FluidForm';
import BvcForm from './MeasurementWindowForms/BvcForm';
import { CalendarState } from '../../../store/calendarStore';
import { requiredMessage } from '../../../utils/UtilsValidation';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import Button from '../../_shared/_controls/Button/Button';
import { useCitizenEnrollmentRequest } from '../../../hooks/citizen-hooks';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import TimePicker from '../../_shared/_controls/TimePicker/TimePicker';
import { CalendarItem, MeasurementType } from '../../../services/prodocApi';
import CalendarItemDrawer from '../../Calendar/CalendarItemDrawer/CalendarItemDrawer';
import { LoadingAnimation } from '../../_shared/LoadingAnimation/LoadingAnimation';
import { UtilsMeasurements } from '../../../utils/UtilsMeasurements';
import { useMeasurementDataLoading } from '../../../hooks/measurements-hooks';

import './MeasurementWindow.scss';

interface Props {
  editData?: any;
  preselectedId?: string;
  calendarItem?: CalendarItem;
  preselectedEnrollmentId?: string;
  preselectedType?: MeasurementType;
  onSave?(citizenEnrollmentId: string): void;
  onClose?(): void;
}

const defaultType = { error: '', value: MeasurementType.Bloodpressure };
const defaultCitizenEnrollment = { error: '', value: '' };

export default function MeasurementWindow(props: Props) {
  const { preselectedEnrollmentId, preselectedType, preselectedId, calendarItem, editData, onClose, onSave } = props;
  const dispatch = useDispatch();
  const measurementState = useSelector<ApplicationState, MeasurementState>(state => state.measurement);
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { getCitizenEnrollmentById } = useCitizenEnrollmentRequest();
  const { loadItemData, data: loadedItemData, resetData: resetLoadedData } = useMeasurementDataLoading();
  const { isWindowOpen } = measurementState;
  const { citizensEnrollments, departmentId } = calendarState;
  const { saved } = pagesState[PAGES_ITEMS.MEASUREMENT_ITEM];
  const isSaving = pagesState[PAGES_ITEMS.MEASUREMENT_ITEM].loading;
  const [type, setType] = useState(defaultType);
  const [citizenEnrollment, setCitizenEnrollment] = useState(defaultCitizenEnrollment);
  const [measurement, setMeasurement] = useState(measurementDefault());
  const [date, setDate] = useState(new Date());
  const [isEdited, setIsEdited] = useState(false);
  const [citizenEnrollmentObj, setCitizenEnrollmentObject] = useState(citizenEnrollmentResultDefault());
  const { value: typeValue, error: typeError } = type;
  const sugarFormRef = useRef() as RefObject<any>;
  const temperatureFormRef = useRef() as RefObject<any>;
  const citizenEnrollmentsList = useMemo(
    () => citizensEnrollments.filter(item => item.departmentId === departmentId),
    [departmentId, citizensEnrollments.length],
  );
  const defaultStartDate = calendarItem?.fromDate;
  const dataForEdit = editData || loadedItemData;
  const isEditMode = !!dataForEdit;

  const resetWindow = () => {
    setMeasurement(measurementDefault());
    setType(defaultType);
    setIsEdited(false);
    setCitizenEnrollment(defaultCitizenEnrollment);
    resetLoadedData();
  };

  const loadCitizenEnrollment = async (id: string) => {
    if (id) {
      const enrollmentData = await getCitizenEnrollmentById(id);

      setCitizenEnrollmentObject(enrollmentData);
    } else {
      setCitizenEnrollmentObject(citizenEnrollmentResultDefault());
    }
  };

  // Close window if data saved
  useEffect(() => {
    if (saved) {
      resetWindow();
      dispatch(setMeasurementWindowStateAction(false));

      onClose && onClose();
      onSave && onSave(citizenEnrollment.value);
    }
  }, [saved]);

  useEffect(() => {
    loadCitizenEnrollment(citizenEnrollment.value);
  }, [citizenEnrollment.value]);

  // Set default type
  useEffect(() => {
    if (isWindowOpen) {
      handleCitizenEnrollmentChange(preselectedEnrollmentId);
      setCitizenEnrollment({ value: preselectedEnrollmentId, error: '' });
      setType({ value: preselectedType, error: '' });
    }
  }, [preselectedType, preselectedEnrollmentId, isWindowOpen]);

  // Set default date
  useEffect(() => {
    if (defaultStartDate) {
      handleDateTimeChange(defaultStartDate);
    }
  }, [defaultStartDate]);

  // Set default item data
  useEffect(() => {
    if (dataForEdit) {
      setCitizenEnrollment({ value: dataForEdit.citizenEnrollmentId, error: '' });
      setType({ value: dataForEdit.measurementType, error: '' });
      setMeasurement(dataForEdit);
      setDate(new Date(dataForEdit.date));
    }
  }, [dataForEdit]);

  // Load edit item data
  useEffect(() => {
    if (preselectedId && isWindowOpen) {
      loadItemData(preselectedId);
    }
  }, [preselectedId, isWindowOpen]);

  const closeWindow = () => {
    dispatch(setMeasurementWindowStateAction(false));
    dispatch(setMeasurementPreselectedTypeAction(null));
    onClose && onClose();

    setTimeout(() => {
      resetWindow();
    }, 300);
  };

  const handleClose = () => {
    if (!isEdited) {
      closeWindow();
      return;
    }

    dispatch(
      showConfirmModal({
        content: `Ønsker du at annullere? Ikke gemte oplysninger vil blive slettet`,
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          closeWindow();
        },
      }),
    );
  };

  const handleFormChange = (value: any) => {
    setMeasurement(value);
    setIsEdited(true);
  };

  const handleCreate = async () => {
    if (!citizenEnrollment.value) {
      setCitizenEnrollment({ value: '', error: requiredMessage });
      return;
    }

    if (!typeValue) {
      setType({ value: MeasurementType.Bloodpressure, error: requiredMessage });
      return;
    }

    switch (typeValue) {
      case MeasurementType.Bloodsugar:
        const isBloodSugarValid = await sugarFormRef.current.validateForm();

        if (!isBloodSugarValid) return;
        break;
      case MeasurementType.Bloodpressure:
        break;
      case MeasurementType.Bmi:
        break;
      case MeasurementType.Temperature:
        const isTemperatureValid = await temperatureFormRef.current.validateForm();

        if (!isTemperatureValid) return;
        break;
      case MeasurementType.Trafficlight:
      case MeasurementType.Liquid:
      case MeasurementType.Bvc:
        break;
      default:
        return null;
    }

    dispatch(create({ ...measurement, measurementType: typeValue, citizenEnrollmentId: citizenEnrollment.value }));
  };

  const handleUpdate = async () => {
    switch (typeValue) {
      case MeasurementType.Bloodsugar:
        const isBloodSugarValid = await sugarFormRef.current.validateForm();
        if (!isBloodSugarValid) return;
        break;
      case MeasurementType.Bloodpressure:
        break;
      case MeasurementType.Bmi:
        break;
      case MeasurementType.Temperature:
        const isTemperatureValid = await temperatureFormRef.current.validateForm();
        if (!isTemperatureValid) return;
        break;
      case MeasurementType.Trafficlight:
      case MeasurementType.Liquid:
      case MeasurementType.Bvc:
        break;
      default:
        return null;
    }

    dispatch(update({ ...measurement, citizenEnrollmentId: citizenEnrollment.value }));
  };

  const handleSubmit = () => {
    if (isEditMode) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  const handleTypeChange = (value: any) => {
    setType({ value, error: '' });
    setIsEdited(true);
  };

  const handleCitizenEnrollmentChange = (value: any) => {
    setCitizenEnrollment({ value, error: '' });

    const enrollmentData = citizensEnrollments.find(item => item.id === value);
    const newData = { ...measurement };

    Object.keys(newData).forEach(key => {
      newData.citizenEnrollmentId = value;
      newData.citizenId = enrollmentData?.citizenId;
    });

    setMeasurement(newData);
  };

  const handleDateTimeChange = (value: Date) => {
    const updatedData = { ...measurement };

    Object.keys(updatedData).forEach(key => {
      updatedData.date = value;
      updatedData.fromDate = value;
      updatedData.toDate = moment(value).add(30, 'minutes').toDate();
    });

    setMeasurement(updatedData);
    setDate(value);
  };

  const renderForm = () => {
    switch (typeValue) {
      case MeasurementType.Bloodsugar:
        return (
          <BloodSugarForm
            ref={sugarFormRef}
            measurement={measurement}
            formName="bloodSugar"
            onChange={handleFormChange}
          />
        );
      case MeasurementType.Bloodpressure:
        return <BloodPressureForm measurement={measurement} formName="bloodPressure" onChange={handleFormChange} />;
      case MeasurementType.Bmi:
        return <BmiForm measurement={measurement} formName="bmi" onChange={handleFormChange} />;
      case MeasurementType.Temperature:
        return (
          <TemperatureForm
            ref={temperatureFormRef}
            measurement={measurement}
            formName="temperature"
            onChange={handleFormChange}
          />
        );
      case MeasurementType.Trafficlight:
        return (
          <TrafficLightsForm
            measurement={measurement}
            enrollmentData={citizenEnrollmentObj}
            formName="trafficLight"
            onChange={handleFormChange}
          />
        );
      case MeasurementType.Liquid:
        return <FluidForm measurement={measurement} formName="fluid" onChange={handleFormChange} />;
      case MeasurementType.Bvc:
        return <BvcForm measurement={measurement} formName="bvc" onChange={handleFormChange} />;
      default:
        return null;
    }
  };

  return (
    <CalendarItemDrawer
      title="Måling"
      isOpen={isWindowOpen}
      onClose={handleClose}
      buttonsRight={
        <>
          <LoadingAnimation active={isSaving} />
          <Button title="Gem" disabled={isSaving} onClick={handleSubmit} />
          <Button title="Annuller" onClick={handleClose} />
        </>
      }>
      <div className="mes-window">
        <div className="mes-window__type common-form">
          <SelectMenu
            title="Vælg borger"
            value={citizenEnrollment.value}
            error={citizenEnrollment.error}
            options={citizenEnrollmentsList}
            valueKey="id"
            disabled={isEditMode}
            labelKey={['firstname', 'lastname']}
            onChange={handleCitizenEnrollmentChange}
          />
          <SelectMenu
            title="Måling"
            className="mes-window__type-selector"
            value={typeValue}
            error={typeError}
            disabled={isEditMode}
            onChange={handleTypeChange}>
            <OptGroup label="Sundhedsmålinger">
              <Option value={MeasurementType.Bloodsugar}>Blodsukker</Option>
              <Option value={MeasurementType.Bloodpressure}>Blodtryk</Option>
              <Option value={MeasurementType.Bmi}>BMI</Option>
              <Option value={MeasurementType.Temperature}>Temperatur</Option>
              <Option value={MeasurementType.Liquid}>Væske</Option>
            </OptGroup>
            <OptGroup label="Risikovurderinger">
              <Option value={MeasurementType.Bvc}>BVC</Option>
              <Option value={MeasurementType.Trafficlight}>Trafiklys</Option>
            </OptGroup>
          </SelectMenu>
          <DatePicker title="Dato" value={date} onChange={handleDateTimeChange} />
          <TimePicker title="Tidspunkt" value={date} onChange={handleDateTimeChange} />
        </div>
        {renderForm()}
      </div>
    </CalendarItemDrawer>
  );
}
