import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Goal, GoalMethod, GoalMethodTheme, GoalResult, GoalStatus, GoalType } from '../../../services/prodocApi';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { Drawer } from '../../_shared/Drawer/Drawer';
import { goalSchema } from '../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import { ApplicationState } from '../../../store';
import { CalendarState } from '../../../store/calendarStore';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import Button from '../../_shared/_controls/Button/Button';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import TextEditor from '../../_shared/_controls/TextEditor/TextEditor';
import { UtilsGoals } from '../../../utils/UtilsGoals';
import { showErrorMessage } from '../../../store/notificationStore';
import { UtilsData } from '../../../utils/UtilsData';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { LoadingAnimation } from '../../_shared/LoadingAnimation/LoadingAnimation';

import './GoalWindow.scss';

interface Props {
  data: GoalResult;
  isVisible: boolean;
  citizenName: string;
  onClose(): void;
  onCreate(data: Goal): void;
  onUpdate(data: Goal): void;
}

export default function GoalWindow(props: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const { isVisible, data, citizenName, onClose, onCreate, onUpdate } = props;
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const [formData, setFormData] = useState<GoalResult>(data);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [evaluatingError, setEvaluatingError] = useState<string>('');
  const { register, handleSubmit, errors, clearError, setValue, unregister } = useForm({
    validationSchema: goalSchema,
    reValidateMode: 'onSubmit',
  });
  const {
    title,
    description,
    goalStatus,
    fromDate,
    toDate,
    evaluationDate,
    id,
    parentGoalId,
    evaluationText,
    goalMethod,
    goalType,
    goalMethodTheme,
  } = formData;
  const { employeesEnrollments = [] } = calendarStore;
  const isEditMode = !!id;
  const isSubGoal = !!parentGoalId;
  const windowTitle = isSubGoal ? 'Opret delmål' : 'Opret mål';
  const evaluatingErrorMessage = 'Udfyld endelig evaluering';
  const isClosing = goalStatus === GoalStatus.Achieved || goalStatus === GoalStatus.NotAchieved;
  const isLoading = pagesStore[PAGES_ITEMS.GOAL].loading;
  const isSaved = pagesStore[PAGES_ITEMS.GOAL].saved;
  const filteredEnrollments = useMemo(() => {
    return UtilsData.removeDuplicatesFromArray(employeesEnrollments, 'employeeId');
  }, [employeesEnrollments.length]);
  const showActions = (isEditMode && isUpdated) || !isEditMode;
  const goalMethodThemeOptions = useMemo(() => {
    if (!goalMethod) return [];

    return translateEnumOptions(GoalMethodTheme).filter(({ value }) => {
      if (goalMethod === GoalMethod.VUM2) return value.indexOf('VUM2') >= 0;
      if (goalMethod === GoalMethod.FSIII) return value.indexOf('FSIII') >= 0;
      if (goalMethod === GoalMethod.ICS) return value.indexOf('ICS') >= 0;

      return false;
    });
  }, [goalMethod]);

  useRegisteredFields(goalSchema, register, unregister);

  useEffect(() => {
    setFormData(data);

    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
      clearError(key);
    });
  }, [data]);

  useEffect(() => {
    if (isSaved) closeWindow();
  }, [isSaved]);

  const checkGoalCloseStatus = (): boolean => {
    if (!isClosing) {
      return true;
    }

    const canBeClosed = UtilsGoals.isGoalCanBeClosed(formData);

    if (!canBeClosed) {
      dispatch(showErrorMessage({ message: 'Der er ikke-afsluttede delmål på dette mål, afslut først delmål' }));
      return false;
    }

    if (!evaluationText) {
      setEvaluatingError(evaluatingErrorMessage);
      return false;
    }

    return true;
  };

  const handleFieldChange = (value: string | Date, fieldName: string) => {
    setFormData({ ...formData, [fieldName]: value });
    setValue(fieldName, value);
    clearError(fieldName);
    setIsUpdated(true);

    if (fieldName === 'evaluationText') {
      setEvaluatingError('');
    }
  };

  const closeWindow = () => {
    setFormData(data);
    setIsUpdated(false);

    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
      clearError(key);
    });

    onClose();
  };

  const handleClose = () => {
    if (!isUpdated) {
      closeWindow();
      return;
    }

    dispatch(
      showConfirmModal({
        content: `Ønsker du at annullere? Ikke gemte oplysninger vil blive slettet`,
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          closeWindow();
        },
      })
    );
  };

  const handleFormSubmit = () => {
    if (!checkGoalCloseStatus()) return;

    if (id) {
      onUpdate(formData);
    } else {
      onCreate(formData);
    }
  };

  return (
    <Drawer
      title={windowTitle}
      subtitle={citizenName}
      visible={isVisible}
      onCancel={handleClose}
      onClose={handleClose}
      onOk={handleSubmit(handleFormSubmit)}
      buttons={
        <>
          {showActions && (
            <div className="goal-window__actions">
              <LoadingAnimation active={isLoading} />
              <Button title="Gem" type="primary" disabled={isLoading} onClick={handleSubmit(handleFormSubmit)} />
              <Button title="Annuller" onClick={handleClose} />
            </div>
          )}
        </>
      }>
      <form className="goal-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <TextInput
          ref={register}
          value={title}
          title="Navn"
          name="title"
          error={errors.title && errors.title.message}
          onChange={handleFieldChange}
        />
        <TextEditor value={description || ''} title="Beskrivelse" name="description" onChange={handleFieldChange} />
        {isClosing && (
          <TextEditor
            value={evaluationText}
            title="Endelig evaluering"
            name="evaluationText"
            error={evaluatingError}
            onChange={handleFieldChange}
          />
        )}
        <SelectMenu
          title="Status"
          value={goalStatus}
          name="goalStatus"
          placeholder="Status"
          valueKey="value"
          labelKey="label"
          error={errors.goalStatus && errors.goalStatus.message}
          options={translateEnumOptions(GoalStatus)}
          onChange={handleFieldChange}
        />
        <SelectMenu
          allowClear
          title="Metode"
          value={goalMethod}
          name="goalMethod"
          valueKey="value"
          labelKey="label"
          options={translateEnumOptions(GoalMethod)}
          onChange={handleFieldChange}
        />
        <SelectMenu
          title="Tema"
          disabled={!goalMethod}
          value={goalMethodTheme}
          name="goalMethodTheme"
          valueKey="value"
          labelKey="label"
          options={goalMethodThemeOptions}
          onChange={handleFieldChange}
        />
        <SelectMenu
          title="Type"
          value={goalType}
          name="goalType"
          valueKey="value"
          labelKey="label"
          options={translateEnumOptions(GoalType)}
          onChange={handleFieldChange}
        />
        <SelectMenu
          title="Ansvarlig"
          value={undefined}
          name="employeeIds"
          placeholder="Ansvarlig"
          valueKey="id"
          multiple
          labelKey={['firstname', 'lastname']}
          options={filteredEnrollments}
          onChange={handleFieldChange}
        />
        <DatePicker
          title="Start dato"
          value={fromDate}
          name="fromDate"
          error={errors.startDate && errors.startDate.message}
          onChange={handleFieldChange}
        />
        <DatePicker
          title="Evalueringsdato"
          value={evaluationDate}
          name="evaluationDate"
          error={errors.evaluationDate && errors.evaluationDate.message}
          onChange={handleFieldChange}
        />
        <DatePicker title="Slut dato" value={toDate} name="toDate" onChange={handleFieldChange} />
      </form>
    </Drawer>
  );
}
