import React, { useCallback } from 'react';

import { CommonOption } from '../../../types/common';
import Button from '../../_shared/_controls/Button/Button';
import { ICONS } from '../../_shared/Icon/Icon';
import { Tooltip } from '../../_shared/Tooltip/Tooltip';
import { UtilsData } from '../../../utils/UtilsData';

import './HealthAreaListItem.scss';

interface Props {
  title: string;
  info: string;
  tooltipText: string;
  value: CommonOption;
  onEdit(value: CommonOption): void;
  onNoteCreate(): void;
}

export default function HealthAreaListItem(props: Props) {
  const { title, tooltipText, value, info, onEdit, onNoteCreate } = props;

  const handleEdit = useCallback(() => {
    onEdit(value);
  }, [onEdit, value]);

  const tooltipContent = !UtilsData.isDefaultHtmlString(tooltipText) ? (
    <span dangerouslySetInnerHTML={{ __html: tooltipText }} />
  ) : (
    ''
  );

  return (
    <div className="hal-item">
      <div className="hal-item__head">
        <Tooltip text={tooltipContent}>
          <span className="hal-item__title">{title}</span>
        </Tooltip>
        <Button size="small" icon={ICONS.edit} onClick={handleEdit} />
        <Button size="small" icon={ICONS.read} onClick={onNoteCreate} />
      </div>
      <div className="hal-item__info">{info}</div>
    </div>
  );
}
