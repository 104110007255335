import React from 'react';
import { Modal } from 'antd';
import Button from '../../../_shared/_controls/Button/Button';

import './HealthAreaStatusConfirmWindow.scss';

interface Props {
  isVisible: boolean;
  onConfirm(moveAll: boolean, contentOnly?: boolean): void;
  onCLose(): void;
}

export const HealthAreaStatusConfirmWindow: React.FC<Props> = props => {
  const { isVisible, onCLose, onConfirm } = props;

  const handleAllConfirm = () => {
    onConfirm(true);
  };

  const handleCurrentConfirm = () => {
    onConfirm(false);
  };

  const handleWithoutStatusConfirm = () => {
    onConfirm(false, true);
  };

  return (
    <Modal
      visible={isVisible}
      width={800}
      className="hasc-window"
      title="Ønsker du at ændre status på tilstande?"
      onCancel={onCLose}
      footer={
        <div className="hasc-window__footer">
          <div className="hasc-window__row">
            <Button title="Skift status på alle tilstande" type="primary" onClick={handleAllConfirm} />
            <p>Skifter alle tilstande inden for området, til den valgte status.</p>
          </div>
          <div className="hasc-window__row">
            <Button title="Skift status på tilstande med samme status" type="primary" onClick={handleCurrentConfirm} />
            <p>
              Skifter kun status på alle de tilstande som
              p.t. har samme status, som den valgte
              tilstand. Kan f.eks skifte alle potentielle
              problemer til aktuelle problemer.
            </p>
          </div>
          <div className="hasc-window__row">
            <Button
              title="Gem uden at skifte status på tilstande"
              type="primary"
              onClick={handleWithoutStatusConfirm}
            />
            <p>Bruges til at gemme ændringer i beskrivelse mm. uden at skifte status på tilstande.</p>
          </div>
          <Button title="Annuller" onClick={onCLose} />
        </div>
      } />
  );
};
