import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import CitizenEditForm from '../../../components/citizen/CitizenEditForm/CitizenEditForm';
import {
  CitizensState,
  enrollCitizen,
  getCitizenById,
  loadAvailableSearchCitizens,
  setCitizenEdit,
  setCitizenInitialDepartmentId,
  updateCitizen,
} from '../../../store/citizenStore';
import { UtilsData } from '../../../utils/UtilsData';
import prodocApi, { Citizen, CitizenEnrollment, Employee, EnrollFromType } from '../../../services/prodocApi';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { navigateTo, navigateToHome } from '../../../store/navigationStore';
import { ROUTES } from '../../../constants/routes';
import { loadUserDepartments } from '../../../store/userStore';
import { ApplicationState } from '../../../store';
import { DepartmentState } from '../../../store/departmentStore';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { Button, FloatButton, Modal, Tooltip } from 'antd';
import { LoginOutlined, LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { URLS } from '../../../constants/urls';
import { useHistory, useParams } from 'react-router';
import CitizenEnrollForm from '../../../components/citizen/CitizenEnrollForm/CitizenEnrollForm';
import CitizenDischargeForm from '../../../components/citizen/CitizenDischargeForm/CitizenDischargeForm';

export default function CitizenEditPage() {
  const dispatch = useDispatch();
  const { match } = useReactRouter<{ id: string }>();
  const { citizenItems } = useCitizenNavigationItems();
  const citizenState = useSelector<any, CitizensState>(state => state.citizens);
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  // const citizenEnrollmentId = match.params.id;
  const userId = userState.id;
  const { editCitizen } = citizenState;
  const { userDepartments } = departmentState;
  const citizenData = editCitizen.data || UtilsData.getCitizenDefaults();
  const history = useHistory();
  const { id: citizenEnrollmentId } = useParams<{ id: string }>();
  const [citizenEnrollment, setCitizenEnrollment] = useState<CitizenEnrollment>();
  const [citizen, setCitizen] = React.useState<Citizen>();

  const [isEnrollOpen, setIsEnrollOpen] = useState(false);

  const [enrollmentData, setEnrollmentData] = useState({
    enrolledFrom: undefined,
    enrollmentDate: new Date(),
    enrolledDepartment: undefined,
  });

  usePageTitle('Rediger borger');

  React.useEffect(() => {
    async function get() {
      const enrollment = await prodocApi.citizenEnrollment.get(citizenEnrollmentId);
      const citizen = await prodocApi.citizen.getCitizenByEnrollmentId(enrollment.id);
      setCitizenEnrollment(enrollment);
      setCitizen(citizen);
    }

    get();
  }, [citizenEnrollmentId]);

  const handleFormSubmit = (data: Citizen) => {
    dispatch(updateCitizen(data));
  };

  const handleCancel = () => {
    history.push(ROUTES.CITIZEN_OVERVIEW);
  };
  const { enrolledFrom, enrollmentDate, enrolledDepartment } = enrollmentData;
  function handleEnroll() {
    dispatch(
      enrollCitizen(
        {
          citizenId: citizen.id,
          enrollmentDate: enrollmentDate,
          enrolledFrom: enrolledFrom as EnrollFromType,
        },
        [enrolledDepartment]
      )
    );
    setIsEnrollOpen(false);
  }
  return (
    <>
      <div className="citizen-page page" style={{ display: 'flex' }}>
        <CitizenEditForm citizenState={citizen} onSubmit={handleFormSubmit} onCancel={handleCancel} />
        <Tooltip title="Indskriv borger">
          <Button
            style={{ marginLeft: '8px' }}
            icon={<LoginOutlined translate={undefined} />}
            onClick={() => setIsEnrollOpen(true)}
            size="large"
          />
        </Tooltip>
      </div>
      <Modal
        width={800}
        title="Indskriv borger"
        okText="Indskriv"
        cancelText="Annuller"
        open={isEnrollOpen}
        onOk={handleEnroll}
        onCancel={() => setIsEnrollOpen(false)}>
        <CitizenEnrollForm
          onDateChange={e => setEnrollmentData({ ...enrollmentData, enrollmentDate: e })}
          onDepartmentChange={e => setEnrollmentData({ ...enrollmentData, enrolledDepartment: e })}
          onEnrolledFromChange={e => setEnrollmentData({ ...enrollmentData, enrolledFrom: e })}
          enrolledFrom={enrolledFrom}
          enrollmentDate={enrollmentDate}
          selectedDepartment={enrolledDepartment}
        />
      </Modal>
    </>
  );
}
