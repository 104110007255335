import { showConfirmModal } from '../components/_shared/Modal/ModalFunctions';
import {
  navigateToCenterDepartmentPage,
  navigateToCitizenEnroll,
  navigateToEmployeeEnroll,
  navigateToHome,
} from './navigationStore';
import { CitizenDischarge } from '../types/citizen';
import { dischargeCitizen } from './citizenStore';
import { EmployeeDischarge } from '../types/employee';
import { dischargeEmployee, setLeaderCreateData } from './employeeStore';

export const showCitizenEnrollModal = (citizenId: string) => {
  return (dispatch) => {
    const options = {
      content: 'Ønsker du at indskrive borgeren på en afdeling?',
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: () => {
        dispatch(navigateToCitizenEnroll(citizenId))
      },
      onCancel: () => {
        dispatch(navigateToHome());
      },
    };

    dispatch(showConfirmModal(options));
  }
};

export const showCitizenDischargeModal = (data: CitizenDischarge) => {
  return (dispatch) => {
    const options = {
      content: 'Ønsker du at udskrive borgeren?',
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: () => {
        dispatch(dischargeCitizen(data))
      },
    };

    dispatch(showConfirmModal(options));
  }
};

export const showEmployeeDischargeModal = (data: EmployeeDischarge) => {
  return (dispatch) => {
    const options = {
      content: 'Ønsker du at afslutte medarbejderen?',
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: () => {
        dispatch(dischargeEmployee(data))
      },
    };

    dispatch(showConfirmModal(options));
  }
};

export const showEmployeeEnrollModal = (citizenId: string) => {
  return (dispatch) => {
    const options = {
      content: 'Ønsker du at tilknytte medarbejderen til en afdeling?',
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: () => {
        dispatch(navigateToEmployeeEnroll(citizenId))
      },
      onCancel: () => {
        dispatch(navigateToHome());
      },
    };

    dispatch(showConfirmModal(options));
  }
};

export const showCenterDepartmentCreateModal = (centerId: string) => {
  return (dispatch) => {
    const options = {
      content: 'Ønsker du at fortsætte til Opret afdeling?',
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: () => {
        dispatch(setLeaderCreateData(null));
        dispatch(navigateToCenterDepartmentPage(centerId))
      },
    };

    dispatch(showConfirmModal(options));
  };
};
