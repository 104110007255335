import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addCenter } from '../../../store/centerStore';
import { centerDefault } from '../../../services/defaults';
import { Center } from '../../../services/prodocApi';
import { navigateToHome } from '../../../store/navigationStore';
import CenterForm from '../../../components/centerPage/CenterForm/CenterForm';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import Button from '../../../components/_shared/_controls/Button/Button';

export default function CenterCreatePage() {
  const dispatch = useDispatch();
  const centerData = useMemo(() => centerDefault(), []);

  usePageTitle('Opret center');

  const handleFormSubmit = (data: Center) => {
    dispatch(addCenter(data));
  };

  const handleCancel = () => {
    dispatch(navigateToHome());
  };

  return (
    <>
      {/* <Sidebar>
        <Button title="Opret center" className="fullwidth" />
      </Sidebar> */}
      <div className="center-page page">
        <CenterForm data={centerData} editMode={false} onSubmit={handleFormSubmit} onCancel={handleCancel} />
      </div>
    </>
  );
}
