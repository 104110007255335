import { Modal, Button, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import SelectMenu from '../_shared/_controls/SelectMenu/SelectMenu';
import { Textbox } from '../_shared/_controls/Textbox/Textbox';
import moment from 'moment';
import TextInput from '../_shared/_controls/TextInput/TextInput';
import DatePicker from '../_shared/_controls/DatePicker/DatePicker';

interface TransferDialogProps {
  isVisible: boolean;
  onCancel: () => void;
  onOk: () => void;
}
export function TransferDialog(props: TransferDialogProps) {
  const { isVisible, onCancel, onOk } = props;
  const [fromWeek, setFromWeek] = useState('');
  const [toWeek, settoWeek] = useState('');
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  function handleSetFromDate(value: moment.Moment) {
    setFromDate(value);
  }
  return (
    <Modal
      width="415px"
      title={<b>Overfør til skabelon</b>}
      visible={isVisible}
      footer={[
        <Button key="submit" type="primary" onClick={onOk}>
          Ja
        </Button>,
        <Button key="back" onClick={onCancel}>
          Nej
        </Button>,
      ]}
      closable
      onCancel={onCancel}>
      <Row gutter={8} align="stretch">
        <Col span={12}>
          <label>
            Kladde fra uge
            <TextInput value={fromWeek} onChange={setFromWeek} />
          </label>
        </Col>
        <Col span={12}>
          <label>
            Kladde til uge
            <TextInput value={toWeek} onChange={settoWeek} />
          </label>
        </Col>
      </Row>
      <Row gutter={8} align="stretch">
        <Col span={24}>
          <label>
            Start dato
            <DatePicker format="DD-MM-YYYY" value={fromDate} onChange={handleSetFromDate} allowClear={false} />
          </label>
        </Col>
      </Row>
    </Modal>
  );
}
