import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Citizen, TypeOfConviction } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import Button from '../../_shared/_controls/Button/Button';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { citizenCreateFullSchema } from '../../../utils/UtilsValidation';
import { useCprValidation, useRegisteredFields } from '../../../hooks/validation-hooks';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { useExistingUserCheck } from '../../../hooks/citizen-hooks';
import Autocomplete, { AcOption } from '../../_shared/Autocomplete/Autocomplete';
import { DawaMunicipality } from '../../../types/citizen';
import { useCitizenEnrollAutocomplete } from '../../../hooks/data-hooks';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import { UtilsItems } from '../../../utils/UtilsItems';
import { useZipCodeField } from '../../../hooks/common-hooks';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { CardActions } from '../../_shared/CardActions/CardActions';

import './CitizenCreateForm.scss';
import CitizenEnrollForm from '../CitizenEnrollForm/CitizenEnrollForm';

interface Props {
  data: Citizen;
  onSubmit(
    citizenData: Citizen,
    enrollmentData: {
      enrolledFrom: string;
      enrollmentDate: Date;
      enrolledDepartment: string;
    }
  ): void;
  onCancel(): void;
  onEnroll(id: string): void;
}

export default function CitizenCreateForm(props: Props) {
  const { data, onSubmit, onCancel, onEnroll } = props;
  const dispatch = useDispatch();
  const { isCprUsed, isUidUsed } = useExistingUserCheck();
  const { getZipCodeData } = useZipCodeField();
  const pagesItemsStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const [acOptions, searchAcOptions] = useCitizenEnrollAutocomplete();
  const { isCprValid, isCprFilled } = useCprValidation();
  const isDataSaved = pagesItemsStore[PAGES_ITEMS.CITIZEN_FORM].saved;
  const domstypeOptions = React.useMemo(() => {
    return Object.keys(TypeOfConviction).map(item => {
      let itemName = item.replace(/\d/, ' $&');

      if (itemName === 'None') itemName = 'Ingen';

      return {
        value: item,
        label: itemName,
      };
    });
  }, []);
  const [acData, setAcData] = React.useState({ actMunicipallity: '', payMunicipallity: '' });

  const [isDisabled, setIsDisabled] = useState(true);
  const [enrollmentData, setEnrollmentData] = useState({
    enrolledFrom: undefined,
    enrollmentDate: undefined,
    enrolledDepartment: undefined,
  });
  const [citizen, setCitizen] = React.useState<Citizen>(data);
  const { register, unregister, errors, clearError, setError, handleSubmit, setValue } = useForm({
    validationSchema: citizenCreateFullSchema,
    defaultValues: data as any,
  });
  useRegisteredFields(citizenCreateFullSchema, register, unregister);

  const {
    firstname,
    lastname,
    address,
    zipCode,
    city,
    alternativeAddress,
    phone,
    cpr,
    foreignId,
    cave,
    typeOfConviction,
    actSocialworker,
    paySocialworker,
    convictionStartDate,
    convictionEndtDate,
    diagnoses,
    vaccines,
  } = citizen;
  const { enrolledFrom, enrollmentDate, enrolledDepartment } = enrollmentData;
  const { actMunicipallity, payMunicipallity } = acData;

  useEffect(() => {
    setCitizen(data);
    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });
  }, [data.id]);

  useEffect(() => {
    if (isDataSaved) {
      resetFormFields();
    }
  }, [isDataSaved]);

  function resetFormFields() {
    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });

    setCitizen(data);
  }

  function handleFieldChange(value: any, name: string) {
    const zipData = getZipCodeData(value, name, { ...citizen, [name]: value });

    setCitizen(zipData);
    setValue(name, value);
    clearError(name);
  }

  const handleCprFieldChange = (value: string) => {
    let handledValue = value;

    if (!isCprValid(value)) return;

    if (isCprFilled(value)) {
      handleExistingUserCheck(value, 'cpr');
    }

    handledValue = UtilsItems.formatCprNumber(value);

    setCitizen({ ...citizen, cpr: handledValue } as Citizen);
    setValue('cpr', handledValue);
    clearError('cpr');
  };

  const handleFormSubmit = async () => {
    onSubmit(citizen, enrollmentData);
  };

  const handleExistingUserCheck = async (value: string, name: string) => {
    if (!value) return;

    const isCpr = name === 'cpr';

    const userId = isCpr ? await isCprUsed(value) : await isUidUsed(value);

    if (!userId) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);

      dispatch(
        showConfirmModal({
          title: 'Borgeren eksisterer allerede. Ønsker du at indskrive borgeren på en afdeling?',
          okText: 'Ja',
          cancelText: 'Nej',
          onOk: () => {
            onEnroll(userId);
          },
        })
      );
    }
  };

  const handleAutocompleteSelect = (value: string, name: string) => {
    setCitizen({ ...citizen, [name]: value } as Citizen);
  };

  const handleAutocompleteChange = (value: string, name: string) => {
    setAcData({ ...acData, [name]: value });
  };

  const renderAcOptions = (data: DawaMunicipality[]) => {
    if (!data) return null;

    return data.map((option: DawaMunicipality) => {
      return (
        <AcOption key={option.dagi_id} value={option.navn}>
          {option.navn}
        </AcOption>
      );
    });
  };

  return (
    <form className="citizen-form item-form" style={{ display: 'block' }}>
      <Card>
        <div>
          <Title level={3}>Person oplysninger</Title>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput
                title="Cpr"
                name="cpr"
                value={cpr}
                error={errors.cpr && errors.cpr.message}
                onChange={handleCprFieldChange}
              />
            </Col>
            <Col span={12}>
              <TextInput
                title="Udlændinge-ID"
                name="foreignId"
                value={foreignId}
                error={errors.foreignId && errors.foreignId.message}
                onChange={handleFieldChange}
                onBlur={handleExistingUserCheck}
              />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <TextInput
                title="Fornavn*"
                name="firstname"
                value={firstname}
                error={errors.firstname && errors.firstname.message}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <TextInput
                title="Efternavn*"
                name="lastname"
                value={lastname}
                error={errors.lastname && errors.lastname.message}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <TextInput
                title="Adresse"
                name="address"
                value={address}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={4}>
              <TextInput
                title="Postnummer"
                name="zipCode"
                value={zipCode}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={8}>
              <TextInput title="By" name="city" value={city} onChange={handleFieldChange} disabled={isDisabled} />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput
                title="Alternativ adresse"
                name="alternativeAddress"
                value={alternativeAddress}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <TextInput
                title="Telefonnummer"
                name="phone"
                value={phone}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <TextInput
                title="CAVE"
                name="cave"
                value={cave}
                caution
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={8}>
              <TextInput
                title="Diagnoser"
                name="diagnoses"
                value={diagnoses}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={8}>
              <TextInput
                title="Vaccinationer"
                name="vaccines"
                value={vaccines}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <SelectMenu
                title="Domstype"
                name="typeOfConviction"
                value={typeOfConviction}
                valueKey="value"
                labelKey="label"
                options={domstypeOptions}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={8}>
              <DatePicker
                title="Dom startdato"
                name="convictionStartDate"
                value={convictionStartDate}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={8}>
              <DatePicker
                title="Dom slutdato"
                name="convictionEndtDate"
                value={convictionEndtDate}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Autocomplete
                data={acOptions.municipality}
                title="Handlekommune"
                value={actMunicipallity}
                name="actMunicipallity"
                placeholder="Skriv for at søge"
                minSearchValue={1}
                onSelect={handleAutocompleteSelect}
                onSearch={searchAcOptions}
                onChange={handleAutocompleteChange}
                disabled={isDisabled}>
                {renderAcOptions(acOptions.actMunicipallity)}
              </Autocomplete>
            </Col>
            <Col span={12}>
              <TextInput
                title="Ansvarlig socialrådgiver handlekommune"
                name="actSocialworker"
                value={actSocialworker}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <Autocomplete
                data={acOptions.payMunicipallity}
                title="Betalingskommune"
                placeholder="Skriv for at søge"
                value={payMunicipallity}
                name="payMunicipallity"
                minSearchValue={1}
                onSelect={handleAutocompleteSelect}
                onSearch={searchAcOptions}
                onChange={handleAutocompleteChange}
                disabled={isDisabled}>
                {renderAcOptions(acOptions.payMunicipallity)}
              </Autocomplete>
            </Col>
            <Col span={12}>
              <TextInput
                title="Ansvarlig socialrådgiver betalingskommune"
                name="paySocialworker"
                value={paySocialworker}
                onChange={handleFieldChange}
                disabled={isDisabled}
              />
            </Col>
          </Row>
          <Title level={3}>Indskrivnings oplysninger</Title>
          <CitizenEnrollForm
            onDateChange={e => setEnrollmentData({ ...enrollmentData, enrollmentDate: e })}
            onDepartmentChange={e => setEnrollmentData({ ...enrollmentData, enrolledDepartment: e })}
            onEnrolledFromChange={e => setEnrollmentData({ ...enrollmentData, enrolledFrom: e })}
            enrolledFrom={enrolledFrom}
            enrollmentDate={enrollmentDate}
            selectedDepartment={enrolledDepartment}
          />
        </div>
        <CardActions
          rightActions={
            <>
              <Button title="Fortsæt" disabled={isDisabled} onClick={handleSubmit(handleFormSubmit)} />
              <Button title="Annuller" onClick={onCancel} />
            </>
          }
        />
      </Card>
    </form>
  );
}
