import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import {
  addCitizen,
  CitizensState,
  enrollCitizen,
  getCitizenById,
  setCitizenEdit,
  updateCitizen,
} from '../../../store/citizenStore';
import { Citizen, EnrollFromType } from '../../../services/prodocApi';
import { navigateTo, navigateToHome, navigateToCitizenEnroll } from '../../../store/navigationStore';
import { UtilsData } from '../../../utils/UtilsData';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { ROUTES } from '../../../constants/routes';
import CitizenCreateForm from '../../../components/citizen/CitizenCreateForm/CitizenCreateForm';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import Button from '../../../components/_shared/_controls/Button/Button';

import './CitizenCreatePage.scss';
import { useHistory } from 'react-router';

export default function CitizenCreatePage() {
  const dispatch = useDispatch();
  const { match } = useReactRouter<{ id: string }>();
  const citizenState = useSelector<any, CitizensState>(state => state.citizens);
  const citizenId = match.params.id;
  const { editCitizen } = citizenState;
  const citizenData = editCitizen.data || UtilsData.getCitizenDefaults();
  const history = useHistory();

  usePageTitle('Opret borger');

  React.useEffect(() => {
    if (citizenId) {
      dispatch(getCitizenById(citizenId));
    }

    return () => {
      dispatch(setCitizenEdit({}));
    };
  }, [citizenId]);

  const handleFormSubmit = (
    data: Citizen,
    enrollment: {
      enrolledFrom: string;
      enrollmentDate: Date;
      enrolledDepartment: string;
    }
  ) => {
    if (citizenId) {
      dispatch(updateCitizen(data));
    } else {
      dispatch(addCitizen(data));
    }
    dispatch(
      enrollCitizen(
        {
          citizenId: data.id,
          enrollmentDate: enrollment.enrollmentDate,
          enrolledFrom: enrollment.enrolledFrom as EnrollFromType,
        },
        [enrollment.enrolledDepartment]
      )
    );
  };

  const handleCancel = () => {
    history.push(ROUTES.CITIZEN_OVERVIEW);
  };

  const handleEnroll = (id: string) => {
    dispatch(navigateToCitizenEnroll(id));
  };

  return (
    <>
      <div className="citizen-page page">
        <CitizenCreateForm
          data={citizenData}
          onSubmit={handleFormSubmit}
          onCancel={handleCancel}
          onEnroll={handleEnroll}
        />
      </div>
    </>
  );
}
