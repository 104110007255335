import { UtilsNavigation } from '../utils/UtilsNavigation';
import { URLS } from './urls';

const { buildRoute } = UtilsNavigation;

export const ROUTES = {
  LOGIN: buildRoute(URLS.LOGIN),
  FORGOT: buildRoute(URLS.LOGIN, URLS.FORGOT),
  NEWPASSWORD: buildRoute(URLS.LOGIN, URLS.NEWPASSWORD),
  HOME: buildRoute(URLS.HOME, URLS.PARAMS.EMPLOYEE_ID),
  PLANNING: buildRoute(URLS.PLANNING, URLS.WORKPLAN, URLS.PARAMS.EMPLOYEE_ID, URLS.PARAMS.DEPARTMENT_ID_OPT),
  TEMPLATE: buildRoute(URLS.PLANNING, URLS.TEMPLATE, URLS.PARAMS.EMPLOYEE_ID, URLS.PARAMS.DEPARTMENT_ID_OPT),
  DRAFT: buildRoute(URLS.PLANNING, URLS.DRAFT, URLS.PARAMS.EMPLOYEE_ID, URLS.PARAMS.DEPARTMENT_ID_OPT),
  CENTER_ADD: buildRoute(URLS.CENTER, URLS.ADD),
  CENTER_OVERVIEW: buildRoute(URLS.CENTER, URLS.OVERVIEW),
  CENTER_DETAILS: buildRoute(URLS.CENTER, URLS.DETAILS, URLS.PARAMS.ID_OPT),
  DEPARTMENT_ADD: buildRoute(URLS.DEPARTMENT, URLS.ADD),
  DEPARTMENT_OVERVIEW: buildRoute(URLS.DEPARTMENT, URLS.OVERVIEW),
  DEPARTMENT_DETAILS: buildRoute(URLS.DEPARTMENT, URLS.DETAILS, URLS.PARAMS.ID_OPT),
  EMPLOYEE_ADD: buildRoute(URLS.EMPLOYEE, URLS.ADD, URLS.PARAMS.CENTER_ID_OPT),
  EMPLOYEE_DETAILS: buildRoute(URLS.EMPLOYEE, URLS.DETAILS, URLS.PARAMS.ID_OPT, URLS.PARAMS.OVERVIEW),
  EMPLOYEE_ENROLL: buildRoute(URLS.EMPLOYEE, URLS.ENROLL, URLS.PARAMS.ID_OPT),
  EMPLOYEE_DISCHARGE: buildRoute(URLS.EMPLOYEE, URLS.DISCHARGE, URLS.PARAMS.ID_OPT),
  EMPLOYEE_OVERVIEW: buildRoute(URLS.EMPLOYEE, URLS.OVERVIEW),
  CITIZEN_ADD: buildRoute(URLS.CITIZEN, URLS.ADD),
  CITIZEN_DETAILS: buildRoute(URLS.CITIZEN, URLS.DETAILS, URLS.PARAMS.ID_OPT),
  CITIZEN_ENROLL: buildRoute(URLS.CITIZEN, URLS.ENROLL, URLS.PARAMS.ID_OPT),
  CITIZEN_DISCHARGE: buildRoute(URLS.CITIZEN, URLS.DISCHARGE, URLS.PARAMS.ID_OPT),
  CITIZEN_OVERVIEW: buildRoute(URLS.CITIZEN, URLS.OVERVIEW),
  OVERVIEW: buildRoute(URLS.OVERVIEW),
  USEOFFORCE: buildRoute(URLS.USEOFFORCE),
  MEDICINE: buildRoute(URLS.MEDICINE),
  MEDICINE_HEALTH: buildRoute(URLS.MEDICINE_HEALTH, URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT),
  MEDICINE_CARD: buildRoute(URLS.MEDICINE_CARD, URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT),
  MEDICINE_FMK: buildRoute(URLS.MEDICINE_FMK, URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT),
  MEDICINE_HISTORY: buildRoute(URLS.MEDICINE_HISTORY, URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT),
  ENROLL_ADMIN: buildRoute(URLS.ENROLL, URLS.ADMIN),
  GOALS: buildRoute(URLS.CITIZEN, URLS.GOALS, URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT),
  GOAL_STATUS: buildRoute(URLS.GOALS, URLS.STATUS, URLS.PARAMS.ID),
  GOAL_STATUSES: buildRoute(URLS.GOALS, URLS.STATUSES),
  NOTES: buildRoute(URLS.NOTES),
  FORUM: buildRoute(URLS.FORUM),
  FORCE: buildRoute(URLS.FORCE),
  DOCUMENTS: buildRoute(URLS.DOCUMENTS),
  DOCUMENTS_PROCEDURES: buildRoute(URLS.DOCUMENTS_PROCEDURES),
  MEASUREMENTS: buildRoute(URLS.MEASUREMENTS, URLS.PARAMS.ID_OPT, URLS.PARAMS.TYPE_OPT),
  MEASUREMENTS_EDIT: buildRoute(URLS.MEASUREMENT, URLS.DETAILS, URLS.PARAMS.ID_OPT),
};
