import { GoalResult, GoalStatus } from '../services/prodocApi';

export class UtilsGoals {
  public static isGoalCanBeClosed(data: GoalResult) {
    const { subGoals } = data;

    if (!subGoals || !subGoals.length) return true;

    return !subGoals.some(({ goalStatus }) => {
      return goalStatus === GoalStatus.Paused || goalStatus === GoalStatus.OnGoing;
    });
  }

  public static sortGoalsByStatus(items: GoalResult[]) {
    const openedGoals = [];
    const pausedGoals = [];
    const finishedGoals = [];
    const closedGoals = [];

    items.forEach((item) => {
      const { goalStatus } = item;

      if (goalStatus === GoalStatus.OnGoing) openedGoals.push(item);
      if (goalStatus === GoalStatus.Paused) pausedGoals.push(item);
      if (goalStatus === GoalStatus.Achieved) finishedGoals.push(item);
      if (goalStatus === GoalStatus.NotAchieved) closedGoals.push(item);
    });

    return [...openedGoals, ...pausedGoals, ...finishedGoals, ...closedGoals];
  }
}