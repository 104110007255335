import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { Citizen, CitizenEnrollment } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import { CitizensState, searchCitizens, setCitizenEnrollmentDepartments } from '../../../store/citizenStore';
import ItemsSearch from '../../_shared/ItemsSearch/ItemsSearch';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { UtilsData } from '../../../utils/UtilsData';
import Button from '../../_shared/_controls/Button/Button';
import { citizenEnrollSchema, requiredMessage } from '../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import { ApplicationState } from '../../../store';
import { DepartmentState } from '../../../store/departmentStore';
import { CardActions } from '../../_shared/CardActions/CardActions';
import { Col, Row } from 'antd';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { SelectOption } from '../../../types/commonItems';

interface Props {
  onDepartmentChange: (departments: string) => void;
  onDateChange: (date: Date) => void;
  onEnrolledFromChange: (enrolledForm: SelectOption) => void;
  selectedDepartment: string;
  enrollmentDate: Date;
  enrolledFrom: string;
}

const enrollFromOptions = UtilsData.getEnrollFromOptions();

export default function CitizenEnrollForm(props: Props) {
  const { onDepartmentChange, onDateChange, onEnrolledFromChange, selectedDepartment, enrollmentDate, enrolledFrom } =
    props;
  const citizenStore = useSelector<ApplicationState, CitizensState>(state => state.citizens);
  const departmentStore = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const departments = departmentStore.userDepartments;
  const { enrollmentDepartments } = citizenStore;

  // const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
  //   validationSchema: citizenEnrollSchema,
  //   defaultValues: defaultData as any,
  // });
  // useRegisteredFields(citizenEnrollSchema, register, unregister);
  // const { enrollmentDate, enrolledFrom } = formData;

  // useEffect(() => {
  //   if (loadedCitizen) {
  //     setFormData({ ...formData, citizenId: loadedCitizen.id });
  //     setValue('citizenId', loadedCitizen.id);
  //   }
  // }, [loadedCitizen]);

  // const handleFieldChange = (value: string | Date, name: string) => {
  //   setFormData({ ...formData, [name]: value });
  //   setValue(name, value);
  //   clearError(name);
  // };

  // const handleFormSubmit = () => {
  //   if (!selectedDepartment) {
  //     setDepartmentsError(requiredMessage);
  //     return;
  //   }

  //   onSubmit(formData, [selectedDepartment]);
  // };

  // const handleDepartmentsSelect = (value: string) => {
  //   if (value.length) {
  //     setDepartmentsError('');
  //   }

  //   dispatch(setCitizenEnrollmentDepartments([]));
  //   setSelectedDepartment(value);
  // };

  return (
    <>
      <Row gutter={8}>
        <Col span={12}>
          <SelectMenu
            title="Indskriv på afdeling"
            name="departmentId"
            valueKey="id"
            labelKey="name"
            // error={departmentsError}
            value={selectedDepartment}
            options={departments}
            valuesError={enrollmentDepartments}
            onChange={onDepartmentChange}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <DatePicker
            value={enrollmentDate}
            title="Indskrivnings dato"
            name="enrollmentDate"
            // error={errors.enrollmentDate && errors.enrollmentDate.message}
            onChange={onDateChange}
          />
        </Col>
        <Col span={12}>
          <SelectMenu
            title="Indskrevet fra"
            name="enrolledFrom"
            valueKey="value"
            labelKey="label"
            // error={errors.enrolledFrom && errors.enrolledFrom.message}
            value={enrolledFrom}
            options={enrollFromOptions}
            onChange={onEnrolledFromChange}
          />
        </Col>
      </Row>
      {/* <CardActions
          rightActions={
            <>
              <Button title="Indskriv" htmlType="submit" onClick={handleSubmit(handleFormSubmit)} />
              <Button title="Annuller" onClick={handleCancel} />
            </>
          }
        /> */}
    </>
  );
}
