import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import prodocApi, { Citizen, CitizenEnrollment, TypeOfConviction } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import Button from '../../_shared/_controls/Button/Button';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { CitizensState, searchCitizens, setCitizensSearchResult } from '../../../store/citizenStore';
import { citizenSchema } from '../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { useNavigation } from '../../../hooks/navigation';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { URLS } from '../../../constants/urls';
import { ApplicationState } from '../../../store';
import Autocomplete, { AcOption } from '../../_shared/Autocomplete/Autocomplete';
import { DawaMunicipality } from '../../../types/citizen';
import { useCitizenEnrollAutocomplete } from '../../../hooks/data-hooks';
import ItemsSearch from '../../_shared/ItemsSearch/ItemsSearch';
import { UtilsItems } from '../../../utils/UtilsItems';
import { useZipCodeField } from '../../../hooks/common-hooks';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { CalendarState } from '../../../store/calendarStore';
import { CardActions } from '../../_shared/CardActions/CardActions';
import { DepartmentState } from '../../../store/departmentStore';

import './CitizenEditForm.scss';
import { Col, Row } from 'antd';
import { log } from 'console';
import { useHistory, useParams } from 'react-router';

interface Props {
  onSubmit(citizencitizenState: Citizen): void;
  onCancel(): void;
  citizenState: Citizen;
}

export default function CitizenEditForm(props: Props) {
  const { onSubmit, onCancel, citizenState } = props;

  const [navigateTo] = useNavigation();
  const { getZipCodeData } = useZipCodeField();
  const citizenStore = useSelector<ApplicationState, CitizensState>(state => state.citizens);
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const [acOptions, searchAcOptions] = useCitizenEnrollAutocomplete();
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const { editCitizen, citizensSearchResult, availableSearchCitizens, initialDepartmentId } = citizenStore;
  const { selectedCitizenEnrollments, citizensEnrollments } = calendarStore;
  const { userDepartments } = departmentState;
  const defaultEnrollmentId = selectedCitizenEnrollments?.length === 1 ? selectedCitizenEnrollments[0] : '';
  const filteredCitizenEnrollments = useMemo(() => {
    return availableSearchCitizens.filter(item => item.departmentId === selectedDepartment);
  }, [selectedDepartment, availableSearchCitizens.length]);

  const loadedCitizen = editCitizen.data;
  const domstypeOptions = React.useMemo(() => {
    return Object.keys(TypeOfConviction).map(item => {
      let itemName = item.replace(/\d/, ' $&');

      if (itemName === 'None') itemName = 'Ingen';

      return {
        value: item,
        label: itemName,
      };
    });
  }, []);
  const history = useHistory();
  const [accitizenState, setAccitizenState] = React.useState({
    actMunicipallity: '',
    payMunicipallity: '',
  });

  const [citizen, setCitizen] = React.useState<Citizen>(citizenState || ({} as Citizen));
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: citizenSchema,
    defaultValues: citizenState as any,
  });
  useRegisteredFields(citizenSchema, register, unregister);
  const {
    firstname,
    lastname,
    address,
    zipCode,
    city,
    alternativeAddress,
    phone,
    cpr,
    foreignId,
    cave,
    typeOfConviction,
    actSocialworker,
    paySocialworker,
    convictionEndtDate,
    convictionStartDate,
    diagnoses,
    vaccines,
  } = citizen;
  const { actMunicipallity, payMunicipallity } = accitizenState;

  // Preselect form fields
  useEffect(() => {
    if (citizenState) {
      setCitizen(citizenState);
      Object.keys(citizenState).forEach(key => {
        setValue(key, citizenState[key]);
      });
    }
  }, [citizenState]);

  // Preselect department and citizen fields
  useEffect(() => {
    setSelectedDepartment(initialDepartmentId);
  }, [initialDepartmentId]);

  useEffect(() => {
    if (defaultEnrollmentId) {
      const defaultEnrollment = citizensEnrollments.find(item => item.id === defaultEnrollmentId);

      handleCitizenSelect(defaultEnrollment?.citizenId);
    }
  }, [defaultEnrollmentId]);

  const handleFieldChange = (value: any, name: string) => {
    const zipcitizenState = getZipCodeData(value, name, { ...citizen, [name]: value });

    setCitizen(zipcitizenState);
    setValue(name, value);
    clearError(name);
  };

  const handleCprFieldChange = (value: string) => {
    const handledValue = UtilsItems.formatCprNumber(value);

    setCitizen({ ...citizen, cpr: handledValue } as Citizen);
    setValue('cpr', handledValue);
    clearError('cpr');
  };

  const handleFormSubmit = async () => {
    onSubmit(citizen);
  };

  const handleDepartmentChange = (id: string) => {
    const route = UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.DETAILS);

    navigateTo(route);
    setSelectedDepartment(id);
  };

  const handleCitizenSelect = (selectedId: string) => {
    if (selectedId) {
      const route = UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.DETAILS, selectedId);

      navigateTo(route);
    }
  };

  const handleAutocompleteSelect = (value: string, name: string) => {
    setCitizen({ ...citizen, [name]: value } as Citizen);
  };

  const handleAutocompleteChange = (value: string, name: string) => {
    setAccitizenState({ ...accitizenState, [name]: value });
  };

  const renderAcOptions = (citizenState: DawaMunicipality[]) => {
    if (!citizenState) return null;

    return citizenState.map((option: DawaMunicipality) => {
      return (
        <AcOption key={option.dagi_id} value={option.navn}>
          {option.navn}
        </AcOption>
      );
    });
  };

  return (
    <form className="citizen-form item-form">
      <Card title="Person oplysninger">
        <Row gutter={8}>
          <Col span={6}>
            <TextInput title="Fornavn" name="firstname" value={firstname} onChange={handleFieldChange} />
          </Col>
          <Col span={6}>
            <TextInput title="Efternavn" name="lastname" value={lastname} onChange={handleFieldChange} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <ItemsSearch
              data={citizensSearchResult}
              optionLabelItems={['firstname', 'lastname', 'cpr']}
              type="citizen"
              title="Cpr"
              name="cpr"
              value={cpr}
              defaultOption={loadedCitizen}
              searchAction={searchCitizens}
              error={errors.cpr && errors.cpr.message}
              updateResultAction={setCitizensSearchResult}
              onSelect={handleCitizenSelect}
              onChange={handleCprFieldChange}
            />
          </Col>
          <Col span={12}>
            <TextInput title="Udlændinge-ID" name="foreignId" value={foreignId} onChange={handleFieldChange} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput
              title="Fornavn"
              name="firstname"
              value={firstname}
              error={errors.firstname && errors.firstname.message}
              onChange={handleFieldChange}
            />
          </Col>
          <Col span={12}>
            <TextInput
              title="Efternavn"
              name="lastname"
              value={lastname}
              error={errors.lastname && errors.lastname.message}
              onChange={handleFieldChange}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput title="Adresse" name="address" value={address} onChange={handleFieldChange} />
          </Col>
          <Col span={4}>
            <TextInput title="Postnummer" name="zipCode" value={zipCode} onChange={handleFieldChange} />
          </Col>
          <Col span={8}>
            <TextInput title="By" name="city" value={city} onChange={handleFieldChange} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <TextInput
              title="Alternativ adresse"
              name="alternativeAddress"
              value={alternativeAddress}
              onChange={handleFieldChange}
            />
          </Col>
          <Col span={12}>
            <TextInput title="Telefonnummer" name="phone" value={phone} onChange={handleFieldChange} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <TextInput title="CAVE" name="cave" value={cave} caution onChange={handleFieldChange} />
          </Col>
          <Col span={8}>
            <TextInput title="Diagnoser" name="diagnoses" value={diagnoses} onChange={handleFieldChange} />
          </Col>
          <Col span={8}>
            <TextInput title="Vaccinationer" name="vaccines" value={vaccines} onChange={handleFieldChange} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <SelectMenu
              title="Domstype"
              name="typeOfConviction"
              value={typeOfConviction}
              valueKey="value"
              labelKey="label"
              options={domstypeOptions}
              onChange={handleFieldChange}
            />
          </Col>
          <Col span={8}>
            <DatePicker
              title="Dom slutdato"
              name="convictionEndtDate"
              value={convictionEndtDate}
              onChange={handleFieldChange}
            />
          </Col>
          <Col span={8}>
            <DatePicker
              title="Dom startdato"
              name="convictionStartDate"
              value={convictionStartDate}
              onChange={handleFieldChange}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Autocomplete
              data={acOptions.municipality}
              title="Handlekommune"
              value={actMunicipallity}
              name="actMunicipallity"
              placeholder="Skriv for at søge"
              minSearchValue={1}
              onSelect={handleAutocompleteSelect}
              onSearch={searchAcOptions}
              onChange={handleAutocompleteChange}>
              {renderAcOptions(acOptions.actMunicipallity)}
            </Autocomplete>
          </Col>
          <Col span={12}>
            <TextInput
              title="Ansvarlig socialrådgiver handlekommune"
              name="actSocialworker"
              value={actSocialworker}
              onChange={handleFieldChange}
            />
          </Col>
          <Col span={12}>
            <Autocomplete
              data={acOptions.payMunicipallity}
              title="Betalingskommune"
              placeholder="Skriv for at søge"
              value={payMunicipallity}
              name="payMunicipallity"
              minSearchValue={1}
              onSelect={handleAutocompleteSelect}
              onSearch={searchAcOptions}
              onChange={handleAutocompleteChange}>
              {renderAcOptions(acOptions.payMunicipallity)}
            </Autocomplete>
          </Col>
          <Col span={12}>
            <TextInput
              title="Ansvarlig socialrådgiver betalingskommune"
              name="paySocialworker"
              value={paySocialworker}
              onChange={handleFieldChange}
            />
          </Col>
        </Row>
        <CardActions
          rightActions={
            <>
              <Button title="Opdater" onClick={handleSubmit(handleFormSubmit)} />
              <Button title="Annuller" onClick={onCancel} />
            </>
          }
        />
      </Card>
    </form>
  );
}
