import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../layout/Header/Header';
import PageTitleContext from '../../context/PageTitleContext';
import { useActiveDepartmentSet, useUserRoleRedirect } from '../../hooks/user-hooks';
import { DocumentAddWindow } from '../documents/DocumentAddWindow/DocumentAddWindow';
import { ROUTES } from '../../constants/routes';
import { loadUserSidebarState } from '../../store/commonStore';

import './MasterControl.scss';

const { Content } = Layout;

export function MasterControl({ className, children }: { className?: string; children: JSX.Element[] }) {
  const isHomeScreen = useRouteMatch(ROUTES.HOME);
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState('');
  const mainClasses = classNames(className, 'content', { 'content-home': isHomeScreen });

  useEffect(() => {
    dispatch(loadUserSidebarState());
  }, []);

  useActiveDepartmentSet();
  useUserRoleRedirect();

  return (
    <Layout>
      <PageTitleContext.Provider value={{ title, setTitle }}>
        <Header />
        <Content style={{ padding: isHomeScreen ? 0 : 10, flexWrap: 'wrap' }} className={mainClasses}>
          <div style={{ flexGrow: 1, padding: 0, display: 'flex', justifyContent: 'center' }}>{children}</div>
        </Content>
        <DocumentAddWindow />
      </PageTitleContext.Provider>
    </Layout>
  );
}

export default MasterControl;
