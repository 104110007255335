import React, { useEffect, useState } from 'react';
import prodocApi, {
  Center,
  CitizenEnrollmentResult,
  EmployeeEnrollment,
  EmployeeEnrollmentResult,
} from '../../../services/prodocApi';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import { LogoutOutlined, MedicineBoxOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import CitizenDischargeForm from '../../../components/citizen/CitizenDischargeForm/CitizenDischargeForm';
import { dischargeCitizen } from '../../../store/citizenStore';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { ColumnType } from 'antd/es/table';
import { UtilsAuth } from '../../../utils/UtilsAuth';

export function EmployeeOverviewPage() {
  //getManyByCenter
  const [employeeEnrollments, setEmployeeEnrollments] = useState<EmployeeEnrollmentResult[]>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  usePageTitle('Medarbejdere');

  useEffect(() => {
    GetEnrollments();
  }, []);
  async function GetEnrollments() {
    try {
      setIsLoading(true);
      const result = await prodocApi.employeeEnrollment.getManyByEmployee();
      setEmployeeEnrollments(result);
    } finally {
      setIsLoading(false);
    }
  }

  const columns: ColumnType<EmployeeEnrollmentResult>[] = [
    {
      title: 'Navn',
      render: (o: EmployeeEnrollmentResult) => o.firstname + ' ' + o.lastname,
      key: 'name',
    },
    {
      title: 'Afdeling',
      render: (o: EmployeeEnrollmentResult) => o.departmentName,
      key: 'address',
    },
    {
      title: 'Oprettet',
      dataIndex: 'created',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : date),
    },
    // {
    //   title: '',
    //   render: (value, row: CitizenEnrollmentResult) => {
    //     return (
    //       <>
    //         <Tooltip title="Mål">
    //           <Button onClick={e => showGoalCitizen(e, row)} icon={<FontAwesomeIcon icon={faBullseye} />} />
    //         </Tooltip>
    //         &nbsp;
    //         <Tooltip title="Helbred og medicin">
    //           <Button onClick={e => showHealthCitizen(e, row)} icon={<MedicineBoxOutlined translate={undefined} />} />
    //         </Tooltip>
    //         &nbsp;
    //       </>
    //     );
    //   },
    // },
  ];
  function handleRowClicked(e) {
    history.push(ROUTES.EMPLOYEE_DETAILS.replace(':id?', e.id));
  }
  function handleCreateCitizen() {
    history.push(ROUTES.CITIZEN_ADD);
  }

  return (
    <div className="citizen-page page">
      <Row>
        <Col span={24}>
          <div style={{ background: '#fafafa', padding: '8px' }}>
            <Tooltip title="Opret borger">
              <Button shape="circle" icon={<PlusOutlined translate="" />} onClick={handleCreateCitizen} />
            </Tooltip>
          </div>
          <Table
            style={{ cursor: 'pointer' }}
            loading={isLoading}
            pagination={{ pageSize: 15 }}
            dataSource={employeeEnrollments}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => handleRowClicked(record),
              };
            }}
            columns={columns}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
}
