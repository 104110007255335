import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CitizenDischargeForm from '../../../components/citizen/CitizenDischargeForm/CitizenDischargeForm';
import { navigateToHome } from '../../../store/navigationStore';
import { loadUserDepartments } from '../../../store/userStore';
import { CitizenDischarge } from '../../../types/citizen';
import { showCitizenDischargeModal } from '../../../store/modalStore';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';

export default function CitizenDischargePage() {
  const dispatch = useDispatch();
  const { citizenItems } = useCitizenNavigationItems();

  usePageTitle('Udskriv borger');

  useEffect(() => {
    dispatch(loadUserDepartments());
  }, []);

  const handleSubmit = (data: CitizenDischarge) => {
    dispatch(showCitizenDischargeModal(data));
  };

  const handleCancel = () => {
    dispatch(navigateToHome());
  };

  return (
    <>
      <Sidebar>
        <NavigationMenu items={citizenItems} />
      </Sidebar>
      <div className="citizen-page page">
        <CitizenDischargeForm onSubmit={handleSubmit} onCancel={handleCancel} />
      </div>
    </>
  );
}
