import React, { useEffect, useState } from 'react';
import { Citizen } from '../../services/prodocApi';
import { SavedData } from './UseOfForce';
export interface PdfEditProps {
  pdfName: string;
  onChange: (result: Result[]) => void;
  citizen?: Citizen;
  pdfData?: SavedData[];
}
export interface Result {
  id: string;
  value: any;
  fieldName: string;
}
interface PdfEditState {
  citizen: Citizen;
}
export default function PdfEdit(props: PdfEditProps) {
  const { onChange, citizen, pdfData } = props;

  const [_json, _setJson] = useState(pdfData || []);
  const jsonRef = React.useRef(_json);
  const setJson = data => {
    jsonRef.current = data;
    _setJson(data);
  };
  useEffect(() => {
    setJson(pdfData);
  }, [pdfData]);

  const [_state, _setState] = useState<PdfEditState>();
  const stateRef = React.useRef(_state);
  const setState = (data: PdfEditState) => {
    stateRef.current = data;
    _setState(data);
  };

  function renderDone() {
    if (stateRef.current && stateRef.current.citizen) {
      updateDomCitizen(stateRef.current.citizen);
    }
    if (jsonRef.current) {
      updateData(jsonRef.current);
    }
    disableFields('CPR-nummer');
    disableFields('CPRnummer');
    disableFields('Fulde navn');
    disableFields('Navn');
  }

  function updateData(d: SavedData[]) {
    d.forEach(element => {
      const domElement = document.querySelector(`[data-id="${element.id}"]`) as any;
      element.id as any;
      if (domElement) {
        if (domElement.type === 'checkbox') {
          domElement.checked = element.value;
        } else {
          domElement.value = element.value;
        }
      }
    });
  }
  var reader = new FileReader();
  useEffect(() => {
    if (citizen) {
      setState({ ...stateRef.current, citizen });
      updateDomCitizen(citizen);
    }
  }, [citizen]);

  var scale = 2;
  var fontSize = 9 * scale;
  var fileName = `/static/${props.pdfName}`;
  let flatpickr = (window as any).flatpickr;
  let pdfjsLib = (window as any).pdfjsLib;
  let Maska = (window as any).Maska;
  useEffect(() => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var recoveredBlob = xhr.response;
      reader.addEventListener('loadend', e => {
        var blob = new Uint8Array((e as any).target.result);
      });
      reader.readAsArrayBuffer(recoveredBlob);
    };
    xhr.open('GET', fileName);
    xhr.send();
  }, []);

  reader.onload = function () {
    var pagesDiv = document.querySelector('#pages');
    var typedarray = new Uint8Array(this.result as any);
    pdfjsLib.getDocument(typedarray).promise.then(function (pdf) {
      // you can now use *pdf* here
      var result = {};

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then(function (page) {
          // you can now use *page* here

          var viewport = page.getViewport({ scale: scale });
          var outputScale = window.devicePixelRatio || 1;
          var pageDiv = document.createElement('div');
          (pageDiv as any).style = 'margin: 9px auto;box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); ';
          var canvas = document.createElement('canvas');
          pageDiv.appendChild(canvas);
          pagesDiv.appendChild(pageDiv);
          var context = canvas.getContext('2d');
          canvas.width = Math.floor(viewport.width * outputScale);
          canvas.height = Math.floor(viewport.height * outputScale);
          canvas.style.width = Math.floor(viewport.width) + 'px';
          canvas.style.height = Math.floor(viewport.height) + 'px';
          pageDiv.style.width = Math.floor(viewport.width) + 'px';
          pageDiv.style.height = Math.floor(viewport.height) + 'px';

          page.getAnnotations().then(function (annotations) {
            for (var j = 0; j < annotations.length; j++) {
              var annotation = annotations[j];
              // Field Name
              if (result[annotation.fieldName] == null) {
                result[annotation.fieldName] = null;
              }
              var normalized = normalizeRect([
                annotation.rect[0],
                page.view[3] - annotation.rect[1] + page.view[1],
                annotation.rect[2],
                page.view[3] - annotation.rect[3] + page.view[1],
              ]);
              var domElement;
              var left = 0;
              var top = 0;
              if (annotation.fieldType === 'Btn') {
                domElement = document.createElement('input');
                left = normalized[0] * scale + canvas.offsetLeft;
                top = normalized[1] * scale + canvas.offsetTop;
                domElement.type = 'checkbox';
                domElement.onclick = function (elem) {
                  if (elem.target.checked) {
                    var found = document.querySelectorAll(
                      '[data-fieldName="' + elem.target.getAttribute('data-fieldName') + '"]'
                    );
                    found.forEach(f => {
                      if (f.getAttribute('data-id') !== elem.target.getAttribute('data-id')) {
                        (f as any).checked = false;
                      }
                      const found = jsonRef.current.find(x => x.id === f.getAttribute('data-id'));
                      var id = f.getAttribute('data-id');
                      var fieldName = f.getAttribute('data-fieldName');
                      var value = (f as any).checked;

                      if (found) {
                        setJson(
                          jsonRef.current.map(x => {
                            if (found.id === x.id) {
                              return { id, value, fieldName };
                            }
                            return x;
                          })
                        );
                      } else {
                        setJson([...jsonRef.current, { id, value, fieldName }]);
                      }
                    });
                    onChange && onChange(jsonRef.current);
                  }
                };
              } else if (annotation.fieldType === 'Tx') {
                if (annotation.multiLine) {
                  domElement = document.createElement('TEXTAREA');
                } else {
                  domElement = document.createElement('input');
                }
                left = normalized[0] * scale + canvas.offsetLeft;
                top = normalized[1] * scale + canvas.offsetTop;
                // domElement.style.border = '0';
                // domElement.style.backgroundColor = 'transparent';

                domElement.addEventListener('change', event => {
                  var value;
                  var id = event.target.getAttribute('data-id');
                  var fieldName = event.target.getAttribute('data-fieldName');
                  if (event.target.type === 'checkbox') {
                    value = event.target.checked;
                  } else {
                    value = event.target.value;
                  }
                  const found = jsonRef.current.find(x => x.id === id);
                  if (found) {
                    setJson(
                      jsonRef.current.map(x => {
                        if (found.id === x.id) {
                          return { id, value, fieldName };
                        }
                        return x;
                      })
                    );
                  } else {
                    setJson([...jsonRef.current, { id, value, fieldName }]);
                  }
                  onChange && onChange(jsonRef.current);
                });
              }
              domElement.style.position = 'absolute';
              domElement.style.margin = '0px';
              domElement.style.width = (normalized[2] - normalized[0]) * scale + 'px';
              domElement.style.height = (normalized[3] - normalized[1]) * scale + 'px';
              domElement.style.left = left + 'px';
              domElement.style.top = top + 'px';
              domElement.style.fontSize = fontSize + 'px';
              domElement.setAttribute('data-fieldName', annotation.fieldName);
              domElement.setAttribute('data-id', annotation.id);
              domElement.setAttribute('title', annotation.alternativeText);

              pagesDiv.appendChild(domElement);
            }
            dateFields();
            renderDone();
          });
          var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

          var renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport,
          };
          page.render(renderContext);
        });
      }
    });
  };
  function dateFields() {
    document.querySelectorAll('input').forEach(function (a) {
      var dataAttribuite = a.attributes['data-fieldName'];
      var titleAttribuite = a.attributes['title'];
      if (
        (dataAttribuite && dataAttribuite.value.indexOf('Dato') > -1) ||
        (titleAttribuite && titleAttribuite.value.toLowerCase().indexOf('dato') > -1)
      ) {
        flatpickr(a, { dateFormat: 'd-m-Y', locale: 'da' });
      }
      if (
        (dataAttribuite && dataAttribuite.value.indexOf('klokken') > -1) ||
        (titleAttribuite && titleAttribuite.value.toLowerCase().indexOf('klokkeslæt') > -1) ||
        (titleAttribuite && titleAttribuite.value.toLowerCase().indexOf('timer:minutter') > -1)
      ) {
        Maska.create(a, {
          mask: '##:##',
        });
      }
    });
  }
  function normalizeRect(rect) {
    const r = rect.slice(0);

    if (rect[0] > rect[2]) {
      r[0] = rect[2];
      r[2] = rect[0];
    }

    if (rect[1] > rect[3]) {
      r[1] = rect[3];
      r[3] = rect[1];
    }

    return r;
  }
  function updateDomCitizen(citizen) {
    setData('CPR-nummer', citizen.cpr);
    setData('CPRnummer', citizen.cpr);
    setData('Fulde navn', citizen.firstname + ' ' + citizen.firstname);
    setData('Navn', citizen.firstname + ' ' + citizen.firstname);
    setData('Adresse på borgeren', citizen.address);
    setData('Adresse', citizen.address);
    setData('Borgerens handlekommune', citizen.actMunicipallity);
    onChange && onChange(jsonRef.current);
  }
  function disableFields(name) {
    var domObject = document.querySelector(`[data-fieldname="${name}"]`);
    if (domObject) {
      domObject.setAttribute('disabled', '');
    }
  }
  function setData(name, value, disabled?: boolean) {
    var domObject = document.querySelector(`[data-fieldname="${name}"]`);
    if (domObject) {
      (domObject as any).value = value;
      var id = domObject.attributes['data-id'].value;
      const found = jsonRef.current.find(x => x.id === id);
      if (!found) {
        setJson([...jsonRef.current, { id, value, fieldName: name }]);
      }
    }
  }
  return <div id="pages" style={{ position: 'relative', margin: 'auto' }}></div>;
}
