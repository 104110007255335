import * as React from 'react';
import * as antd from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

import './Drawer.scss';
import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation';

interface DrawerPropsExt extends DrawerProps {
  children;
  onOk?: React.MouseEventHandler<HTMLElement>;
  onCancel?: React.MouseEventHandler<HTMLElement>;
  onDelete?: React.MouseEventHandler<HTMLElement>;
  showDelete?: boolean;
  subtitle?: string;
  width?: number;
  buttons?: any;
  saving?: boolean;
}

export const Drawer = ({
  onClose,
  visible,
  children,
  onOk,
  onCancel,
  title,
  showDelete,
  onDelete,
  subtitle,
  width = 500,
  buttons,
  saving,
}: DrawerPropsExt) => {
  const { Title, Text } = antd.Typography;

  return (
    <div>
      <antd.Drawer
        width={width}
        destroyOnClose
        className="drawer"
        title={
          <div>
            <Title level={3} className="drawer__title">
              {title}
            </Title>
            {subtitle && (
              <Text
                style={{
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                }}
                type="secondary">
                {subtitle}
              </Text>
            )}
          </div>
        }
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}>
        {children}
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}>
          {buttons && buttons}
          {!buttons && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {!showDelete && <div />}
              <antd.Button style={{ width: 100 }} onClick={onDelete} type="default" hidden={!showDelete}>
                Slet
              </antd.Button>
              <div>
                <LoadingAnimation active={saving} />
                <antd.Button disabled={saving} style={{ width: 100, marginLeft: '10px' }} onClick={onOk} type="primary">
                  Gem
                </antd.Button>
                <antd.Button style={{ marginLeft: '10px', width: 100 }} onClick={onCancel}>
                  Annuller
                </antd.Button>
              </div>
            </div>
          )}
        </div>
      </antd.Drawer>
    </div>
  );
};
