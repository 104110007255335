import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addDepartment } from '../../../store/departmentStore';
import { departmentDefault } from '../../../services/defaults';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { navigateTo, navigateToHome } from '../../../store/navigationStore';
import { ROUTES } from '../../../constants/routes';
import DepartmentForm from '../../../components/departments/DepartmentForm/DepartmentForm';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import Button from '../../../components/_shared/_controls/Button/Button';

export default function DepartmentCreatePage() {
  const dispatch = useDispatch();
  const departmentData = useMemo(() => departmentDefault(), []);

  usePageTitle('Opret afdeling');

  const handleFormSubmit = (data: any) => {
    dispatch(addDepartment(data));
  };

  const handleCancel = () => {
    if (UtilsNavigation.shouldReturnToOverview()) {
      dispatch(navigateTo(ROUTES.OVERVIEW));
    } else {
      dispatch(navigateToHome());
    }
  };

  return (
    <>
      {/* <Sidebar>
        <Button title="Opret afdeling" className="fullwidth" />
      </Sidebar> */}
      <div className="department-page page">
        <DepartmentForm data={departmentData} editMode={false} onSubmit={handleFormSubmit} onCancel={handleCancel} />
      </div>
    </>
  );
}
